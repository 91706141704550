.request-access-page table tr td {
  color: white !important;
}
.request-access-page table tr th {
  color: white !important;
}
.request-access-content {
  padding: 80px 0px;
}

.request-access-content-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.request-access-content h2 {
  color: white;
}

.request-access-page .request-access-table table tr td,
.request-access-page .request-access-table table tr th {
  border: none;
  font-size: 14px;
  padding: 12px 15px;
  vertical-align: middle;
}
.request-access-page .request-access-table thead {
  background: #31343e;
}
.request-access-page .request-access-table tbody tr:nth-child(even) {
  background-color: #31343e !important;
}
.table-header-section {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 16px;
}

.table-header-section label {
  color: white;
  margin: 0;
  font-size: 14px;
}

.table-header-section .searching-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.table-header-section .searching-section button,
.table-header-section .searching-section button:hover,
.table-header-section .searching-section button:focus {
  width: 80px;
  background-color: #d5ba87;
  border: 1px solid #d5ba87;
  padding: 5px 12px;
  font-size: 14px;
  height: 40px;
}
.table-header-section .searching-section .form-group {
  margin-right: 15px;
}

.table-header-section input,
.table-header-section select {
  background: #454851;
  border: 1px solid #a0aabb;
  border-radius: 6px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  height: 40px;
  line-height: 17px;
  padding: 5px 12px;
}
.table-header-section .form-control:focus {
  background: transparent !important;
  outline: none;
  box-shadow: none;
  color: white;
}
.table-header-section input::-webkit-input-placeholder {
  background-color: transparent !important;
  color: #a0aabb;
}
.table-header-section input::-moz-placeholder {
  background-color: transparent !important;
  color: #a0aabb;
}
.table-header-section input:-ms-input-placeholder {
  background-color: transparent !important;
  color: #a0aabb;
}
.table-header-section input::-ms-input-placeholder {
  background-color: transparent !important;
  color: #a0aabb;
}
.table-header-section input::placeholder {
  background-color: transparent !important;
  color: #a0aabb;
}
.table-header-section select option {
  color: rgb(0, 0, 0);
}
.pagination ul {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
}

.pagination ul li.previous,
.pagination ul li.next {
  background: transparent;
  border: 1px solid #787878;
  padding: 5px 10px;
  border-radius: 5px;
  max-width: 100px;
  width: 100%;
  text-align: center;
}

.pagination ul li.previous a,
.pagination ul li.next a {
  color: white;
  text-decoration: none;
}
.pagination ul li {
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination ul li a {
  color: white !important;
}
.pagination ul li.selected a {
  color: #d5ba87 !important;
  font-weight: 600;
}
.pagination ul li.previous.disabled,
.pagination ul li.next.disabled {
  pointer-events: none;
  opacity: 0.6;
}
.no-data-found h2 {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #31343e;
  min-height: 200px;
  border-radius: 5px;
}

.request-access-page .request-access-table table tr td:first-child,
.request-access-page .request-access-table table tr th:first-child,
.request-access-page .request-access-table table tr td:nth-child(2),
.request-access-page .request-access-table table tr th:nth-child(2) {
  width: 7%;
}

.request-access-page .request-access-table table tr td:nth-child(3),
.request-access-page .request-access-table table tr th:nth-child(3),
.request-access-page .request-access-table table tr td:nth-child(7),
.request-access-page .request-access-table table tr th:nth-child(7) {
  width: 10%;
}

.request-access-page .request-access-table table tr td:nth-child(4),
.request-access-page .request-access-table table tr th:nth-child(4),
.request-access-page .request-access-table table tr td:nth-child(5),
.request-access-page .request-access-table table tr th:nth-child(5) {
  width: 15%;
}

.request-access-page .request-access-table table tr td:nth-child(6),
.request-access-page .request-access-table table tr th:nth-child(6) {
  width: 20%;
}

@media screen and (max-width: 767px) {
  .request-access-page .request-access-table table tr td,
  .request-access-page .request-access-table table tr th {
    white-space: nowrap;
  }
  .table-header-section .searching-section .form-group {
    margin-right: 5px;
    width: 100%;
    max-width: 48%;
  }
  .table-header-section .searching-section {
    margin-right: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
  }
}
