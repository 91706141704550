.video-setting-popup{
	background: #0E0E10 !important;
}

.video-setting-view{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
	left: 50%;
    position: absolute;
    top: 0;
    width: 1680px;
    height: 938px;
    transition: all 0.5s;
}

.video-setting-view .select-btn{
	padding: 12px 20px;

}

.video-setting-view .select-btn-text {
    font-weight: 500;
}

.video-setting-view .option {
    padding: 0px 16px 0 40px;
    margin-top: 16px;
    margin-bottom: 16px;
}

.video-setting-view .popper {
    min-width: 334px;
}

.signup.video-setting {
	background-color: #0E0E10;
	height: 100vh;
}

.signup.video-setting .logo-icon {
	position: absolute;
	left: 30px;
	top: 25px;
}

.video-container {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
    gap: 93px;
}

.video-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 1027px;
    width: 100%;
}

.video-section {
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
	height: 658px;
	width: 100%;
}

.default-placeholder {
	background-color: #31343E;
	height: 100%;
	width: 100%;
	border-radius: 1.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
}

.default-placeholder h2 {
    font-size: 40px;
    font-weight: 500;
    line-height: 120%;
    color: #ECEFF3;
    font-family: "Gilroy" !important;
}
.ready-container {
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 367px;
    width: 100%;
}

.ready-container h2 {
    font-size: 48px;
    font-weight: 500;
    color: #fff;
    line-height: 120%;
    font-family: "Gilroy" !important;
	margin-bottom: 8px;
}

.ready-to-play {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 100%;

}

.ready-to-play span {
    font-size: 1rem;
    color: #DFE1E7;
    font-weight: 400;
    line-height: 145%;
}
.ready-to-play button {
	margin-top: 1rem;
}

.signup-btn.join-btn {
	width: 100%;
}

.video-footer {
	position: absolute;
	display: flex;
	bottom: 0;
	left: 0;
	height: 128px;
	width: 100%;
	background-color: transparent;
}

.footer-action-container {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.video-footer-name {
	position: absolute;
	left: 32px;
	top: 50%;
	transform: translateY(-50%);
	color: #ECEFF3;
	font-size: 20px;
	font-weight: 400;
}

.video-mic-action {
	display: flex;
	gap: 3rem;
}

.video-mic-action span {
	padding: 1rem;
	border-radius: 50%;
	border: none;
	background-color: #DF1C41;
	border: 1px solid #DF1C41;
	cursor: pointer;
	user-select: none;
}

.video-mic-action span.active {
	background-color: transparent;
	border: 1px solid #ECEFF3;
}

.mic-badge {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 32px;
	left: 32px;
	width: 34px;
	height: 34px;
	border-radius: 50%;
	background-color: #5B72E2;
	z-index: 10;
}

.video-action-container {
	display: flex;
	justify-content: center;
	align-items: center;
    gap: 32px;
    margin-top: 32px;
}

.mic.active svg {
	width: 33px;
	height: 32px;
}

.mic.active path {
	stroke: #ECEFF3;
	stroke-width: 1;
}

.video-view {
	width: 100%;
	object-fit: cover;
	border-radius: 1.5rem;
	transform: scale(-1, 1);
	height: 100%;
}

.poker-popup.permission-popup .modal-dialog {
	max-width: 1102px;
}

.poker-popup.permission-popup .modal-content {
	border-color: #1C1D20;
	background: #1C1D20;
}

.permission-popup-area {
	padding: 64px 120px!important;
	display: flex;
	gap: 32px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.permission-popup-area h2 {
    font-weight: 500;
    font-size: 40px;
    text-align: center;
    color: #ECEFF3;
    line-height: 120%;
    font-family: "Gilroy" !important;
    margin: 0;
}
.permission-cover{
	max-width: 220px;
	width: 100%;
}

.permission-cover img {
	width: 100%;
}

.allow-camera-btn .btn {
    padding-left: 3rem;
    padding-right: 3rem;
    max-width: 367px;
    margin: 0px auto;
    display: block;
}

.permission-popup-area span {
    color: #C7AD7A;
    font-size: 1rem;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
    line-height: 155%;
}

.permission-popup-area a:hover {
	color: #C7AD7A;
}

.signup-btn.allow-camera-btn {
    padding: 0;
    width: 100%;
}

.setting-sound-wave {
	height: 25px;
	width: 100%;
}

.waveform-container {
	display: inline-flex;
}




@media (max-width: 767px) {
	.video-wrapper {
		width: 100% !important;
	}
}

@media (min-width: 2624px) {

	.ready-container {
		width: 13.67% !important;
	}
}