.game-view::-webkit-scrollbar-track {
	border-radius: 10px;
	background-color: #555;
}

.game-view::-webkit-scrollbar {
	width: 0px;
	background-color: #555;
}

.game-view::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background-color: #555;
}

.player-game-view {
	color: #fff;
}

.player-game-view .container {
	max-width: 1600px !important;
	position: relative;
	min-height: 100vh;
}


.intro-video-wrapper video {
	width: 100%;
	height: 100vh;
	object-fit: cover;
}

.action-play-overlay {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	height: 100%;
	pointer-events: none;
	/*border: 1px solid #c7ad7a;*/
}

.action-container {
	display: flex;
	width: 300px;
	height: 300px;
	justify-content: center;
	align-items: center;
	pointer-events: auto;
}

.action-icon {
	display: inline-flex;
	width: 100px;
	height: 100px;
	cursor: pointer;
}

.action-icon>svg {
	height: 100%;
	width: 100%;
	fill: white;
}

.action-pause-container svg line,
.action-pause-container svg path {
	stroke: white;
}

.action-pause-container {
	opacity: 0;
}

.action-pause-container:hover {
	opacity: 1;
}

.action-play-overlay:hover {
	-webkit-backdrop-filter: brightness(0.9);
	backdrop-filter: brightness(0.9);
}

.skipbtn {
	z-index: 9;
	max-width: 100px;
	width: 100% !important;
	background: #31343e !important;
}

/**************************** Sidebar ***************************/

.player-game-sidebar {
	background: #383b41;
	width: 122px;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	text-align: center;
	padding: 40px 0px;
	transition: all 0.2s;
	z-index: 999;
	box-shadow: 10px 10px 60px #1b1d23;
}

.player-game-sidebar.waiting-sidebar {
	right: 0;
	left: unset;
	transition: all 0.2s;
	background: #1C1D20;
	box-shadow: 0px 10px 60px 0px #1B1D23;
}

.player-game-sidebar.waiting-sidebar.hide {
	right: -125px;
	box-shadow: none;
}

.player-sidebar-logo {
	padding-bottom: 18px;
}

.player-game-sidebar.hide {
	left: -125px;
	box-shadow: none;
}

.player-game-sidebar .nav {
	height: 84vh;
	overflow-y: auto;
	overflow-x: hidden;
}

.player-game-sidebar .nav::-webkit-scrollbar-track {
	border-radius: 10px;
	background-color: #555;
}

.player-game-sidebar .nav::-webkit-scrollbar {
	width: 0px;
	background-color: #555;
}

.player-game-sidebar .nav::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background-color: #555;
}

.player-game-sidebar-menu .nav a,
.player-game-sidebar-menu .nav span {
	display: flex;
	width: 100%;
	margin: 0px 20px;
	margin-top: 36px;
	cursor: pointer;
	text-decoration: none;
	transition: all 0.2s;
	padding: 14px 10px;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.player-game-sidebar-menu .nav a:hover,
.player-game-sidebar-menu .nav span:hover {
	background: #454851;
	border-radius: 8px;
}

.player-game-sidebar-menu .nav a span,
.player-game-sidebar-menu .nav span span {
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	color: #a0aabb;
	margin: 0;
	padding: 0;
	padding-top: 10px;
	text-overflow: ellipsis;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	word-break: break-word;
}

.player-sidebar-logo img {
	cursor: pointer;
}

/**************************** Top Bar ***************************/

.player-bid-amount {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	padding: 20px 0px;
	width: 1576px;
	height: 110px;
}

.player-bid-box {
	display: grid;
	gap: 20px;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
}

.player-bid {
	height: 50px;
	padding: 6px 20px;
	background: #373940;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
}

.player-bid-grid {
	max-width: 1000px;
	width: 100%;
}

.player-bid-button {
	max-width: unset;
	width: unset;
	text-align: unset;
}

.player-bid-logo {
	max-width: 288px;
	width: 100%;
}

.player-bid-logo span {
	cursor: pointer;
}

.player-bid-info h4 {
	margin: 0;
	font-weight: 400;
	font-size: 30px;
	line-height: 38px;
	text-overflow: ellipsis;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	word-break: break-all;
}

.player-bid-info h3 {
	margin: 0;
	font-weight: 400;
	font-size: 30px;
	line-height: 38px;
	color: #909090;
	margin-left: 12px;
}

.timer-grid {
	display: flex;
	align-items: center;
}

.player-bid-icon {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.player-bid-icon h4 {
	font-weight: 400;
	font-size: 20px;
	line-height: 26px;
	color: #909090;
	margin: 0;
}

.player-bid-icon .alert-timer {
	color: #d93355 !important;
}

.player-bid-icon span {
	margin-left: 12px;
}

.player-bid-info span {
	font-weight: 500;
	font-size: 12px;
	line-height: 14px;
	/* identical to box height */
	display: block;
	color: #a0aabb;
}

.player-bid-button .btn,
.player-bid-button .btn:disabled {
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	color: #c7ad7a;
	background: transparent;
	border: 1px solid #c7ad7a;
	border-radius: 6px;
	width: 250px;
	height: 50px;
	box-shadow: none;
	font-family: "DDCHardware" !important;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.2s;
}

.player-bid-button .btn:hover,
.player-bid-button .btn:focus,
.player-bid-button .btn-check:checked+.player-bid-button .btn,
.player-bid-button .btn.active,
.player-bid-button .btn.show,
.player-bid-button .btn:first-child:active,
:not(.player-bid-button .btn-check)+.player-bid-button .btn:active,
.player-bid-button .btn:focus-visible {
	background: transparent;
	border-color: #af935d;
	color: #af935d;
	outline: 0;
}

.player-bid-button .btn:focus,
.player-bid-button .btn:focus-visible {
	border-color: transparent;
	box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}

.player-blind-timer-dropdown {
	position: absolute;
	top: 75px;
	width: 100%;
	left: 0;
	background: #373940;
	border-radius: 6px;
	padding: 16px 20px;
	z-index: 99;
	transition: all 0.2s;
	visibility: hidden;
	transform: translateY(-20px);
	opacity: 0;
}

.player-blind-timer-dropdown.show {
	visibility: visible;
	transform: translateY(0px);
	opacity: 1;
}

.player-blind-timer-dropdown h4 {
	font-weight: 400;
	font-size: 20px;
	line-height: 26px;
	display: flex;
	align-items: center;
	text-transform: uppercase;
	color: #ffffff;
	margin: 0;
	padding-bottom: 16px;
}

.player-blind-timer-dropdown h4 span {
	color: #83bc9a;
	margin-left: 6px;
	display: inline-block;
}

.player-blind-timer-dropdown table td {
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: #a5a5a5;
	border: none;
	padding: 4px 10px;
}

.player-blind-timer-dropdown table tr td:first-child {
	padding-left: 0;
}

.player-blind-timer-dropdown table {
	margin: 0;
}

.player-blind-timer-table {
	max-height: 162px;
	margin: 0;
	overflow-y: scroll;
}

.player-blind-timer-dropdown table td span {
	color: #fff;
}

.player-blind-timer-dropdown table td .blin-td span {
	color: #83bc9a;
}

.player-blind-timer-dropdown table td:last-child {
	text-align: right;
}

.player-blind-timer-table::-webkit-scrollbar-track {
	border-radius: 10px;
	background-color: #373940;
}

.player-blind-timer-table::-webkit-scrollbar {
	width: 0px;
	background-color: #373940;
}

.player-blind-timer-table::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background-color: #373940;
}

.player-bid.player-pot-count {
	padding: 6px 10px;
	min-height: 50px;
}

.player-pot-count ul {
	padding: 0;
	margin: 0;
	list-style-type: none;
	width: 100%;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}

.player-pot-count ul li {
	border-right: 1px solid #454851;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	padding: 0 2%;
}

.player-pot-count ul li span {
	font-weight: 500;
	font-size: 12px;
	line-height: 14px;
	display: block;
	text-align: center;
	color: #a0aabb;
}

.player-pot-count ul li:last-child {
	border: none;
}

.player-pot-count ul li h4 {
	font-weight: 400;
	font-size: 20px;
	line-height: 26px;
	text-align: center;
	color: #ffffff;
	margin: 0;
}

.player-pot-count ul.more-pots {
	padding-left: 80px;
}

.player-pot-count ul.more-pots li {
	border-right: none;
}

.player-pot-count ul.more-pots li:first-child {
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	width: auto;
}

.player-pot-count ul.more-pots li span {
	font-size: 10px;
	line-height: 11px;
}

.player-pot-count ul.more-pots li h4 {
	font-size: 15px;
	line-height: 14px;
}

.player-pot-count ul.more-pots li:first-child span {
	font-size: 14px;
	line-height: 16px;
}

.player-pot-count ul.more-pots li:first-child h4 {
	font-size: 24px;
	line-height: 30px;
}

/**************************** Player View ***************************/

.player-game-grid {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 1230px;
	height: 684px;
	gap: 10px;
	padding: 20px;
	transition: all 0.2s;
}

.player-game-view-grid {
	max-width: 357px;
	width: 100%;
	display: grid;
	gap: 4px;
	align-content: flex-start;
}

.player-game-dealing {
	max-width: 455px;
	width: 100%;
	position: relative;
	/*padding-top: 5px;*/
}

/**************************** Player ***************************/

.player-box-list {
	background: #373940;
	position: relative;
}

.player-box {
	border: 4px solid #373940;
	border-radius: 6px;
	position: relative;
}

.player-length-4 .active-player {
	border: 4px solid #c7ad7a;
}

.player-length-4 .player-box-iframe.private-call {
	border: 4px solid #00acee;
}

.player-length-3 .active-player {
	border: 4px solid #c7ad7a;
}

.player-length-3 .player-box-iframe.private-call {
	border: 4px solid #00acee;
}

.player-length-2 .active-player {
	border: 4px solid #c7ad7a;
}

.player-length-2 .player-box-iframe.private-call {
	border: 4px solid #00acee;
}

.player-length-1 .active-player {
	border: 4px solid #c7ad7a;
}

.player-length-1 .player-box-iframe.private-call {
	border: 4px solid #00acee;
}

.microphone-active {
	border: 4px solid #00acee !important;
}

.player-box-grid {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.player-box-action-grid {
	position: relative;
	max-width: 165px;
	width: 100%;
	padding: 10px;
	border-top: 2px solid transparent;
	z-index: 9;
}

.company-logo-grid {
	position: absolute;
	right: 0px;
	top: 0px;
	display: flex;
	align-items: center;
}

.main-dealer-symbol {
	background: #ffffff;
	box-shadow: 10px 10px 60px #1b1d23;
	border-radius: 100px;
	width: 20px;
	height: 20px;
	line-height: 20px;
	text-align: center;
	font-weight: 400;
	font-size: 16px;
	color: #31343e;
	font-family: "DDCHardware" !important;
}

.company-logo-section img {
	width: 36px;
	height: 36px;
	-o-object-fit: cover;
	object-fit: cover;
}

.player-game-right-view .player-box-grid .company-logo-grid .company-logo-section img {
	border-radius: 0px 0px 12px 0px;
}

.player-game-left-view .player-box-grid .company-logo-grid .company-logo-section img {
	border-radius: 0px 0px 0px 12px;
}

.player-box-view {
	width: calc(100% - 165px);
	position: relative;
}

.player-dropdown {
	position: absolute;
	z-index: 999;
	top: 11px;
	left: 11px;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 8px;
}

.player-dropdown .btn,
.player-dropdown .btn:hover,
.player-dropdown .btn:focus,
.player-dropdown .btn-check:checked+.player-dropdown .btn,
.player-dropdown .btn.active,
.player-dropdown .btn.show,
.player-dropdown .btn:first-child:active,
:not(.player-dropdown .btn-check)+.player-dropdown .btn:active,
.player-dropdown .btn:focus-visible {
	border: none;
	box-shadow: none;
	padding: 0;
	width: 20px;
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #909090;
	border-radius: 100%;
	gap: 2px;
}

.player-dropdown .btn::after {
	display: none;
}

.player-dropdown .dropdown-menu {
	background: #31343e;
	border-color: #31343e;
	padding: 0;
	overflow: hidden;
}

.player-dropdown .dropdown-item {
	color: #fff;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	padding: 12px;
	cursor: pointer;
}

.player-dropdown .dropdown-item:focus,
.player-dropdown .dropdown-item:hover {
	background: #393c46;
	color: #fff;
}

.player-dropdown .btn span {
	display: flex;
	align-items: center;
	width: 3px;
	height: 3px;
	background: #fff;
	border-radius: 100%;
	transition: all 0.2s;
}

.player-btn-dropdown button.dropdown-toggle {
	background-color: #00acee !important;
}

.dropdwon-btn-1to1 button.dropdown-toggle {
	background-color: #d93355 !important;
}

.dot-flashing.dot-animate {
	-webkit-animation: dot-flashing 1s infinite linear alternate;
	animation: dot-flashing 1s infinite linear alternate;
	-webkit-animation-delay: 0.5s;
	animation-delay: 0.5s;
}

.dot-flashing.dot-animate::before {
	-webkit-animation: dot-flashing 1s infinite alternate;
	animation: dot-flashing 1s infinite alternate;
	-webkit-animation-delay: 0s;
	animation-delay: 0s;
}

.dot-flashing.dot-animate::after {
	-webkit-animation: dot-flashing 1s infinite alternate;
	animation: dot-flashing 1s infinite alternate;
	-webkit-animation-delay: 1s;
	animation-delay: 1s;
}

.dot-flashing {
	position: relative;
	width: 4px;
	height: 4px;
	border-radius: 5px;
	background-color: #fff;
	color: #fff;
}

.dot-flashing::before,
.dot-flashing::after {
	content: "";
	display: inline-block;
	position: absolute;
	top: 0;
}

.dot-flashing::before {
	left: -5px;
	width: 4px;
	height: 4px;
	border-radius: 5px;
	background-color: #fff;
	color: #fff;
}

.dot-flashing::after {
	left: 5px;
	width: 4px;
	height: 4px;
	border-radius: 5px;
	background-color: #fff;
	color: #fff;
}

@-webkit-keyframes dot-flashing {
	0% {
		background-color: #b1adad;
	}

	50%,
	100% {
		background-color: #ffffff;
	}
}

@keyframes dot-flashing {
	0% {
		background-color: #b1adad;
	}

	50%,
	100% {
		background-color: #ffffff;
	}
}

.player-box-user {
	background: transparent;
	position: absolute;
	display: flex;
	align-items: center;
	gap: 4px;
	width: calc(100% - 0px);
	bottom: 0px;
	justify-content: space-between;
	padding: 4px;
	left: 0;
}

.player-box-user.handname {
	right: 0;
	left: auto;
	bottom: 39px;
	justify-content: center;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	padding: 6px 10px;
	border-radius: 10px 0 0 0;
	overflow: hidden;
}

.player-box-user:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: block;
	background: radial-gradient(341.25% 341.25% at 54.33% -118.75%,
			rgba(255, 255, 255, 0.2) 0%,
			rgba(255, 255, 255, 0) 100%),
		rgba(87, 98, 102, 0.5);
	-webkit-backdrop-filter: blur(3px);
	backdrop-filter: blur(3px);
}

.player-box-user.handname:before {
	border-radius: 10px 0 0 0;
	overflow: hidden;
}

.player-box-user span {
	display: block;
	overflow: hidden;
	text-align: center;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 100%;
	background: #23262e;
	border-radius: 2px;
	padding: 6px 8px;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	position: relative;
	transition: all 0.2s;
	z-index: 9;
	word-break: break-all;
}

.player-box-user h4 {
	margin: 0;
	font-weight: 400;
	font-size: 26px;
	line-height: 26px;
	position: relative;
}

.player-box-iframe {
	display: block;
	/*border: 5px solid #31343e;*/
	border-radius: 6px;
}

.player-box-iframe img {
	width: 100%;
	height: 184px;
	-o-object-fit: cover;
	object-fit: cover;
	-o-object-position: center;
	object-position: center;
}

.player-box-card {
	display: flex;
	justify-content: space-between;
	position: relative;
}

.player-box-card span {
	visibility: hidden;
	opacity: 0;
	transition: all 0.2s;
	position: relative;
	z-index: 9;
}

.player-box-card span.show {
	visibility: visible;
	opacity: 1;
}

.player-box-card span img {
	width: 74px;
	height: 101px;
	-o-object-fit: cover;
	object-fit: cover;
	margin: 0px auto;
	display: block;
	border-radius: 3px;
	outline: 2px solid #a6a7aa;
	transition: all 0.2s;
	visibility: hidden;
	opacity: 0;
	position: relative;
	z-index: 9;
}

.player-box-card span.show img {
	visibility: visible;
	opacity: 1;
}

.player-action-view {
	background: #373940;
	border: 2px solid #23262e;
	border-radius: 3px;
	padding: 5px 10px;
	margin-top: 7px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.player-action-view-text {
	font-weight: 500;
	font-size: 20px;
	line-height: 20px;
	color: #ffffff;
	/*padding-bottom: 6px;*/
}

.player-action-view h4 {
	font-weight: 400;
	font-size: 26px;
	line-height: 30px;
	color: #ffffff;
	margin: 0;
}

.player-game-left-view .player-action-view {
	text-align: right;
}

.raise-action-view {
	border-color: #57b661;
}

.raise-action-view.recent-player {
	background: #57b661;
}

.bigblind-action-view {
	border-color: #d7905d;
}

.smallblind-action-view {
	border-color: #7c5dd7;
}

.smallblind-action-view.recent-player {
	background: #7c5dd7;
}

.allin-action-view {
	border-color: #d93355;
}

.allin-action-view.recent-player {
	background: #d93355;
}

.call-action-view {
	border-color: #ddab34;
}

.call-action-view.recent-player {
	background: #ddab34;
}

.check-action-view {
	border-color: #5b72e2;
}

.player-action-view-bet {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.player-action-view-bet span {
	display: inline-block;
	margin-left: 7px;
	font-weight: 400;
	font-size: 18.3474px;
	line-height: 23px;
	color: #ffffff;
}

.player-action-view-fold {
	background: #454851;
	border: 2px solid #454851;
	border-radius: 2px;
	padding: 10px 8px;
	margin-top: 7px;
	text-align: center;
}

.player-action-view-fold span {
	font-weight: 600;
	font-size: 26px;
	line-height: 32px;
	display: block;
	color: #ffffff;
}

.player-action-view-show {
	background: #373940;
	border: 2px solid #83bc9a;
	border-radius: 2px;
	padding: 10px 8px;
	margin-top: 7px;
	text-align: center;
}

.player-action-view-show span {
	font-weight: 500;
	font-size: 26px;
	line-height: 32px;
	display: block;
	color: #ffffff;
}

.player-action-view-check {
	background: #373940;
	border: 2px solid #5b72e2;
	border-radius: 2px;
	padding: 10px 8px;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
}

.player-action-view-check.recent-player {
	background: #5b72e2;
}

.player-action-view-check span {
	font-weight: 500;
	font-size: 26px;
	line-height: 32px;
	display: block;
	color: #ffffff;
}

.player-action-winner {
	display: flex;
	flex-direction: column;
	justify-content: center;
	background: #ddab34;
	box-shadow: 0px 0px 30px rgba(186, 143, 42, 0.5);
	border: 2px solid #ddab34;
	border-radius: 2px;
	padding: 10px 8px;
	text-align: center;
}

.player-action-winner span {
	font-weight: 400;
	font-size: 26px;
	line-height: 32px;
	display: block;
	color: #ffffff;
	font-family: "DDCHardware" !important;
}

.player-action-winner p {
	margin: 0;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	color: #ffffff;
}

.player-game-right-view .player-box-grid .player-box-view {
	order: 2;
}

.player-game-right-view .player-box-grid .player-box-action-grid {
	order: 1;
}

.player-game-right-view .player-box-grid .player-box-user span {
	order: 2;
}

.player-game-right-view .player-box-grid .player-box-user h4 {
	order: 1;
}

.player-game-right-view .player-box-grid .player-dropdown {
	left: auto;
	top: 11px;
	right: 11px;
	align-items: center;
	flex-direction: row-reverse;
}

/*.player-game-right-view .player-box-grid .main-dealer-symbol {
  left: 60px;
  right: auto;
}*/
.player-game-right-view .player-box-grid .company-logo-grid {
	left: 0;
	right: auto;
	flex-direction: row-reverse;
}

.player-game-right-view .active-player .player-box-grid .company-logo-grid {
	left: 0px;
}

.player-game-right-view .player-action-view-bet {
	justify-content: flex-start;
}

.player-camera.view-2 .community-cards {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	height: 269px;
	align-items: center;
	background: #373940;
	border-radius: 16px;
	padding: 30px;
}

.player-camera.view-2 .community-cards span {
	width: 18%;
	margin: 0px 1%;
}

.player-camera.view-2 .community-cards span img {
	width: 100%;
	height: auto;
}

.winner-player {
	border: 4px solid #ddab34;
}

.winner-player .player-box-action-grid {
	border-top: 4px solid #ddab34;
}

.player-action-view-check .player-action-view-text {
	padding-bottom: 0;
	font-size: 44px;
	line-height: 50px;
}

/**************************** Empty Player Grid ***************************/

.player-empty-box {
	background: #454851;
	border-radius: 6px;
	height: 194px;
	display: none;
}

/**************************** Player Invite ***************************/

.player-invite-box {
	background: #454851;
	border-radius: 6px;
	height: 194px;
	display: none;
}

/**************************** Dealer Camera ***************************/

.player-camera-box {
	border-radius: 6px;
	overflow: hidden;
	position: relative;
	border: 4px solid #31343e;
	display: flex;
}

.player-camera-box img {
	width: 100%;
}

.player-camera.view-1 .player-camera-box img {
	height: 679px;
	-o-object-fit: cover;
	object-fit: cover;
	-o-object-position: center;
	object-position: center;
	border-radius: 4px;
}

.player-camera.view-1 .player-camera-box video {
	/*height: 460px !important;*/
	-o-object-fit: cover;
	object-fit: cover;
	-o-object-position: center;
	object-position: center;
	border-radius: 4px;
}

/*.player-camera.view-1.dealer-camera-view .player-camera-box video {
  height: 460px !important;
}*/

.player-camera.view-1.dealer-camera-view .player-camera-box img {
	height: 306px !important;
}

.player-camera.view-2 .player-camera-box img {
	height: 269px;
	-o-object-fit: cover;
	object-fit: cover;
	-o-object-position: center;
	object-position: center;
	border-radius: 16px;
}

.player-camera.view-2 .player-camera-box video {
	width: 100%;
	height: 269px;
	-o-object-fit: cover;
	object-fit: cover;
	-o-object-position: center;
	object-position: center;
	border-radius: 16px;
}

.player-camera-box .btn,
.player-camera-box .btn:focus,
.player-camera-box .btn:hover {
	position: absolute;
	top: 10px;
	right: 10px;
	background: #4b605c;
	border: none;
	max-width: 160px;
	width: 100%;
	height: 50px;
	font-weight: 400;
	font-size: 20px;
	line-height: 26px;
	/* identical to box height, or 130% */
	text-align: center;
	text-transform: uppercase;
	color: #ffffff;
	font-family: "DDCHardware" !important;
}

.player-camera {
	margin-bottom: 12px;
	position: relative;
}

.player-game-not-started {
	position: absolute;
	border-radius: 16px;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

/* .player-game-not-started:before {
    content: "";
    background: rgba(35, 38, 46, 0.7);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
} */

/* .layer-game-not-started-content {
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 16px;
    transform: translate(-50%, -50%);
    z-index: 9;
    width: 100%;
    text-align: center;
} */

.layer-game-not-started-content {
	position: absolute;
	left: -15px;
	border-radius: 16px;
	z-index: 9;
	text-align: center;
	bottom: -15px;
}

.layer-game-not-started-content.pause-animation {
	left: 15px;
	bottom: 15px;
}

.layer-game-not-started-content>div {
	width: 120px !important;
	height: 120px !important;
}

.layer-game-not-started-content.pause-animation>div {
	width: 70px !important;
	height: 70px !important;
}

.layer-game-not-started-content h4 {
	margin: 0;
	width: 100%;
	font-weight: 400;
	font-size: 36px;
	line-height: 46px;
	padding-bottom: 10px;
}

.layer-game-not-started-content p {
	margin: 0;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
}

/**************************** PLayer Game Action ***************************/

.player-game-action {
	background: #373940;
	box-shadow: 0px -10px 20px rgba(35, 38, 46, 0.2);
	border-radius: 4px 4px 0px 0px;
	padding: 12px;
	position: absolute;
	width: 100%;
	bottom: 0;
}

/*.player-game-action.action-timer-added {
  padding-bottom: 0;
}*/

.player-game-action-content .btn {
	position: relative;
	border-radius: 6px;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	max-width: 160px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	height: 50px;
	font-family: "DDCHardware" !important;
	box-shadow: none;
	transition: all 0.2s;
}

.player-game-action-content .btn::before {
	position: absolute;
	content: "";
	width: 9px;
	height: 35px;
	background-color: rgb(51 51 51 / 30%);
	top: 50%;
	left: 8px;
	transform: translate(0, -50%);
	border-radius: 2px;
}

.player-game-action-content {
	position: relative;
	display: flex;
	gap: 8px;
	align-items: flex-end;
	background: #373940;
	border-radius: 4px;
}

.player-game-action-content .player-raise-btn.btn {
	background: #57b661;
	border-radius: 0;
	border: none;
	height: 40px;
}

.player-game-action-content .player-raise-btn.btn:hover,
.player-raise-btn.btn-check:checked+.player-raise-btn.btn,
.player-raise-btn.btn.active,
.player-raise-btn.btn.show,
.player-raise-btn.btn:first-child:active,
:not(.player-raise-btn.btn-check)+.player-raise-btn.btn:active {
	background: #83bc9a;
	border-color: #83bc9a;
	box-shadow: none;
}

.player-game-action-content .player-raise-btn:focus,
.player-raise-btn.btn:focus-visible {
	background: #57b661;
	border-color: #57b661;
	box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}

.player-game-action-content .player-call-btn.btn {
	background: #ddab34;
	border-radius: 6px;
	border-color: #ddab34;
	height: 40px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 0;
}

.player-game-action-content .player-call-btn.btn:hover,
.player-call-btn.btn-check:checked+.player-call-btn.btn,
.player-call-btn.btn.active,
.player-call-btn.btn.show,
.player-call-btn.btn:first-child:active,
:not(.player-call-btn.btn-check)+.player-call-btn.btn:active {
	background: #af935d;
	border-color: #af935d;
}

.player-game-action-content .player-call-btn:focus,
.player-call-btn.btn:focus-visible {
	background: #ddab34;
	border-color: #ddab34;
	box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}

.player-game-action-content .player-check-btn.btn {
	background: #5b72e2;
	border-radius: 6px;
	border-color: #5b72e2;
	height: 40px;
}

.player-game-action-content .player-check-btn.btn:hover,
.player-check-btn.btn-check:checked+.player-check-btn.btn,
.player-check-btn.btn.active,
.player-check-btn.btn.show,
.player-check-btn.btn:first-child:active,
:not(.player-check-btn.btn-check)+.player-check-btn.btn:active {
	background: #5167d2;
	border-color: #5167d2;
}

.player-game-action-content .player-check-btn:focus,
.player-check-btn.btn:focus-visible {
	background: #5b72e2;
	border-color: #5b72e2;
	box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}

.player-game-action-content .player-confirm-btn.btn {
	background: #c7ad7a;
	border-radius: 6px;
	border-color: #c7ad7a;
}

.player-game-action-content .player-confirm-btn.btn:hover,
.player-confirm-btn.btn-check:checked+.player-confirm-btn.btn,
.player-confirm-btn.btn.active,
.player-confirm-btn.btn.show,
.player-confirm-btn.btn:first-child:active,
:not(.player-confirm-btn.btn-check)+.player-confirm-btn.btn:active {
	background: #af935d;
	border-color: #af935d;
}

.player-game-action-content .player-confirm-btn.btn:focus,
.player-confirm-btn.btn:focus-visible {
	background: #c7ad7a;
	border-color: #c7ad7a;
	box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}

.player-game-action-content .player-fold-btn.btn {
	background: #4c505e;
	border-radius: 6px;
	border-color: #4c505e;
	height: 40px;
}

.player-game-action-content .player-fold-btn.btn:hover,
.player-fold-btn.btn-check:checked+.player-fold-btn.btn,
.player-fold-btn.btn.active,
.player-fold-btn.btn.show,
.player-fold-btn.btn:first-child:active,
:not(.player-fold-btn.btn-check)+.player-fold-btn.btn:active {
	background: #454851;
	border-color: #454851;
}

.player-game-action-content .player-fold-btn.btn:focus,
.player-fold-btn.btn:focus-visible {
	background: #4c505e;
	border-color: #4c505e;
	box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}

.player-game-action-content .player-tip-btn.btn,
.player-game-action-content .player-tip-btn.btn:hover,
.player-game-action-content .player-tip-btn.btn:focus,
.player-tip-btn.btn:focus-visible,
.player-tip-btn.btn-check:checked+.player-tip-btn.btn,
.player-tip-btn.btn.active,
.player-tip-btn.btn.show,
.player-tip-btn.btn:first-child:active,
:not(.player-tip-btn.btn-check)+.player-tip-btn.btn:active {
	background: transparent;
	border-radius: 6px;
	border-color: #c7ad7a;
	color: #c7ad7a;
}

.player-game-action-content .player-allin-btn.btn {
	background: #d93355;
	border-radius: 6px;
	border-color: #d93355;
	height: 40px;
}

.player-game-action-content .player-allin-btn.btn:hover,
.player-allin-btn.btn:focus,
.player-allin-btn.btn:focus-visible,
.player-allin-btn.btn-check:checked+.player-allin-btn.btn,
.player-allin-btn.btn.active,
.player-allin-btn.btn.show,
.player-allin-btn.btn:first-child:active,
:not(.player-allin-btn.btn-check)+.player-allin-btn.btn:active {
	background: #d6002b;
	border-color: #d6002b;
}

.player-game-action-content .player-allin-btn.btn:focus,
.player-allin-btn.btn:focus-visible {
	background: #d93355;
	border-color: #d93355;
	box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}

/**************************** Dealer Game Action ***************************/

.dealer-game-action form {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	width: 100%;
	flex-wrap: wrap;
}

.dealer-game-action form .form-control,
.dealer-game-action form .form-control:focus {
	height: 40px;
	background: #454851;
	border-radius: 6px !important;
	border-color: #454851;
	font-weight: 400;
	font-size: 20px;
	line-height: 25px;
	/* identical to box height */
	display: flex;
	align-items: center;
	text-transform: uppercase;

	/* Grayscale / Gray Lighter */
	color: #a5a5a5;
	font-family: "DDCHardware" !important;
	box-shadow: none;
	width: 100%;
	transition: all 0.2s;
}

.dealer-game-action form .input-group .btn,
.dealer-game-action form .input-group .btn:hover,
.dealer-game-action form .input-group .btn:focus {
	background: #c7ad7a;
	border: 1px solid #c7ad7a;
	border-radius: 6px !important;
	width: 40px !important;
	height: 40px !important;
}

.dealer-game-action form .btn.player-pause-btn img {
	margin-left: 10px;
}

.dealer-game-action-form {
	display: flex;
	width: 68%;
	justify-content: space-between;
	flex-wrap: wrap;
}

.dealer-game-action-form .input-group {
	justify-content: space-between;
}

.dealer-game-action-form .form-group {
	width: 48%;
}

.dealer-game-action form .input-group .form-control,
.dealer-game-action form .input-group .form-control:hover .dealer-game-action form .input-group .form-control:focus {
	width: calc(100% - 50px);
	flex: inherit;
}

.dealer-game-action form .player-pause-btn,
.dealer-game-action form .player-pause-btn:hover,
.dealer-game-action form .player-pause-btn:focus {
	height: 54px !important;
	background: #c7ad7a;
	border-color: #c7ad7a;
	border-radius: 6px;
	width: 30%;
	padding: 10px;
}

.dealer-game-action form .form-control::-webkit-input-placeholder {
	color: #a5a5a5;
}

.dealer-game-action form .form-control::-moz-placeholder {
	color: #a5a5a5;
}

.dealer-game-action form .form-control:-ms-input-placeholder {
	color: #a5a5a5;
}

.dealer-game-action form .form-control::-ms-input-placeholder {
	color: #a5a5a5;
}

.dealer-game-action form .form-control::placeholder {
	color: #a5a5a5;
}

.dealer-game-action form .form-label {
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	display: flex;
	align-items: center;
	color: #ffffff;
	font-family: "DDCHardware" !important;
	margin-bottom: 10px;
	transition: all 0.2s;
}

.dealer-game-action form .btn,
.dealer-game-action form .btn:hover {
	height: 40px;
}

.dealer-game-action form .btn img {
	margin: 0;
}

.dealer-game-action {
	width: 100%;
}

.dealer-game-action-form input.form-control:disabled {
	opacity: 0.3;
}

.dealer-game-action form .disabled-label.form-label {
	opacity: 0.3;
}

.dealer-game-action form .input-group .btn.check-effect,
.dealer-game-action form .input-group .btn.check-effect:hover,
.dealer-game-action form .input-group .btn.check-effect:focus {
	background: #83bc9a;
	border: 1px solid #83bc9a;
}

/**************************** Raise Widget *****************************/

.player-placeholder-btns .placeholder-btn {
	width: 100%;
	height: 50px;
	background: #454851;
	display: block;
	border-radius: 6px;
}

.player-placeholder-btns {
	display: flex;
	width: calc(100% - 0px);
	justify-content: space-between;
	gap: 8px;
}

.raise-widget {
	padding-bottom: 20px;
}

.raise-widget-content form {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.raise-slider {
	max-width: 220px;
	width: 100%;
}

.raise-quick-bets {
	width: calc(100% - 240px);
}

.raise-slider .form-control,
.raise-slider .form-control:focus {
	background: #454851;
	border-radius: 6px;
	border-color: #454851;
	font-weight: 400;
	font-size: 20px;
	line-height: 26px;
	display: flex;
	align-items: center;
	text-transform: uppercase;
	color: #ffffff;
	font-family: "DDCHardware" !important;
	height: 38px;
	box-shadow: none;
	padding: 8px 12px;
	margin-bottom: 20px;
}

.raise-quick-bets .btn,
.raise-quick-bets .btn:hover,
.raise-quick-bets .btn:focus {
	border: 1px solid #c7ad7a;
	border-radius: 6px;
	background: transparent;
	max-width: 67px;
	width: 100%;
	margin-right: 5px;
	margin-bottom: 5px;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	color: #ffffff;
	font-family: "DDCHardware" !important;
	box-shadow: none;
	transition: all 0.2s;
}

.raise-quick-bets .btn:hover,
.raise-quick-bets .btn-check:checked+.raise-quick-bets .btn,
.raise-quick-bets .btn.active,
.raise-quick-bets .btn.show,
.raise-quick-bets .btn:first-child:active,
:not(.raise-quick-bets .btn-check)+.raise-quick-bets .btn:active {
	color: #af935d;
	border-color: #af935d;
	box-shadow: none;
	background: transparent;
}

.raise-quick-bets .btn:focus,
.raise-quick-bets .btn:focus-visible {
	background: transparent;
	box-shadow: 0 0 0 2px #c7ad7a;
	color: #fff;
}

.raise-quick-bets .btn.all-in-btn,
.raise-quick-bets .btn.all-in-btn:hover,
.raise-quick-bets .btn.all-in-btn:focus {
	color: #c7ad7a;
}

.raise-widget .change-stack {
	padding: 0;
}

.raise-widget .change-stack .input-range {
	margin: 0;
}

.disable-btn {
	opacity: 0.3;
	pointer-events: none;
}

.raise-widget .input-range__label-container {
	display: none;
}

/**************************** Profile Viewer *****************************/

.profile-viewer {
	display: flex;
	flex-direction: column;
	gap: 24px;
	background: #31343e;
	border-radius: 6px;
	padding: 20px;
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 9999;
	max-width: 378px;
	box-shadow: 10px 10px 30px #1b1d238c;
}

.profile-viewer.chat-profile-view {
	max-width: 378px;
	top: unset;
	left: unset;
	right: 0;
}

.chat-profile-view .preview-info h4 {
	width: 100%;
	position: relative;
	text-align: left;
}

.chat-profile-view .preview-info h4::after {
	position: absolute;
	content: "";
	width: 8px;
	height: 8px;
	background: #83bc9a;
	top: 8px;
	right: -16px;
	border-radius: 100px;
}

.profile-viewer span.close-profile {
	position: absolute;
	right: -10px;
	top: -10px;
	cursor: pointer;
}

.player-game-right-view .profile-viewer {
	left: auto;
	right: 0;
}

select#video-devices,
select#video-devices:focus,
select#video-devices:hover {
	position: absolute;
	margin-left: 60px;
	background: #373940;
	border: 1px solid #31343e;
	color: #a9a9a9;
	border-radius: 5px;
	outline: none;
	cursor: pointer;
	height: 50px;
	width: 208px;
	padding: 12px;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	left: 0;
	top: 15px;
	font-family: "DDCHardware" !important;
}

/**************************** Animation *****************************/

.card-animate-fold {
	position: absolute;
	top: -99px;
	bottom: 0;
	left: -16px;
	right: 0;
	width: 250px;
	height: 250px;
	display: none;
}

.card-animate-cardview {
	position: absolute;
	top: -99px;
	bottom: 0;
	left: -16px;
	right: 0;
	width: 250px;
	height: 250px;
	display: none;
}

.card-animate-firstcard {
	position: absolute;
	top: -99px;
	bottom: 0;
	left: -16px;
	right: 0;
	width: 250px;
	height: 250px;
	display: none;
}

.card-animate-secondcard {
	position: absolute;
	top: -99px;
	bottom: 0;
	left: -16px;
	right: 0;
	width: 250px;
	height: 250px;
	display: none;
}

.firstCard .card-animate-firstcard {
	display: block;
}

.secondCard .card-animate-secondcard {
	display: block;
}

.foldCard .card-animate-fold {
	display: block;
}

.showCard .card-animate-cardview {
	display: block;
}

/**********************************************************************************/
/*************************************** Player View by Length*****************************/
/**********************************************************************************/

/*************** Player 1 **************/

.player-length-1 .player-box {
	height: 644px;
}

.player-length-1 .player-box-list {
	height: 100%;
}

.player-length-1 .player-box-grid {
	height: 100%;
	flex-wrap: wrap;
}

.player-length-1 .player-box-view {
	width: 100%;
	height: 552px;
}

.player-length-1 .player-box-action-grid {
	height: 84px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px;
	max-width: 100%;
}

.player-length-1 .player-box-iframe {
	height: 100%;
}

/*.player-length-1 .player-box-iframe img {
  height: 100%;
}*/

.player-length-1 .player-box-iframe video {
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
	-o-object-position: center;
	object-position: center;
}

.player-game-right-view.player-length-1 .player-box-grid .player-box-view {
	order: 1;
}

.player-game-right-view.player-length-1 .player-box-card {
	order: 2;
	transform: scale(66%) translateX(28%);
}

.player-length-1 .player-box-user h4 {
	font-size: 26px;
	line-height: 32px;
}

.player-length-1 .player-action-view {
	margin: 0;
	padding: 10px 8px;
	height: 68px;
}

.player-game-left-view.player-length-1 .player-box-action {
	margin-left: -49px;
}

.player-game-right-view.player-length-1 .player-box-action {
	margin-right: -49px;
}

.player-length-1 .player-action-view-check,
.player-length-1 .player-action-view-fold,
.player-length-1 .player-action-view-show {
	height: 68px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0;
}

.player-length-1 .player-box-card {
	max-width: 158px;
	width: 100%;
	transform: scale(66%) translateX(-28%);
}

.player-length-1 .player-box-action {
	width: calc(100% - 60px);
}

.player-length-1 .player-action-view-text {
	font-weight: 600;
	font-size: 20px;
	line-height: 20px;
	padding: 0;
}

.player-length-1 .player-action-view h4 {
	font-size: 26px;
	line-height: 32px;
}

.player-length-1 .player-action-view-bet span img {
	width: 24px;
}

.player-length-1 .player-action-view-bet span {
	font-size: 22px;
}

.player-length-1 .player-action-empty-field {
	border: 2px solid #454851;
	border-radius: 2px;
	width: 100%;
	min-height: 68px;
}

/*************** Player 2 **************/

.player-length-2 .player-box {
	height: 320px;
}

.player-length-2 .player-box-list {
	height: 100%;
}

.player-length-2 .player-box-grid {
	height: 100%;
	flex-wrap: wrap;
}

.player-length-2 .player-box-view {
	width: 100%;
	height: 228px;
}

.player-length-2 .player-box-action-grid {
	max-width: 100%;
	height: 80px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px;
}

.player-length-2 .player-box-iframe {
	height: 100%;
}

/*.player-length-2 .player-box-iframe img {
  height: 100%;
}*/

.player-length-2 .player-box-iframe video {
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
	-o-object-position: center;
	object-position: center;
}

.player-game-right-view.player-length-2 .player-box-grid .player-box-view {
	order: 1;
}

.player-game-right-view.player-length-2 .player-box-card {
	order: 2;
}

.player-length-2 .player-box-user h4 {
	font-size: 26px;
	line-height: 32px;
}

.player-length-2 .player-action-view {
	margin: 0;
	padding: 10px 8px;
	height: 68px;
}

.player-length-2 .player-action-view-check,
.player-length-2 .player-action-view-fold,
.player-length-2 .player-action-view-show {
	height: 68px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0;
}

.player-length-2 .player-action-winner {
	padding: 10px 8px;
	height: 68px;
}

.player-length-2 .player-box-card {
	max-width: 130px;
	width: 100%;
	transform: scale(80%);
}

.player-length-2 .player-box-action {
	width: calc(100% - 130px);
}

.player-length-2 .player-box-card span img {
	width: 59px;
	height: 81px;
}

.player-length-2 .player-action-view-text {
	font-weight: 600;
	font-size: 20px;
	line-height: 20px;
	padding: 0;
}

.player-action-view-check .player-length-2 .player-action-view-text {
	font-size: 26px;
	line-height: 32px;
}

.player-length-2 .player-action-view h4 {
	font-size: 26px;
	line-height: 32px;
}

.player-length-2 .player-action-view-bet span img {
	width: 24px;
}

.player-length-2 .player-action-view-bet span {
	font-size: 16px;
}

.player-length-2 .card-animate-firstcard {
	top: -78px;
	left: -13px;
	width: 200px;
	height: 200px;
}

.player-length-2 .card-animate-secondcard {
	top: -78px;
	left: -13px;
	width: 200px;
	height: 200px;
}

.player-length-2 .card-animate-fold {
	top: -78px;
	left: -13px;
	width: 200px;
	height: 200px;
}

.player-length-2 .card-animate-cardview {
	top: -78px;
	left: -13px;
	width: 200px;
	height: 200px;
}

.player-length-2 .player-action-empty-field {
	border: 2px solid #454851;
	border-radius: 2px;
	width: 100%;
	min-height: 68px;
}

/*************** Player 3 **************/

.player-length-3 .player-box {
	height: 212px;
}

.player-length-3 .player-box-list {
	height: 100%;
}

.player-length-3 .player-box-grid {
	height: 100%;
}

.player-length-3 .player-box-view {
	height: 100%;
	width: 230px;
}

.player-length-3 .player-box-action-grid {
	height: 100%;
	display: flex;
	align-items: flex-end;
	width: 115px;
	max-width: inherit;
	justify-content: flex-end;
	flex-wrap: wrap;
	flex-direction: column;
	gap: 4px;
	padding: 4px;
	border-top: 0px solid transparent;
	border-left: 4px solid transparent;
}

.player-length-3 .winner-player .player-box-action-grid {
	border-left: 2px solid #ddab34;
}

.player-game-right-view.player-length-3 .player-box-action-grid {
	border-right: 4px solid transparent;
	border-left: 0px solid transparent;
}

.player-game-right-view.player-length-3 .winner-player .player-box-action-grid {
	border-right: 2px solid #ddab34;
}

.player-length-3 .player-box-iframe {
	height: 100%;
}

/*.player-length-3 .player-box-iframe img {
  height: 100%;
}*/

.player-length-3 .player-box-iframe video {
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
	-o-object-position: center;
	object-position: center;
}

.player-length-3 .player-action-view-check {
	padding: 8px;
	margin-top: 0px;
	height: 62px;
}

.player-length-3 .player-action-view-check span {
	font-weight: 600;
	font-size: 20px;
	line-height: 26px;
}

.player-length-3 .player-action-view-fold span,
.player-length-3 .player-action-view-show span {
	font-weight: 600;
	font-size: 20px;
	line-height: 26px;
}

.player-length-3 .player-action-winner span {
	font-size: 20px;
	line-height: 26px;
}

.player-length-3 .player-action-winner p {
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
}

.player-length-3 .player-action-winner {
	padding: 10px 8px;
}

.player-length-3 .player-action-view-check .player-action-view-text {
	font-size: 20px;
	line-height: 26px;
}

.player-length-3 .player-box-card {
	max-width: 150px;
	width: 100%;
	transform: scale(74%) translate(-18%, 19%);
}

.player-length-3 .player-action-view {
	margin-top: 0;
	padding: 3px 8px;
	min-height: 62px;
}

.player-length-3 .player-action-view-text {
	font-size: 14px;
	line-height: 18px;
	padding: 0;
}

.player-length-3 .player-action-view h4 {
	font-size: 20px;
	line-height: 26px;
}

.player-length-3 .player-action-view-fold,
.player-length-3 .player-action-view-show {
	margin-top: 0;
	min-height: 62px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.player-length-3 .player-box-action {
	max-width: 150px;
	width: 100%;
}

.player-length-3 .player-box-card span img {
	width: 69.75px;
	height: 91px;
	border: 2px solid #a6a7aa;
	outline: none;
}

.player-length-3 .card-animate-firstcard {
	top: -88px;
	left: -14px;
	width: 225px;
	height: 225px;
}

.player-length-3 .card-animate-secondcard {
	top: -88px;
	left: -14px;
	width: 225px;
	height: 225px;
}

.player-length-3 .card-animate-fold {
	top: -88px;
	left: -14px;
	width: 225px;
	height: 225px;
}

.player-length-3 .card-animate-cardview {
	top: -88px;
	left: -14px;
	width: 225px;
	height: 225px;
}

.player-length-3 .player-action-empty-field {
	border: 2px solid #454851;
	border-radius: 2px;
	width: 100%;
	min-height: 62px;
}

/*************** Player 4 **************/

.player-length-4 .player-box {
	height: 158px;
}

.player-length-4 .player-box-list {
	height: 100%;
}

.player-length-4 .player-box-grid {
	height: 100%;
}

.player-length-4 .player-box-view {
	height: 100%;
	width: 230px;
}

.player-length-4 .player-box-action-grid {
	height: 100%;
	display: flex;
	align-items: center;
	width: 115px;
	max-width: inherit;
	justify-content: flex-end;
	flex-direction: column;
	gap: 4px;
	padding: 4px;
	border-top: 0px solid transparent;
	border-left: 4px solid transparent;
}

.player-length-4 .winner-player .player-box-action-grid {
	border-left: 4px solid #ddab34;
}

.player-game-right-view.player-length-4 .player-box-action-grid {
	border-right: 4px solid transparent;
	border-left: 0px solid transparent;
}

.player-game-right-view.player-length-4 .winner-player .player-box-action-grid {
	border-right: 4px solid #ddab34;
}

.player-length-4 .player-box-iframe {
	height: 100%;
}

/*.player-length-4 .player-box-iframe img {
  height: 100%;
}*/

.player-length-4 .player-box-iframe video {
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
	-o-object-position: center;
	object-position: center;
}

.player-length-4 .player-action-view-text {
	font-size: 14px;
	line-height: 18px;
}

.player-length-4 .player-action-view h4 {
	font-size: 20px;
	line-height: 26px;
}

.player-length-4 .player-action-view-bet span {
	font-size: 16px;
	line-height: 20px;
}

.player-length-4 .player-action-winner span {
	font-size: 20px;
	line-height: 26px;
}

.player-length-4 .player-action-winner p {
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
}

.player-length-4 .player-action-winner {
	padding: 4px;
	min-height: 69px;
}

.player-length-4 .player-action-view-fold span,
.player-length-4 .player-action-view-show span {
	font-weight: 600;
	font-size: 20px;
	line-height: 20px;
}

.player-length-4 .player-action-view-check .player-action-view-text {
	font-size: 20px;
	line-height: 20px;
}

.player-length-4 .player-action-view-fold,
.player-length-4 .player-action-view-show {
	padding: 4px;
	margin-top: 0;
	min-height: 69px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.player-length-4 .player-action-view-check {
	min-height: 69px;
}

.player-length-4 .player-box-card {
	max-width: 150px;
	width: 100%;
	transform: scale(72%) translate(-18%, 19%);
}

.player-length-4 .player-action-view {
	margin-top: 0;
	padding: 2.5px 4px;
	min-height: 69px;
}

.player-length-4 .player-box-action {
	max-width: 150px;
	width: 100%;
}

.player-length-4 .player-box-card span img {
	width: 69.94px;
	height: 89.96px;
	border: 2px solid #a6a7aa;
	outline: none;
}

.player-length-4 .card-animate-firstcard {
	top: -87px;
	left: -13px;
	width: 220px;
	height: 220px;
}

.player-length-4 .card-animate-secondcard {
	top: -87px;
	left: -13px;
	width: 220px;
	height: 220px;
}

.player-length-4 .card-animate-fold {
	top: -87px;
	left: -13px;
	width: 220px;
	height: 220px;
}

.player-length-4 .card-animate-cardview {
	top: -87px;
	left: -13px;
	width: 220px;
	height: 220px;
}

.placeholder-btn-action {
	width: 32%;
	height: 50px;
	background: #454851;
	display: block;
	border-radius: 6px;
}

.player-length-4 .player-action-empty-field {
	border: 2px solid #454851;
	border-radius: 2px;
	width: 100%;
	min-height: 69px;
}

.end-private-call {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(35, 38, 46, 0.7);
	-webkit-backdrop-filter: blur(15px);
	backdrop-filter: blur(15px);
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	visibility: hidden;
	transition: all 0.2s;
}

.end-private-call .btn,
.end-private-call .btn:first-child:active {
	background: #c7ad7a;
	border-radius: 6px;
	max-width: 170px;
	width: 100%;
	border-color: #c7ad7a;
	font-size: 16px;
	line-height: 20px;
	color: #ffffff;
	height: 50px;
	font-family: "DDCHardware" !important;
}

.end-private-call .btn:hover {
	background: #af935d;
	border-color: #af935d;
}

.end-private-call .btn:focus,
.end-private-call .btn:focus-visible {
	background: #c7ad7a;
	border-color: #57626680;
	box-shadow: 0px 0px 0px 2px #57626680;
}

.player-box-iframe:hover .end-private-call {
	opacity: 1;
	visibility: visible;
}

.player-game-view-wrapper {
	display: flex;
	flex-direction: column;
	gap: 8px;
	/*align-items: center;*/
}

.player-camera.view-1 {
	display: flex;
	flex-direction: column;
	gap: 4px;
}

.main-player-camoff {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #454851;
}

.main-player-camoff img {
	width: 160px;
	height: 160px;
	border: 3px solid #454851;
	border-radius: 100%;
	-o-object-fit: cover;
	object-fit: cover;
}

.player-length-2 .main-player-camoff img {
	width: 138px;
	height: 138px;
	border: 3px solid #454851;
	border-radius: 100%;
	-o-object-fit: cover;
	object-fit: cover;
}

.player-length-3 .main-player-camoff img {
	width: 100px;
	height: 100px;
}

.player-length-4 .main-player-camoff img {
	width: 80px;
	height: 80px;
}

.player-game-action-grid {
	position: relative;
	display: flex;
	flex-direction: column;
	row-gap: 6px;
	justify-content: flex-end;
	min-height: 94px;
}

/******************** Progress bar ********************/
.player-game-action .progress {
	--bs-progress-bar-transition: width 0.2s ease;
	background-color: #31343e;
	height: 6px;
	border-radius: unset;
	margin: 0 -12px;
	margin-bottom: -12px;
	position: absolute;
	width: 520px;
	bottom: 0;
}

.player-game-action .progress-bar {
	background-color: #af935d;
}

.player-box-card.self-fold span {
	opacity: 1;
	visibility: visible;
}

.player-box-card.self-fold span img {
	visibility: visible;
	opacity: 0.2;
}

.opponent-fold .card-animate-cardview {
	display: none !important;
}

/**************************************** New Action Design *****************************************/

.bet-input-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 4px 12px;
	gap: 4px;
	background: #454851;
	border-radius: 6px 6px 0 0;
}

.bet-btn-wrapper {
	max-width: 160px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: 2px solid #57b661;
	border-radius: 6px;
}

.bet-btn-wrapper input.form-control {
	width: 71px;
	font-weight: 400;
	font-size: 20px;
	line-height: 26px;
	display: flex;
	align-items: center;
	text-align: center;
	font-family: "DDCHardware" !important;
	text-transform: uppercase;
	color: #ffffff;
	background: transparent;
	border: none;
	outline: 0;
	padding: 0;
}

.bet-btn-wrapper input.form-control:focus {
	box-shadow: none;
}

.increment-icon-btn {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;
	background: #373940;
	border-radius: 6px;
	cursor: pointer;
	transition: all 0.2s;
}

.increment-icon-btn:hover {
	background: #4c505e;
}

.player-range-action-wrapper {
	max-width: 256px;
	width: 100%;
	display: flex;
	align-items: center;
	gap: 8px;
	position: absolute;
	right: 0;
	top: 0;
}

.player-range-input {
	/*max-width: 160px;*/
	width: 50%;
}

.player-pot-input {
	/*max-width: 160px;*/
	width: 50%;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px;
	gap: 6px;
}

.pot-input-btn.btn {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 4px 12px;
	width: 100%;
	height: 26px;
	background: #454851;
	border-color: #454851;
	border-radius: 6px;
	font-family: "DDCHardware" !important;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	color: #ffffff;
	cursor: pointer;
	transition: all 0.2s;
}

.pot-input-btn.btn::before {
	display: none;
}

.pot-input-btn.btn:hover,
.pot-input-btn.btn:active,
.pot-input-btn.btn:first-child:active,
.pot-input-btn.btn-check:checked+.pot-input-btn.btn,
.pot-input-btn.btn.active,
.pot-input-btn.btn.show,
:not(.pot-input-btn.btn-check)+.pot-input-btn.btn:active {
	background: #4c505e;
	border-color: #4c505e;
}

.pot-input-btn.btn:focus,
.pot-input-btn.btn:focus-visible {
	background: #454851;
	border-color: #454851;
	box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}

.pot-input-btn.btn:disabled {
	background: #454851;
	border-color: #454851;
	color: #808595;
}

.player-range-input .input-range {
	height: 18px;
}

.player-range-input .input-range__track {
	height: 18px;
	background: #454851;
	border-radius: 50px;
	margin: 0;
	left: 0;
	right: 0;
	top: 0;
}

.player-range-input .input-range__track--active {
	background: #57b661;
}

.player-range-input .input-range__slider {
	width: 22px;
	height: 22px;
	background: #fff;
	border-radius: 46px;
	border-color: #fff;
	margin-top: -20px;
	margin-left: -14px;
	z-index: 9;
}

/*********************** Dealer name *********************/

.dealer-name-wrapper {
	position: absolute;
	right: 10px;
	top: 10px;
	box-shadow: 0px 10px 60px #1b1d23;
	background: radial-gradient(341.25% 341.25% at 54.33% -118.75%,
			rgba(255, 255, 255, 0.2) 0%,
			rgba(255, 255, 255, 0) 100%),
		rgba(87, 98, 102, 0.5);
	border-radius: 4px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 4px 8px;
	gap: 10px;
}

.dealer-name-wrapper.current-round-wrapper {
	display: none;
	opacity: 0;
	visibility: hidden;
	top: auto;
	bottom: 10px;
	border-radius: 6px;
	width: 117px;
	height: 36px;
}

.table-count-wrapper {
	right: unset;
	left: 10px;
}

.dealer-name-wrapper span {
	color: #ffffff;
	letter-spacing: 0.03rem;
	font-family: "DDCHardware";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
}

/**********************************************************************************/
/*********************************** Automated Player *****************************/
/**********************************************************************************/

.tournament-game-view .player-bid-amount {
	width: unset;
	height: unset;
	padding: 4px 0;
}

/******************* Header ******************/

.tournament-game-view .automated-game-head {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	-moz-column-gap: 58px;
	column-gap: 58px;
	margin-bottom: 30px;
	position: relative;
}

.tournament-game-view .game-cp-wrapper {
	max-width: 720px;
	width: 100%;
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: url(../../../assets/images/playerview/cpTagBg.svg);
	background-repeat: no-repeat;
	background-position: top;
	background-size: cover;
}

.tournament-game-view .game-cp-wrapper h5 {
	margin: 0;
	font-weight: 400;
	font-size: 30px;
	line-height: 38px;
	color: #fff;
}

.player-bid-button .btn-check:checked+.player-bid-button .btn,
.player-bid-button .btn.active,
.player-bid-button .btn.show,
.player-bid-button .btn:first-child:active,
:not(.player-bid-button .btn-check)+.player-bid-button .btn:active {
	color: #c7ad7a;
	background: transparent;
	border-color: #c7ad7a;
}

.icon-btn.btn,
.icon-btn.btn:disabled {
	background: #c7ad7a;
	border-radius: 6px;
	border: none;
	width: 40px;
	height: 40px;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.2s;
}

.icon-btn.btn:hover,
.icon-btn.btn:focus,
.icon-btn.btn:focus:not(:focus-visible),
.icon-btn.btn:focus-visible,
.icon-btn.btn-check:checked+.icon-btn.btn,
.icon-btn.btn.active,
.icon-btn.btn.show,
.icon-btn.btn:first-child:active,
:not(.icon-btn.btn-check)+.icon-btn.btn:active {
	background: #9d896d;
	border: none;
	outline: 0;
}

.icon-btn.btn:focus,
.icon-btn.btn:focus:not(:focus-visible),
.icon-btn.btn:focus-visible {
	box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}

.icon-btn.enabled.btn {
	background: #fff;
}

.icon-btn.enabled.btn:hover,
.icon-btn.enabled.btn:focus,
.icon-btn.enabled.btn:focus:not(:focus-visible),
.icon-btn.enabled.btn:focus-visible,
.icon-btn.enabled.btn-check:checked+.icon-btn.enabled.btn,
.icon-btn.enabled.btn.active,
.icon-btn.enabled.btn.show,
.icon-btn.enabled.btn:first-child:active,
:not(.icon-btn.enabled.btn-check)+.icon-btn.enabled.btn:active {
	background: #a0aabb;
	border: none;
	outline: 0;
}

.icon-btn.enabled.btn:focus,
.icon-btn.enabled.btn:focus:not(:focus-visible),
.icon-btn.enabled.btn:focus-visible {
	background: #fff;
	box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}

.tournament-game-view .automated-head-btns {
	display: flex;
	align-items: center;
	gap: 16px;
}

.tournament-game-view .automated-head-btns-wrapper {
	display: flex;
	align-items: center;
	gap: 24px;
}

/******************** Player Camera ********************/

.tournament-game-view .player-camera.view-1 .player-camera-box img,
.tournament-game-view .player-camera.view-1 .player-camera-box video {
	height: 306px;
}

.tournament-game-view .player-camera.view-1 .community-card-cameraTow-view video {
	width: 520px !important;
	height: 292.5px;
	-o-object-fit: cover;
	object-fit: cover;
	-o-object-position: center;
	object-position: center;
	position: absolute;
	top: 0px;
	left: 50%;
	transform: translateX(-50%) scale(1);
}

.player-camera-box.community-card-cameraTow-view {
	height: 152px;
}

.tournament-game-view .player-camera {
	margin-bottom: unset;
}

.single-dealer-cam .player-camera.view-1 .player-camera-box video {
	height: 582px !important;
}

/*********************** Player Bid *********************/

.tournament-game-view .player-bid-box {
	grid-template-columns: repeat(2, minmax(0, 1fr));
	gap: 4px;
}

.tournament-game-view .player-bid-grid {
	max-width: unset;
}

.tournament-game-view .player-bid,
.player-bid.player-pot-count {
	padding: 4px 12px;
}

.tournament-game-view .player-bid-info h4 {
	font-weight: 400;
	font-size: 20px;
	line-height: 26px;
}

.tournament-game-view .player-bid {
	align-items: flex-end;
}

.tournament-game-view .player-bid-icon {
	padding-bottom: 4px;
}

/******************* blind timer dropdown *****************/

.tournament-game-view .player-bidtimer-drop-wrapper {
	position: absolute;
	top: -244px;
	width: 100%;
	height: 236px;
	left: 0;
	display: flex;
	align-items: flex-end;
	background: transparent;
	border-radius: 6px;
	z-index: 99;
	transition: all 0.2s;
	visibility: hidden;
	transform: translateY(20px);
	opacity: 0;
}

.tournament-game-view .player-bidtimer-drop-wrapper.show {
	visibility: visible;
	transform: translateY(0px);
	opacity: 1;
}

.tournament-game-view .player-blind-timer-dropdown {
	position: unset;
	top: 75px;
	width: 100%;
	left: 0;
	background: #373940;
	border-radius: 6px;
	padding: 16px 20px;
	z-index: 99;
	transition: all 0.2s;
	visibility: unset;
	transform: unset;
	opacity: unset;
}

.tournament-game-view .player-blind-timer-dropdown.show {
	visibility: unset;
	transform: unset;
	opacity: unset;
}

/******************* Automated Card *********************/

.tournament-game-view .automated-community-card-wrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 7px 5px;
	gap: 8px;
	border: 4px solid #31343e;
	border-radius: 6px;
	min-height: 150px;
}

.automated-community-card-wrapper span:nth-child(n + 6) {
	display: none;
}

.tournament-game-view .automated-community-card-wrapper span img {
	/*width: 100%;*/
	width: auto;
	height: 120.5px;
	-o-object-fit: cover;
	object-fit: cover;
	-o-object-position: center;
	object-position: center;
	border-radius: 6px;
}

/********************** player box *******************/

.tournament-game-view .player-length-2 .player-box-user span svg,
.tournament-game-view .player-box-user span svg {
	width: 20px;
	height: 20px;
	position: absolute;
	right: 6px;
	top: 50%;
	margin-top: -10px;
}

/********************** Tentative action btns ************************/

.tournament-game-view .tentative-action-btn.form-check {
	padding: 0;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin: 0;
}

.tentative-box.form-label {
	padding: 8px;
	max-width: 158.5px;
	width: 100%;
	height: 50px;
	border-radius: 6px;
	margin: 0;
	cursor: pointer;
}

.tentative-box.form-label.tentative-fold,
.tentative-box.form-label.tentative-check-fold {
	background: #4c505e;
	transition: all 0.2s;
}

.tentative-box.form-label.tentative-check {
	background: #5b72e2;
	transition: all 0.2s;
}

.tentative-box.form-label.tentative-call {
	background: #ddab34;
	transition: all 0.2s;
}

.tentative-box.form-label.tentative-call-any {
	background: #d7905d;
	transition: all 0.2s;
}

.tentative-box.form-label.tentative-all-in {
	background: #d93355;
	transition: all 0.2s;
}

.tentative-box.form-label.tentative-fold:hover,
.tentative-box.form-label.tentative-check-fold:hover {
	background: #454851;
}

.tentative-box.form-label.tentative-check:hover {
	background: #00acee;
}

.tentative-box.form-label.tentative-call:hover {
	background: #af935d;
}

.tentative-box.form-label.tentative-call-any:hover {
	background: #ac734a;
}

.tentative-box.form-label.tentative-all-in:hover {
	background: #d6002b;
}

.tentative-box.form-label.tentative-fold:focus,
.tentative-box.form-label.tentative-check-fold:focus,
.tentative-box.form-label.tentative-check:focus,
.tentative-box.form-label.tentative-call:focus,
.tentative-box.form-label.tentative-call-any:focus,
.tentative-box.form-label.tentative-all-in:focus {
	box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}

.tournament-game-view .player-game-action-check-content {
	gap: 10px;
	min-height: 94px;
}

.tentative-box input[type="checkbox"] {
	margin: 0;
	box-shadow: none;
	transition: all 0.2s;
	width: 9px;
	height: 35px;
	border-radius: 2px;
	border: 0;
	cursor: pointer;
}

.tentative-box .tentative-fold-btn input[type="checkbox"],
.tentative-box .tentative-check-fold-btn input[type="checkbox"] {
	background-color: #373940;
}

.tentative-box .tentative-check-btn input[type="checkbox"] {
	background-color: #445cd4;
}

.tentative-box .tentative-call-btn input[type="checkbox"] {
	background-color: #cc9028;
}

.tentative-box .tentative-call-any-btn input[type="checkbox"] {
	background-color: #c18153;
}

.tentative-box .tentative-all-in-btn input[type="checkbox"] {
	background-color: #b92745;
}

.tentative-box.form-label.tentative-check:hover .tentative-check-btn input[type="checkbox"] {
	background-color: #0691c6;
}

.tentative-box.form-label.tentative-call:hover .tentative-call-btn input[type="checkbox"] {
	background-color: #9a7f4c;
}

.tentative-box.form-label.tentative-call-any:hover .tentative-call-any-btn input[type="checkbox"] {
	background-color: #9d6034;
}

.tentative-box.form-label.tentative-all-in:hover .tentative-all-in-btn input[type="checkbox"] {
	background-color: #b92745;
}

.tentative-box input:checked[type="checkbox"] {
	background-image: url(../../../assets/images/checkBar.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: 5px 31px;
}

.tournament-game-view .tentative-action-btn .form-check-label {
	font-family: "DDCHardware";
	font-weight: 400;
	font-size: 16px;
	line-height: initial;
	text-transform: uppercase;
	width: calc(100% - 10px);
	justify-content: center;
	cursor: pointer;
}

.dealer-camera-view+.player-bid-amount {
	padding-bottom: 0;
}

/*******************************************************************************************************/
/******************************************* New Main player UI ************************************** */
/*******************************************************************************************************/

.player-view-main .container {
	max-width: 1600px !important;
	padding: 0;
	position: relative;
}

.user-main-game-header .logo {
	display: none;
}

.game-view-banner-main {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 0px;
	/*max-width: 1230px;*/
	width: 100%;
	background: #31343e;
	border-radius: 20px;
	height: 0px;
	opacity: 0;
	visibility: hidden;
	transition: all 0.2s;
}

.game-view-banner-main.show {
	height: 180px;
	opacity: 1;
	visibility: visible;
}

.game-view-banner-main.show-custom {
	height: 126px;
	opacity: 1;
	visibility: visible;
}

.game-view-banner-main span {
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #a0aabb;
}

.player-game-view-container {
	display: flex;
	justify-content: center;
	background: #23262e;
	border: 2px solid #373940;
	border-radius: 20px;
	width: 100%;
	height: 684px;
	overflow: hidden;
	transition: all 0.2s;
}

.player-game-view-container .game-viewport {
	position: unset;
	opacity: 1;
	visibility: visible;
	z-index: unset;
	border-radius: 20px;
	height: 684px;
	overflow: hidden;
	transition: all 200ms;
}

.player-game-view-wrapper-top {
	display: flex;
	flex-direction: row;
	/*align-items: flex-end;*/
	gap: 20px;
	justify-content: space-between;
	transition: all 0.2s;
}

.player-game-view-top-left {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 12px;
	width: 1230px;
}

.player-game-view-top-right.hide {
	height: 100%;
	width: unset !important;
}

.player-game-view-top-right.rail,
.player-game-view-top-right.multi-table {
	width: 100%;
	padding-top: 12px;
}

.color-text {
	color: #c7ad7a !important;
}

.player-view-main-wrapper {
	position: absolute;
	top: 0;
	left: 50%;
	transform: translate(-50%, 0) scale(100%);
	transition: all 0.2s;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 1657px;
	height: 955px;
	gap: 8px;
}

.player-view-main-wrapper .player-game-sidebar,
.player-view-main-wrapper .logo {
	display: none;
}

.game-sidebar-main span {
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #a0aabb;
}

.game-sidebar-main .setting-btn,
.side-setting-wrapper .setting-btn {
	width: 83px;
	justify-content: flex-end;
}

.game-sidebar-main .btn-down-icon,
.side-setting-wrapper .btn-down-icon {
	width: 35px;
	position: absolute;
	left: 0;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.view-game-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 14px 0px;
	gap: 4px;
	background: #31343e;
	border-radius: 16px;
	cursor: pointer;
}

.view-game-wrapper span {
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	align-items: center;
	display: flex;
	gap: 4px;
	color: #fff;
}

.side-setting-wrapper {
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 9px 16px;
	gap: 8px;
	background: #1C1D20;
	border-radius: 8px;
	justify-content: space-evenly;
	height: 68px;
}

.side-setting-wrapper.expand {
	flex-direction: row;
	height: 68px;
}

.side-setting-wrapper.collapsed {
	flex-direction: column;
	height: unset;
}

/*.setting-btn-group {
  width: 100%;
}*/
.setting-btn {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding-left: 12px;
	gap: 8px;
	background: #0E0E10;
	border-radius: 24px;
	width: 100%;
	height: 48px;
	border-color: #454851;
	position: relative;
	outline: 0;
	box-shadow: none;
	cursor: pointer;
	transition: all 0.2s;
}

.setting-btn.active {
	background: #35383e;
}

.setting-btn.active:hover {
	background: #35383e;
}

.setting-btn.active .mic-icon,
.setting-btn.active .cam-icon {
	background: #0E0E10;
}

.setting-btn.active .mic-icon.off,
.setting-btn.active .cam-icon.off {
	background-color: #DF1C41;
}


.draggable-content .setting-btn.active {
	background: #41444c;
}

.draggable-content .setting-btn:hover {
	background: #0E0E10;
}

.draggable-content .setting-btn.active:hover {
	background: #41444c;
}

.draggable-content .setting-btn.active:hover .cam-icon {
	background: #0E0E10;
}


.draggable-content .drop-wrapper.show {
	transform: translateY(24px);
}

.setting-btn.btn {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 8px 12px;
	gap: 4px;
	background: #292B31;
	border-radius: 100%;
	width: 48px;
	height: 48px;
	border-color: #292B31;
	position: relative;
	outline: 0;
	box-shadow: none;
	transition: all 0.2s;
}

.setting-btn .mic-icon,
.setting-btn .cam-icon,
.setting-btn .speaker-icon,
.setting-icon,
.leave-icon {
	/*border: 1px solid red;*/
	height: 48px;
	width: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	background-color: #292B31;
	/*position: absolute;
	left: 50%;
	top: 0;*/
}

.mic-icon.off,
.speaker-icon.off,
.cam-icon.off {
	background-color: #DF1C41;
}

.mic-speaker-container {
	display: flex;
	padding: 0 0.5rem;
	justify-content: space-around;
	width: 100%;
}

.chat-icon {
	position: relative;
}

.leave-icon {
	background-color: #DF1C41;
	border: none;
}

.leave-icon:hover,
.leave-icon:active {
	background-color: #DF1C41 !important;
}

.setting-icon {
	border: none;
}

.setting-icon:active {
	background-color: #292B31 !important;
}

/*.leave-icon:active {
	background-color: #DF1C41;
}*/

.setting-icon:hover {
	background-color: #292B31;
}

.setting-icon.show path {
	fill: #818898;
}

.setting-icon.show {
	background-color: #292B31;
}

.leave-icon.show path {
	fill: #DF1C41;
}

.leave-icon.show {
	background-color: #292B31;
}

.leave-icon.show:hover {
	background-color: #292B31 !important;
}

.waiting-sidebar-menu {
	justify-content: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2rem;
}

.action-btn-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
}

.chat-notifications {
	height: 27px;
	min-width: 27px;
	position: absolute;
	padding: 0 7px;
	top: -11px;
	right: -11px;
	color: #ECEFF3 !important;
	background-color: #C7AD7A;
	border-radius: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
}

.chat-notifications.main-game {
	height: 20px;
	min-width: 20px;
	top: -5px;
	right: -8px;
}

.setting-label,
.leave-label {
	color: #818898;
	font-size: 14px;
	text-align: center;
}

.leave-label {
	color: #DF1C41;
}

.setting-btn:hover,
.setting-btn:hover {
	background: #0E0E10;
	border-color: #0E0E10;
}

.setting-btn.btn:active,
.setting-btn.btn:first-child:active {
	background: #31343e;
	border-color: #31343e;
	box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}

.btn-down-icon {
	transition: all 0.2s;
}

.btn-down-icon.rotate {
	transform: rotate(-180deg);
}

.drop-wrapper {
	position: absolute;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 8px 0px;
	background: #31343e;
	border: 1px solid #454851;
	box-shadow: 0px 10px 60px #1b1d23;
	border-radius: 16px;
	transform: translateY(0px);
	opacity: 0;
	visibility: hidden;
	transition: all 0.2s;
	z-index: 99;
	top: 46px;
	left: 0;
}

.drop-wrapper.show {
	transform: translateY(20px);
	opacity: 1;
	visibility: visible;
}

.show-drop-btn {
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 7px 16px;
	gap: 8px;
	justify-content: space-between;
	background: #31343e;
	border-radius: 16px;
	cursor: pointer;
}

.show-drop-btn h5 {
	font-family: "DDCHardware";
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 26px;
	display: flex;
	align-items: center;
	text-transform: uppercase;
	color: #a0aabb;
	margin: 0;
	gap: 8px;
}

.show-drop-btn h5 span {
	font-family: "DDCHardware";
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 26px;
	display: flex;
	align-items: center;
	text-transform: uppercase;
	color: #ffffff;
}

.table-drop-list-wrapper {
	top: 35px;
}

.table-drop-list-wrapper span {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 12px;
	gap: 10px;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #ffffff;
	border-radius: 0;
	transition: all 0.2s;
	cursor: pointer;
}

.table-drop-list-wrapper span:hover {
	background: #4c505e;
}

.game-sidebar-main {
	width: 404px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding: 0px;
	gap: 8px;
	height: 100%;
}

.player-game-view-top-right.multi-table .game-sidebar-main.lg {
	width: 664px;
}

.player-game-view-top-right.hide.multi-table .game-sidebar-main.lg.hide {
	width: fit-content;
}

.stat-btn.btn,
.stat-btn.btn:active,
.stat-btn.btn:first-child:active,
:not(.stat-btn.btn-check)+.stat-btn.btn:active {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 8px 16px;
	gap: 8px;
	width: 100%;
	height: 42px;
	background: #292b31;
	border-color: #292b31;
	border-radius: 20px;
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 26px;
	color: #83bc9a;
	transition: all 0.2s;
}

.stat-btn.btn:hover {
	background: #292b31;
	border-color: #292b31;
}

.stat-btn.btn:focus,
.stat-btn.btn:focus-visible {
	background: #292b31;
	border-color: #292b31;
	box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}

.stat-btn.cancel {
	background: #D93355;
}

.stat-btn.cancel:hover {
	background: #d6002b;
}

.stat-btn.cancel:focus,
.stat-btn.cancel:focus-visible {
	background: #D93355 !important;
	/*border-color: #D93355 !important;*/
	box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}

.stat-btn.btn h3 {
	display: block;
	text-align: center;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 26px;
	margin: 0;
	color: #fff;
	word-break: break-all;
	font-family: "Gilroy" !important;
}

.game-stats-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	padding: 0px;
	gap: 8px;
}

.drop-item {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 10px 12px;
	gap: 10px;
	width: 100%;
}

.drop-item .invite-user-action {
	width: 100%;
}

.drop-item .invite-user-action .form-check {
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 0;
	margin: 0;
	gap: 10px;
}

.drop-item .invite-user-action .form-check .form-check-label {
	width: calc(100% - 30px);
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	cursor: pointer;
}

.drop-item-divider {
	background: #4c505e;
	height: 1px;
	padding: 0;
	width: 93%;
	margin: 8px auto;
}

.speaker-item-divider {
	background: #4c505e;
	height: 1px;
	padding: 0;
	width: 100%;
	margin: 8px auto;
}

.volume-control-wrapper {
	width: 100%;
	padding: 12px;
}

.selectValue {
	position: absolute;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 4px 8px;
	gap: 10px;
	width: 46px;
	height: 28px;
	background: #4c505e;
	border-radius: 2px;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	text-align: center;
	color: #ffffff;
	top: -40px;
	left: 50%;
	transform: translateX(-50%);
	opacity: 0;
	visibility: hidden;
	transition: all 0.2s;
}

.volume-range-main .volume-input-slider:active+#selector .selectValue {
	opacity: 1;
	visibility: visible;
}

.selectValue::after {
	display: block;
	content: "";
	border-top: 0.3em solid;
	border-right: 0.3em solid transparent;
	border-bottom: 0;
	border-left: 0.3em solid transparent;
	position: absolute;
	bottom: -4px;
	color: #4c505e;
}

.slider-title {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
	width: 100%;
	padding-bottom: 12px;
}

.slider-title span {
	font-family: "DDCHardware";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	display: flex;
	align-items: center;
	color: #ffffff;
	max-width: unset;
	padding: 0;
}

.slider-inc-btn {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 10px;
	width: 24px;
	height: 24px;
	background: #454851;
	border-radius: 136px;
	cursor: pointer;
}

.player-game-right-view.player-length-1 .player-action-view {
	/*margin-right: -49px;*/
	margin-left: unset;
}

.table-drop-wrapper {
	position: relative;
}

.player-box-user.mute span {
	padding-right: 32px;
}

/****************************************************************************************************/
/*************************************** Sidebar Tabs Section ***************************************/
/****************************************************************************************************/

.chat-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 0px;
	isolation: isolate;
	background: #292b31;
	border-radius: 8px;
	overflow: hidden;
	flex-grow: 1;
	flex-shrink: 0;
	flex-basis: 0px;
}

.tab-card-head {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 12px 16px;
	gap: 8px;
	width: 100%;
	background: #292B31;
	border-radius: 8px 8px 0px 0px;
	box-shadow: 0px 5px 10px 0px rgba(19, 20, 21, 0.15);
}


.tab-card-head.multi-table-head {
	padding: 16px;
	cursor: pointer;
}

.tab-card-head.chat-participant-head {
	display: flex;
	justify-content: space-between;
	box-shadow: 0px 5px 10px 0px #00000040;
	z-index: 1;
}

.chat-participant-tab {
	display: flex;
	/*border: 1px solid red;*/
	padding: 7px 20px;
	width: 50%;
	border-radius: 8px;
	justify-content: center;
	cursor: pointer;
}

.chat-participant-tab.active {
	background-color: #1C1D20;
	border: 0.5px solid #666D80;
}


.tab-card-head h4 {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0px;
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 26px;
	color: #ECEFF3;
	margin: 0;
	font-family: "Gilroy" !important;
}

.tab-card-head h4 span {
	font-style: normal;
	font-family: "DDCHardware";
	font-weight: 400;
	font-size: 20px;
	line-height: 26px;
	text-transform: uppercase;
	color: #83bc9a;
}

.tab-card-body {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 10px;
	gap: 8px;
	isolation: isolate;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	scroll-behavior: smooth;
	/*padding-top: 10px;*/
}


.multi-table-card-body {
	width: 100%;
	/*display: flex;
	flex-direction: column;
	align-items: flex-start;*/
	padding: 10px;
	gap: 8px;
	/*isolation: isolate;*/
	/*height: 100%;
	overflow-x: hidden;
	overflow-y: auto;*/
	/*scroll-behavior: smooth;*/
}

.multi-table-card-body .slick-prev {
	left: 7px;
	z-index: 1;
	width: 25px;
	height: 24px;
	/*background-color: #ECEFF3;*/
	border-radius: 50%;
}

.multi-table-card-body .slick-prev::before {
	/*font-size: 28px;
	color: #C7AD7A;*/
	/*opacity: 1;*/
	content: "";
	background: url("../../../assets/images/next-arrow.svg") no-repeat #C7AD7A center;
	rotate: 180deg;
	width: 25px;
	height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
}

.multi-table-card-body .slick-next {
	right: 10px;
	z-index: 1;
	width: 24px;
	height: 24px;
	/*background-color: #ECEFF3;*/
	border-radius: 50%;
}

.multi-table-card-body .slick-next::before {
	/*font-size: 28px;*/
	/*color: #C7AD7A;*/
	content: "";
	background: url("../../../assets/images/next-arrow.svg") no-repeat #C7AD7A center;
	width: 25px;
	height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
}

.multi-table-card-body .slick-slide {
	display: flex;
	justify-content: center;
}

.tab-card-element {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 4px 16px;
	gap: 8px;
}

/****************** Hand History Tab ******************/

.hand-history-head {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px;
	gap: 8px;
}

.hand-history-head-left {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0px;
	gap: 8px;
}

.hand-history-head-left .hand-count {
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	display: flex;
	align-items: center;
	color: #a5a5a5;
	width: 20px;
}

.hand-history-profile-wrapper {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px;
	gap: 8px;
}

.table-last-winner .hand-history-profile-wrapper {
	position: absolute;
	flex-direction: row;
	padding: 0px;
	gap: 8px;
	display: flex;
	width: 86px;
	height: 86px;
	justify-content: center;
	align-items: center;
	top: -43px;
	border-radius: 100%;
	box-shadow: 0px 0px 30px 0px rgba(186, 143, 42, 0.5);
}

.hand-history-profile-wrapper img {
	width: 32px;
	min-width: 32px;
	height: 32px;
	border: 1px solid #373940;
	border-radius: 100px;
	-o-object-fit: cover;
	object-fit: cover;
}

.table-last-winner .hand-history-profile-wrapper img {
	width: 86px;
	min-width: 86px;
	height: 86px;
	border: 3px solid #eab612;
}

.hand-winner-name {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
	gap: 4px;
}

.table-last-winner .hand-winner-name {
	padding: 4px 8px;
	justify-content: center;
	align-items: center;
	align-self: stretch;
	border-radius: 8px;
	background: #fff;
	z-index: 1;
}

.hand-winner-name p {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	word-break: break-all;
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 0px;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	margin: 0;
	color: #fff;
}

.table-last-winner .hand-winner-name p {
	color: #ddab34;
	font-family: "DDCHardware";
	font-size: 16px;
	line-height: 20px;
}

.table-last-winner .hand-winner-name p:last-child {
	font-family: inherit;
	color: #ddab34;
	font-size: 24px;
	font-weight: 600;
	line-height: 26px;
	text-transform: uppercase;
}

.hand-winner-name p span {
	color: #909090;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
}

.hand-winner-name .hand-name {
	font-weight: 500;
	color: #c7ad7a;
}

.hand-history-head-right {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	padding: 0px;
	gap: 8px;
}

.hand-pot-details {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
	padding: 0px;
	gap: 4px;
}

.hand-pot-details p {
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	margin: 0;
	color: #a5a5a5;
}

.hand-pot-details span {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #fff;
}

.hand-history-card-wrapper {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 0px;
	gap: 4px;
}

.CardJustifyEnd {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
}

.CardJustify {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 4px;
}

.TableNumber {
	color: #c7ad7a !important;
	margin-left: 5px;
}

.hand-history-card-wrapper p {
	margin: 0;
	color: #fff;
}

.hand-history-card-wrapper.highlighted-winner-cards {
	background: #c7ad7a;
	border-radius: 4px;
	padding: 4px;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
}

.hand-history-card-wrapper img {
	width: 29px;
	height: 44px;
}

/************************** Rank Tab *********************/

.tab-card-element .element-title {
	font-family: "DDCHardware";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	color: #a0aabb;
	margin: 0;
}

.rank-element-head .hand-winner-name p {
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
}

.rank-chips-details {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	padding: 0px;
	gap: 4px;
}

.rank-chips-details p {
	font-family: "DDCHardware";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	color: #fff;
	margin: 0;
}

/********************** Viewer Tab ********************/

.viewer-profile-status {
	position: relative;
}

.viewer-profile-status::before {
	position: absolute;
	content: "";
	width: 6px;
	height: 6px;
	top: 0;
	left: 0;
	border-radius: 100%;
}

.viewer-profile-status.online::before {
	background-color: #83bc9a;
}

.viewer-profile-status.offline::before {
	background-color: #d93355;
}

.viewer-call-icon {
	cursor: pointer;
}

/************************************************************************************/
/********************************* Multi Table ***************************************/
/************************************************************************************/

.multi-table-slider-wrapper {
	background: transparent !important;
	/*min-height: 299px !important;*/
}

.player-game-view-top-left.multi-table {
	width: 916px;
}

.player-game-view-container.multi-table,
.player-game-view-container.multi-table .final-winner,
.player-game-view-container.multi-table .game-viewport {
	width: 916px;
	height: 510px;
}

.player-game-view-container.multi-table .player-game-grid {
	transform: scale(73%) translate(0%, -17.7%);
}

.player-game-view-container.multi-table .final-winner-main {
	transform: scale(73%) translate(-17.7%, -17.7%);
}

.tab-card-element.hand-chat-elem {
	padding: 8px 16px;
}

.hand-chat-wrapper {
	display: flex;
	width: 100%;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
	gap: 8px;
	padding: 12px;
	background: #454851;
	border-radius: 8px;
}

.hand-chat-wrapper.table-last-winner {
	position: relative;
	width: 100%;
	justify-content: center;
	display: flex;
	padding: 56px 12px 12px 12px;
	flex-direction: column;
	align-items: center;
	gap: 8px;
	align-self: stretch;
	border-radius: 8px;
	background: linear-gradient(135deg, #eab20b 0%, #f9ce1f 100%, #f9ce1f 100%);
	box-shadow: 0px 0px 30px 0px rgba(186, 143, 42, 0.5);
	margin-top: 43px;
}

.hand-chat-wrapper.table-last-winner::before {
	content: "";
	position: absolute;
	background-image: url(../../../assets/images/confitti.png);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100%;
	height: 80%;
	top: 0;
	left: 0;
	right: 0;
}

.player-game-view-top-right.multi-table .tab-card-element,
.player-game-view-top-right.multi-table .hand-chat-wrapper {
	flex-direction: row;
	align-items: center;
}

.player-game-view-top-right.multi-table .tab-card-element .hand-history-card-wrapper {
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
}

/************************* Game New Sidebar collapse ***********************/

.player-game-view-top-left.hide {
	width: 1515px;
}

.sidebar-toggle {
	width: 24px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #ffffff;
	box-shadow: 0px 5.8156px 34.8936px #1b1d23;
	border-radius: 8px;
	position: absolute;
	left: -12px;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
}

.sidebar-toggle.waiting-sidebar {
	top: 55px;
	left: -10px;
}

.sidebar-toggle:hover {
	background: #a0aabb;
}

.sidebar-toggle:focus,
.sidebar-toggle:focus-visible {
	background: #fff;
	box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}

.sidebar-toggle svg {
	transform: rotate(180deg);
	transition: all 0.2s;
}

.sidebar-toggle.hide svg {
	transform: rotate(0deg);
}

.game-sidebar-main.hide {
	min-width: unset;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
}

.game-sidebar-main.hide .game-stats-wrapper {
	flex-direction: column;
}

.game-sidebar-main.hide .side-setting-wrapper,
.game-sidebar-main.hide .table-drop-wrapper,
.game-sidebar-main.hide .game-stats-wrapper {
	width: 105px;
}

.game-sidebar-main.hide .side-setting-wrapper .setting-btn.btn {
	transition: unset;
}

.game-sidebar-main.hide .side-setting-wrapper .setting-btn-group.camera-btn-wrapper,
.game-sidebar-main.hide .side-setting-wrapper .setting-btn-group.speaker-btn-wrapper,
.game-sidebar-main.hide .side-setting-wrapper .setting-btn-group.mic-btn-wrapper,
.game-sidebar-main.hide .chat-wrapper {
	display: none;
}

.player-game-view-top-right.hide.multi-table {
	min-width: unset;
	width: unset;
}

.game-sidebar-main.hide .stat-btn.btn h3 {
	display: none;
}

.player-game-view-top-left.hide.multi-table .player-game-view-container.multi-table {
	width: 100%;
}

/************************* Game New Sidebar collapse ***********************/

.player-action-view.timer {
	background: #c7ad7a;
	border-radius: 2px;
	border-color: #c7ad7a;
	font-family: "DDCHardware";
	font-style: normal;
	font-weight: 400;
	font-size: 30px;
	line-height: 38px;
	display: flex;
	align-items: center;
	text-align: center;
	color: #ffffff;
}

.player-length-3 .player-action-view.timer,
.player-length-4 .player-action-view.timer {
	font-size: 26px;
	line-height: 32px;
}

.add-time {
	width: 40px;
	height: 40px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 2px;
	background: #57b661;
	border-radius: 6px;
	z-index: 99;
}

.add-time span {
	position: relative;
	font-family: "DDCHardware";
	font-style: normal;
	font-weight: 400;
	font-size: 26px;
	line-height: 32px;
	color: #ffffff;
	cursor: pointer;
}

.add-time span img {
	position: absolute;
	top: 18px;
	right: 0;
	width: 10px;
	height: 10px;
}

.player-action-view.timer.green {
	background: #57b661;
	border-color: #57b661;
}

/*************************** Show Card BTN *********************/

.show-bg-btn.btn {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 8px 16px;
	gap: 8px;
	background: #c7ad7a;
	border-color: #c7ad7a;
	border-radius: 6px;
	color: #ffffff;
}

.show-bg-btn.btn:hover {
	background: #af935d;
	border-color: #af935d;
}

.show-bg-btn.btn:focus,
.show-bg-btn.btn:focus-visible,
.show-bg-btn.btn-check:checked+.show-bg-btn.btn,
.show-bg-btn.btn.active,
.show-bg-btn.btn.show,
.show-bg-btn.btn:first-child:active,
:not(.show-bg-btn.btn-check)+.show-bg-btn.btn:active {
	background: #c7ad7a;
	border-color: #c7ad7a;
	box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}

.show-bg-btn.btn:disabled,
.show-bg-btn.btn.disabled {
	background: #c7ad7a;
	border-color: #c7ad7a;
	color: #31343e;
}

.show-outline-btn.btn {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 8px 16px;
	gap: 8px;
	background: #373940;
	border-color: #c7ad7a;
	border-radius: 6px;
	color: #c7ad7a;
}

.show-bg-btn.btn span,
.show-outline-btn.btn span {
	font-family: "DDCHardware";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	width: 48px;
}

.show-outline-btn.btn:hover {
	background: #373940;
	border-color: #af935d;
	color: #af935d;
}

.show-outline-btn.btn:focus,
.show-outline-btn.btn:focus-visible,
.show-outline-btn.btn-check:checked+.show-outline-btn.btn,
.show-outline-btn.btn.active,
.show-outline-btn.btn.show,
.show-outline-btn.btn:first-child:active,
:not(.show-outline-btn.btn-check)+.show-outline-btn.btn:active {
	background: #373940;
	border-color: #373940;
	box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
	color: #c7ad7a;
}

.show-outline-btn.btn:disabled,
.show-outline-btn.btn.disabled {
	background: #373940;
	border-color: #c7ad7a;
	color: #ffffff;
}

.player-game-action-grid.show-card {
	justify-content: center;
}

.show-btn-card {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	gap: 1.5px;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	height: 24px;
	border-radius: 2px;
	overflow: hidden;
}

.show-btn-card img {
	width: 100%;
	height: 50px;
	-o-object-fit: contain;
	object-fit: contain;
}

/*************************** Show Card BTN *********************/

.player-length-3 .company-logo-section img,
.player-length-4 .company-logo-section img {
	width: 26px;
	height: 26px;
}

.player-box-iframe.multi-table-avatar {
	display: none;
}

.dealer-name-wrapper.multi-table-count {
	display: none;
}

.rail-slider-wrapper.multi-table-slider-wrapper {
	opacity: 0;
	visibility: hidden;
	display: none;
}

.rail-slider-wrapper.multi-table-slider-wrapper.multi-table-show {
	display: flex;
	height: 325.3px;
	opacity: 1;
	visibility: visible;
	overflow: unset;
}

.multi-table-dealer-view {
	display: none;
}

.add-player-notification {
	position: fixed;
	display: flex;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	justify-content: space-between;
	align-items: center;
	padding: 16px;
	gap: 8px;
	top: 20px;
	right: 0;
	border-radius: 12px;
	background: #83bc9a;
	box-shadow: 0px 10px 60px 0px #1b1d23;
	z-index: 99;
}

.add-player-notification p {
	margin: 0;
	color: #fff;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
}

.add-player-notification span {
	cursor: pointer;
}

/*.player-pot-count ul li:nth-child(3) {
  border: none;
}*/
/*.player-pot-count ul li:nth-child(4),
.player-pot-count ul li:nth-child(5),
.player-pot-count ul li:nth-child(6),
.player-pot-count ul li:nth-child(7),
.player-pot-count ul li:nth-child(8),
.player-pot-count ul li:nth-child(9) {
  display: none;
}*/

/************************** One to One notification *********************************/

.one-to-one-call-notification-wrapper {
	position: fixed;
	display: flex;
	flex-direction: column;
	gap: 6px;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	bottom: 34px;
	right: 0;
	z-index: 999;
	transition: all 300ms;
}

.one-to-one-call-notification {
	width: 116px;
	/*height: 40px;*/
	display: flex;
	align-items: center;
	border-radius: 12px;
	background: radial-gradient(341.25% 341.25% at 54.33% -118.75%,
			rgba(255, 255, 255, 0.2) 0%,
			rgba(255, 255, 255, 0) 100%),
		rgba(87, 98, 102, 0.5);
	box-shadow: 0px 10px 60px 0px #1b1d23;
	padding: 8px;
	justify-content: space-between;
	gap: 20px;
	overflow: hidden;
	transition: ease 200ms;
}

.one-to-one-call-notification:hover {
	width: 360px;
	height: 56px;
	background: #4c505e;
	padding: 8px;
	padding-left: 16px;
}

.one-to-one-notification-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 20px;
	width: 100%;
}

.one-to-one-call-notification:hover .one-to-one-notification-wrapper {
	justify-content: space-between;
}

/*.rail-end-call.btn {
  display: none !important;
}*/

/*.one-to-one-call-notification:hover .rail-end-call.btn {
  display: flex !important;
}*/

.one-to-one-player-name {
	display: none;
}

.one-to-one-call-notification:hover .one-to-one-player-name {
	display: flex;
	flex-direction: column;
}

.one-to-one-player-name p {
	color: #fff;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
	margin: 0;
}

.one-to-one-player-name span {
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;
	color: #a0aabb;
}

.one-to-one-notification-wrapper p {
	color: #fff;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 18px;
	margin: 0;
}

.one-to-one-player-name.joined {
	flex-direction: row;
	-moz-column-gap: 4px;
	column-gap: 4px;
}

.close-notification-btn {
	cursor: pointer;
}

.fade.poker-popup.player-history-modal,
.fade.bar-room-setting-popup {
	left: 50%;
}

.player-box.player-position3 .profile-viewer,
.player-box:last-child .profile-viewer,
.player-box.player-position4 .profile-viewer {
	top: unset;
	bottom: 0;
}

/****************************************************************************************/
/******************************* Hand Info View ***********************************/
/****************************************************************************************/

.hand-info-view {
	position: fixed;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	left: 0px;
	z-index: 99;
	top: 20px;
	transition: all 0.2s;
}

.hand-info-main {
	display: inline-flex;
	align-items: flex-start;
	position: relative;
}

.hand-info-wrapper {
	display: flex;
	width: 0;
	height: 898px;
	flex-direction: column;
	align-items: flex-start;
	gap: 20px;
	border-radius: 0px 20px 20px 0px;
	background: #31343e;
	box-shadow: 0px 10px 60px 0px #1b1d23;
	overflow: hidden;
	visibility: hidden;
	opacity: 0;
	transition: all 0.2s;
}

.hand-info-wrapper.show {
	width: 374px;
	opacity: 1;
	visibility: visible;
}

.hand-info-head {
	display: flex;
	height: 58px;
	padding: 12px 16px;
	align-items: center;
	gap: 8px;
	flex-shrink: 0;
	align-self: stretch;
}

.hand-info-head h5 {
	color: #fff;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 26px;
	text-transform: uppercase;
	margin: 0;
}

.hand-info-card-wrapper {
	display: flex;
	padding: 0px 16px;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
}

.hand-info-card {
	display: flex;
	align-items: center;
	gap: 8px;
}

.hand-info-card-text {
	display: flex;
	width: 135px;
	flex-direction: column;
	align-items: flex-start;
	gap: 4px;
}

.hand-info-card-text h6 {
	color: #fff;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	margin: 0;
	align-self: stretch;
}

.hand-info-card-text p {
	color: #fff;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 14px;
	margin: 0;
	align-self: stretch;
}

.hand-info-card-box {
	display: flex;
	align-items: flex-start;
	gap: 3.438px;
}

.hand-info-card-box img {
	width: 37.249px;
	height: 55.014px;
	-o-object-fit: contain;
	object-fit: contain;
}

.hand-info-btn {
	position: absolute;
	right: -48px;
	display: flex;
	width: 48px;
	height: 40px;
	padding: 4px;
	justify-content: center;
	align-items: center;
	border-radius: 0px 12px 12px 0px;
	background: #c7ad7a;
	box-shadow: 0px 10px 60px 0px #1b1d23;
	margin-top: 30px;
	cursor: pointer;
	z-index: 99;
}

.hand-info-btn svg {
	width: 28px;
	height: 28px;
	transition: all 0.2s;
}

.hand-info-btn.close svg {
	transform: rotate(90deg);
}

/******************************* Hand Info View End ***********************************/

.ping-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 6px;
	position: fixed;
	top: 0;
	left: 0;
	padding: 6px;
	flex-wrap: wrap;
}

.ping-color {
	display: flex;
	align-items: center;
	justify-content: center;
}

.ping-color svg path:nth-child(2) {
	-webkit-animation: wifiAnimate ease 300ms infinite;
	animation: wifiAnimate ease 1.6s infinite;
	-webkit-animation-delay: 0.4s;
	animation-delay: 0.4s;
}

.ping-color svg path:nth-child(3) {
	-webkit-animation: wifiAnimate ease 300ms infinite;
	animation: wifiAnimate ease 1.6s infinite;
	-webkit-animation-delay: 0.6s;
	animation-delay: 0.6s;
}

.ping-color svg path:nth-child(4) {
	-webkit-animation: wifiAnimate ease 1.6s infinite;
	animation: wifiAnimate ease 1.6s infinite;
	-webkit-animation-delay: 0.8s;
	animation-delay: 0.8s;
}

@-webkit-keyframes wifiAnimate {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes wifiAnimate {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.ping-color.green svg path {
	fill: #57b661;
}

.ping-color.yellow svg path {
	fill: #ddab34;
}

.ping-color.red svg path {
	fill: #d93355;
}

.ping-wrapper span {
	font-size: 11px;
	font-weight: 400;
	color: #fff;
}

.upload-chat-image-modal .intro-drag-video .error-msg {
	position: absolute;
	top: 60%;
	font-weight: 600;
	color: #d93355;
}

.multi-table-slider-main .final-winner-main {
	transform: scale(0.42) translate(-69%, -69%);
	height: 762px;
}

.multi-table-slider-main .final-winner {
	height: 324px;
	width: 520px;
	overflow: hidden;
	background: #31343e;
	border: 1px solid #373940;
	border-radius: 16px;
}

.multi-table-slider-main .final-winner-main .final-winner-player-box {
	transform: scale(1.2);
}

.multi-table-game-view .final-winner-main {
	transform: scale(0.27);
	transform-origin: top left;
}

.player-box-user.handname h4 {
	color: #fff;
	font-size: 16px;
	line-height: 16px;
	font-family: "Gilroy" !important;
	font-weight: 500;
}

.game-viewport.hide-player-view,
.game-viewport.hide-winner-view {
	position: fixed;
	opacity: 0;
	visibility: hidden;
	z-index: -1;
}

/************************** Hand Strength *************************/

.hand-strength-wrapper {
	position: absolute;
	width: 136px;
	top: -76px;
	left: 50%;
	z-index: 9999;
	color: #fff;
	display: flex;
	padding: 12px;
	flex-direction: column;
	align-items: center;
	gap: 4px;
	border-radius: 10px;
	background: #4c505e;
	transform: translate(-50%, -36%) scale(1.66);
	box-shadow: 0px 10px 60px 0px #1b1d23;
}

.hand-strength-wrapper>h6 {
	color: #fff;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	margin: 0;
}

.hand-strength-wrapper::before {
	position: absolute;
	content: "";
	width: 20px;
	height: 8px;
	background: url(../../../assets/images/handArrow.svg);
	background-repeat: no-repeat;
	background-position: top;
	background-size: contain;
	bottom: -7px;
	left: 50%;
	transform: translateX(-50%);
}

.player-length-3 .hand-strength-wrapper,
.player-length-4 .hand-strength-wrapper {
	transform: translate(-34%, -36%) scale(1.66);
}

.hand-strength-meter {
	position: absolute;
	bottom: -14px;
	width: 100%;
	z-index: 999;
}

.hand-strength-wrapper .progress,
.hand-strength-meter .progress {
	width: 100%;
	height: 8px;
	background: #23262e;
	border: 50px;
	padding: 1px;
}

.hand-strength-wrapper .progress-bar,
.hand-strength-meter .progress-bar {
	position: relative;
	overflow: hidden;
	background: transparent;
}

.hand-strength-wrapper .progress-bar::before,
.hand-strength-meter .progress-bar::before {
	position: absolute;
	content: "";

	height: 6px;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	border-radius: 4px;
	background: linear-gradient(90deg, #d6002b 0%, #ffc742 49.79%, #57b661 100%);
	-webkit-backdrop-filter: blur(15px);
	backdrop-filter: blur(15px);
}

.hand-strength-wrapper .progress-bar::before {
	width: 113px;
}

.hand-strength-meter .progress-bar::before {
	width: 127px;
}

.player-length-1 .hand-strength-meter .progress-bar::before {
	width: 154px;
}

.player-length-1 .player-box-card.strength-meter-show {
	transform: translate(17%, -2%) scale(62%);
}

.player-game-left-view.player-length-1 .player-box-card.strength-meter-show {
	transform: translate(-17%, -2%) scale(62%);
}

.player-length-2 .player-box-card.strength-meter-show {
	transform: translateY(-4%) scale(74%);
}

.player-length-3 .player-box-card.strength-meter-show {
	margin-bottom: 14px;
}

.player-length-4 .player-box-card.strength-meter-show {
	margin-bottom: 7px;
}

.player-length-3 .player-box-card.strength-meter-show .hand-strength-meter {
	transform: translateX(18%) scaleX(1.35);
}

.player-length-3 .player-box-card.strength-meter-show .hand-strength-meter .progress-bar::before,
.player-length-4 .player-box-card.strength-meter-show .hand-strength-meter .progress-bar::before {
	width: 100px;
}

.player-length-4 .player-box-card.strength-meter-show .hand-strength-meter {
	transform: translate(18%, -24%) scaleX(1.35);
}

.winner-confetti-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 99;
	overflow: hidden;
}

.player-length-1 .winner-confetti-wrapper svg {
	scale: 1.2;
}

.player-length-3 .winner-confetti-wrapper svg {
	scale: 2;
}

.player-length-2 .winner-confetti-wrapper svg,
.player-length-4 .winner-confetti-wrapper svg {
	scale: 2.7;
}

.final-winner-confetti-wrapper {
	pointer-events: none;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
}

.player-box-card span img.cards-for-multiTable-view {
	display: none;
}

.player-winner-amount {
	bottom: 40px;
	left: 50%;
	transform: translate(-50%, 100%);
	background: #ddab34;
	width: 145px;
	border-radius: 16px 16px 0px 0px;
	overflow: hidden;
	justify-content: center;
	opacity: 0;
	visibility: hidden;
	transition: all 300ms;
}

.player-winner-amount.player-box-user:before {
	display: none;
}

.player-winner-amount.show {
	opacity: 1;
	visibility: visible;
	transform: translate(-50%, 0);
}

/************************* Silent Disco Modal *************************/

.silent-disco-modal-wrapper {
	display: flex;
	padding: 36px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 20px;
}

.disco-modal-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 16px;
}

.silent-disco-modal-wrapper>svg {
	width: 56px;
	height: 56px;
}

.disco-modal-content h5 {
	color: #fff;
	text-align: center;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 26px;
	text-transform: uppercase;
	margin: 0;
}

.disco-modal-content p {
	color: #fff;
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	margin: 0;
}

.disco-check-wrapper .add-check-box.form-check {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
	align-self: stretch;
	margin: 0;
	padding: 0;
}

.disco-check-wrapper {
	display: flex;
}

.outline-btn.disco-cancel-btn.btn,
.outline-btn.disco-cancel-btn.btn:hover,
.outline-btn.disco-cancel-btn.btn:focus,
.outline-btn.disco-cancel-btn.btn:focus-visible,
.outline-btn.disco-cancel-btn.btn:active,
:not(.outline-btn.disco-cancel-btn.btn-check)+.outline-btn.disco-cancel-btn.btn:active {
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	color: #c7ad7a;
}

.game-sidebar-main .main-game-music {
	max-width: 100%;
	width: 100%;
}

.game-sidebar-main.hide .music-track-wrapper {
	display: none;
}

.game-sidebar-main.hide .main-game-music {
	justify-content: center;
}


/* Intro Css */
.soundButton {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100% !important;
	max-width: 50px;
	background: transparent !important;
	border-color: black !important;
}

.soundOpacity {
	opacity: 0.1;
}

.soundButton:hover {
	opacity: 1;
}

.buttonSection-bottom {
	position: absolute;
	bottom: 20px;
	right: 20px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 20px;
	width: 100%;
}

/**********************************************************************/

/********************** 1 to 1 Call New UI **************************/
.full-h {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 99999;
}

.full-h.one-full-h {
	height: auto;
	position: unset;
	width: auto;
	z-index: unset;
}

.one-to-one-view .side-setting-wrapper .setting-btn-group,
.one-to-one-view .side-setting-wrapper .btn-primary {
	pointer-events: none;
}

.one-full-h .side-setting-wrapper .setting-btn-group,
.one-full-h .side-setting-wrapper .btn-primary {
	pointer-events: unset;
}

.draggable-container {
	position: absolute;
	top: 50px;
	right: 50px;
	padding: 16px 20px;
	border-radius: 20px;
	box-shadow: 0px 10px 60px 0px #1b1d23;
	background: #31343e;
	max-width: 370px;
	width: 100%;
	cursor: grab;
	cursor: -webkit-grab;
	user-select: none;
	z-index: 9999;

	&:active {
		cursor: grabbing;
	}
}


/*.draggable-container .setting-btn{
    padding: 8px 10px;
  }*/


.draggable-head {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 16px;
}

.draggable-title {
	color: #fff;
	font-family: "DDCHardware";
	font-size: 20px;
	font-style: normal;
	line-height: 26px;
	text-transform: uppercase;
}

.draggable-actions button,
.draggable-actions button:hover,
.draggable-actions button:focus,
.draggable-actions button:focus:not(:focus-visible) {
	background: transparent;
	border: none;
	cursor: pointer;
	color: #fff;
	font-size: 16px;
	padding: 0;
}

.draggable-actions {
	display: flex;
	gap: 12px;
	align-items: center;
}

.draggable-content .side-setting-wrapper {
	padding: 16px 0px 0px;
	background: transparent;
	border-radius: 0;
	justify-content: space-between;
}

.draggable-content .close-btn.btn {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 8px 12px;
	gap: 4px;
	background: #DF1C42;
	border-radius: 100%;
	width: 48px;
	height: 48px;
	border-color: #df1c41;
	position: relative;
	outline: 0;
	box-shadow: none;
	transition: all 0.2s;
}

.draggable-content .rail-player-wrapper {
	width: 100%;
	height: 220px;
}

.draggable-content .rail-slider-content {
	padding: 0;
	padding-top: 16px;
	flex-direction: column;
}

.draggable-full .draggable-content .rail-player-wrapper {
	width: 50%;
	height: 220px;
}

.draggable-full .draggable-content .rail-slider-content {
	padding: 0;
	padding-top: 0px;
	flex-direction: row;
}

.draggable-content .rail-player-wrapper .player-box-iframe .main-player-camoff img {
	height: 216px;
}

.draggable-content .rail-username {
	width: calc(100% - 16px);
}


/*************** Full Width **************/

.one-to-one-view {
	overflow: hidden;
}

.one-to-one-view body {
	overflow: hidden;
}

.draggable-container.draggable-full {
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	max-width: 100%;
	position: fixed;
	box-shadow: none;
}

.draggable-full .draggable-head {
	justify-content: center;
	position: relative;
}

.draggable-full .draggable-actions {
	position: absolute;
	right: 0;
}

.draggable-full .draggable-content {
	height: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	position: relative;
	transition: width 0.3s ease-in-out;
}

.draggable-content.drag-collapse {
	width: unset;
}

.draggable-content.drag-expand {
	width: calc(100% - 409px);
}

.draggable-full .draggable-content .rail-player-wrapper {
	width: 450px;
	height: 450px;
}

.draggable-full .draggable-content .rail-player-wrapper .player-box-iframe .main-player-camoff img {
	height: 446px;
}

.draggable-full .draggable-content .side-setting-wrapper {
	max-width: 440px;
	width: 100%;
	position: absolute;
	bottom: 50px;
}


/*************** Actions **************/


.one-to-one-view .drop-wrapper {
	display: none;
}

.one-to-one-view .btn-down-icon.rotate {
	transform: rotate(0deg);
}

.one-to-one-view .oneToOneShow .drop-wrapper {
	display: block;
}

.one-to-one-view .normalActionShow .drop-wrapper {
	display: block;
}

.one-to-one-view .oneToOneShow .btn-down-icon.rotate {
	transform: rotate(-180deg);
}

.one-to-one-view .normalActionShow .btn-down-icon.rotate {
	transform: rotate(-180deg);
}


.one-to-one-view .draggable-full .oneToOneShow .drop-wrapper {
	top: unset;
	bottom: 50px;
}

.chat-section {
	display: flex;
	flex-direction: column;
	background-color: #454851;
	border-radius: 16px;
	margin-top: 1rem;
	/*padding: 1rem;*/
}

.chat-header {
	padding: 1rem;
	/*border-bottom: 1px solid #4C505E;*/
	position: relative;
}

.heading {
	font: Gilroy;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	color: #FFFFFF;
}

.expandable,
.close-icon {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 50%;
	right: 15px;
	transform: translateY(-50%);
	cursor: pointer;
	padding: 5px;
	border-radius: 50%;
}

.expandable:hover,
.close-icon:hover {
	background-color: #31343E;
}

.close-icon img {
	width: 18px;
}

.chat-content {
	/*padding: 0 1rem;*/
	height: 200px;
	overflow: auto;
}

.chat-full-content {
	padding: 0 1rem;
	height: 738px;
	overflow: auto;
}

.chat-full-content-body {
	justify-content: flex-end;
}

.chat-footer {
	padding: 1rem;
	/*border: 1px solid red;*/
	border-top: 1px solid #4C505E;
}

.chat-input {
	background: #31343E !important;
	border-radius: 12px !important;
}

.floating-chat {
	position: absolute;
	right: 20px;
	bottom: 20px;
	max-width: 409px;
}

.floating-chat-icon {
	position: absolute;
	right: 20px;
	bottom: 20px;
	cursor: pointer;
}

.player-game-view-wrapper-bottom {
	width: 100%;
}

.player-game-view-wrapper-bottom.rail-open-view {
	padding: 0 2rem;
}

.table-container {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 100%;
	padding: 15px;
}

.participants-table {
	display: flex;
	flex-direction: column;
	gap: 10px;
	width: 100%;
}

.table-title {
	font-size: 1rem;
	line-height: 20px;
	font-weight: 410;
	color: #A0AABB;
}

.participants-data {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.participant-row {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	gap: 10px;
}

.participant-info {
	display: flex;
	gap: 0.5rem;
	align-items: center;
}

.participant-avatar {
	height: 32px;
	width: 32px;
	position: relative;
}

.participant-avatar>img {
	height: 100%;
	width: 100%;
	border-radius: 50%;
	object-fit: cover;
}

.online-status {
	position: absolute;
	top: 1px;
	left: 0;
	height: 6px;
	width: 6px;
	border-radius: 50%;
	background-color: #83BC9A;
	z-index: 3;
}

.name-container {
	display: flex;
	gap: 5px
}

.name-container>svg {
	height: 16px;
	width: 16px;
}

.participant-name {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 14px;
	font-weight: 400;
	line-height: 16px;
	color: #FFFFFF;
}

.participant-actions {
	display: flex;
	gap: 0.5rem;
}

.participant-chat-icon,
.participant-profile-icon,
.participant-call-icon {
	display: inline-flex;
	cursor: pointer;
	position: relative;
}

.participant-chat-icon>svg,
.participant-profile-icon>svg,
.participant-call-icon>svg {
	width: 19.5px;
	height: 19.5px;
}

.participant-chat-icon>svg>path,
.participant-call-icon>svg>path {
	fill: none;
	stroke: #A0AABB;
}

.chat-tooltip-container {
	display: none;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 25px;
	right: 50%;
	transform: translateX(50%);
	background-color: #4C505E;
	padding: 4px 8px;
	border-radius: 2px;
	z-index: 2;
}

.participant-call-icon .chat-tooltip-container {
	right: 16px;
}

.participant-chat-icon:hover .chat-tooltip-container,
.participant-profile-icon:hover .chat-tooltip-container,
.participant-call-icon:hover .chat-tooltip-container {
	display: flex;
	-webkit-animation: toolTipAnimate 0.3s ease;
	animation: toolTipAnimate 0.3s ease;
}

@-webkit-keyframes toolTipAnimate {
	0% {
		opacity: 0;
	}

	50% {
		opacity: 0.5;
	}

	100% {
		opacity: 1;
	}
}

@keyframes toolTipAnimate {
	0% {
		opacity: 0;
	}

	50% {
		opacity: 0.5;
	}

	100% {
		opacity: 1;
	}
}

.tooltip-relative-container {
	display: flex;
	position: relative;
}

.chat-tooltip-info {
	font-size: 12px;
	line-height: 12px;
	text-align: center;
	font-weight: 400;
	color: #C1C7D0;
	text-wrap: nowrap;
	letter-spacing: 0.5px;
}

.tooltip-pointer {
	position: absolute;
	top: -7px;
	left: 50%;
	transform: translateX(-50%);
	height: 8px;
	width: 8px;
	background-color: #4C505E;
	transform: translateX(-50%) rotate(45deg);
	border-radius: 2px;
	z-index: -1;
}

.participant-call-icon .chat-tooltip-container .tooltip-pointer {
	left: 20px;
}

.player-box-iframe.full-height {
	height: 100%;
}

.no-message-container {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.no-message-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 5px;
}

.no-message-wrapper>span:nth-child(1) {
	font-size: 14px;
	font-weight: 400;
	line-height: 16px;
	color: #FFFFFF;
}

.no-message-wrapper>span:nth-child(2) {
	font-size: 12px;
	font-weight: 400;
	line-height: 14px;
	color: #A0AABB;
}

.call-in-progress {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	gap: 0.7rem;
	padding-bottom: 2rem;
}

.player-length-3 .player-box-iframe .call-in-progress,
.player-length-4 .player-box-iframe .call-in-progress {
	border-left: 1px solid #454851;
}

.call-in-progress>span {
	font-size: 14px;
	font-weight: 400;
	line-height: 16px;
	color: #909090;
}

.call-in-progress>div {
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: #D93355;
}

.viewer-wrapper {
	position: relative;
	width: 100%;
	/*background-color: #1C1D20;*/
}

.multi-table-viewer-wrapper {
	background-color: #1C1D20;
}

.profile-viewer.participant-profile-viewer {
	top: unset;
	bottom: 10px;
	left: 10px;
	width: 94%;
}



/************************ Profile View **********************/

.profile-view {
    position: absolute;
    background: #1C1D20;
    max-width: 255px;
    width: 100%;
    z-index: 99;
    border-radius: 16px;
    border: 1px solid #666D80;
    box-shadow: 59px 304px 87px 0px rgba(0, 0, 0, 0.00), 38px 195px 79px 0px rgba(0, 0, 0, 0.01), 21px 110px 67px 0px rgba(0, 0, 0, 0.05), 9px 49px 50px 0px rgba(0, 0, 0, 0.09), 2px 12px 27px 0px rgba(0, 0, 0, 0.10);
    bottom: -90px;
    right: -255px;
    overflow: hidden;
	display: none;
}

.profile-view-icon {
    width: 84px;
    height: 84px;
    border-radius: 100%;
    overflow: hidden;
    margin: 0px auto;
    top: 32px;
    position: relative;
}

.profile-view-icon img {
    width: 100%;
    height: 100%;
	object-fit: cover;
}

.profile-view-header {
    background: #373A4180;
    height: 75px;
	position: relative;
}

.profile-view-close {
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
}


.profile-view-content {
    padding: 50px 20px 20px;
    text-align: center;
}

.profile-view-content h4 {
    color: #ECEFF3;
    font-size: 32px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 120% !important;
    font-family: "Gilroy" !important;
    padding-bottom: 6px;
	margin: 0;
}

.profile-view-content p {
    color: #C1C7D0;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%;
    margin: 0;
    padding-top: 2px;
}

.profile-view-content a {
    display: flex;
    justify-content: center;
    margin-top: 8px;
}



.player-game-right-view .profile-view{
	left: -255px;
	right: unset;

}

.player-box-user{
	cursor: pointer;
}

.player-box-user:hover .profile-view{
	display: block;
}

.visible-always .profile-view{
 display: block;
}

.multi-table-game-view .profile-view {
    display: none !important;
}

/*.player-box-user .profile-view{
	pointer-events: none
}*/

.vis .profile-view {
    display: block;
}







.player-camera-box .tip-btn {
	background: #DF1C41;
	border-radius: 6px;
	max-width: 145px;
	width: 100%;
	height: 38px;
	font-weight: 400;
	font-size: 14px;
	line-height: 21.7px;
	color: #ECEFF3;
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0px auto;
	transition: all 0.5s;
	cursor: pointer;
	z-index: 1;
	position: absolute;
	bottom: 5px;
	left: 5px;
}

.player-camera-box .tip-btn.disabled {
	background-color: #373A41;
	cursor: default;
}

.player-bidtimer-drop-wrapper.tip-drop-wrapper {
	top: unset;
	height: unset;
	max-width: 204px;
	bottom: 55px;
	left: 5px;
	z-index: 1;
}

.tip-drop-wrapper .player-blind-timer-dropdown {
	background: #292B31;
	/*max-width: 204px;*/
	padding: 8px 12px;
	display: flex;
	flex-direction: column;
	gap: 16px;
	width: 100%;
}

.tip-drop-wrapper .player-blind-timer-dropdown h4 {
	padding-bottom: 0;
}

.tip-drop-wrapper .form-check-input {
	background: #1C1D20;
}

.tip-popup-list-row {
	display: flex;
	gap: 8px;
	/*margin-bottom: 5px;*/
}

.tip-drop-wrapper .social-logo {
	height: 20px;
	width: 20px;
}

.tip-drop-wrapper .social-logo>img {
	height: 100% !important;
	width: 100%;
}

.tip-popup-list-row>span:nth-child(3) {
	color: #ECEFF3;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 160%;
	/* 25.6px */
}

.tip-drop-wrapper .close-tip-popup {
	width: 20px;
	height: 20px !important;
	top: -30px;
	left: -30px;
	position: absolute;
	z-index: 999;
	left: 50%;
	transform: translateX(-50%);
	cursor: pointer;
}

.player-camera.view-1.dealer-camera-view .tip-drop-wrapper img {
	height: 20px !important;
}

.tip-drop-wrapper .add-check-box .form-check-input:checked,
.tip-drop-wrapper .add-check-box .form-check-input[type="radio"] {
	border: 1px solid #C7AD7A;
}

.bar-room-leave-popup.request-popup .modal-dialog {
	max-width: 678px;
}

.bar-room-leave-popup.request-popup .bar-room-leave {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.bar-room-leave-popup.request-popup .bar-room-leave p {
	font-weight: 400;
	color: #ECEFF3;
}

.bar-room-leave-popup.request-popup .bar-room-subtitle {
	margin: 0;
	color: #C1C7D0;
	text-align: center;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 155%;
	/* 27.9px */
	max-width: unset;
}

.bar-room-leave-popup.request-popup img {
	margin-bottom: 24px;
}

.bar-room-leave-popup.request-popup .divider-bar {
	width: 400px;
	height: 8px;
	background-color: #818898;
	border-radius: 100px;
	margin-top: 24px;
}

.player-view-main .share-tip {
	position: absolute;
	top: 30px;
	left: 50%;
	transform: translateX(-50%);
	height: 70px;
	width: 873px;
	border-radius: 8px;
	border: 1px solid #666D80;
	background: linear-gradient(92deg, #1C1D20 32.8%, #757986 134.78%);
	box-shadow:
		10px 10px 60px 0px #0E0E10,
		140px 313px 137px 0px rgba(0, 0, 0, 0.01),
		79px 176px 116px 0px rgba(0, 0, 0, 0.05),
		9px 20px 47px 0px rgba(0, 0, 0, 0.10);
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px 24px;
	z-index: 2;
}

.player-view-main .share-tip>div {
	display: flex;
	gap: 12px;
	align-items: center;
}
.player-view-main .share-tip>div>span {
	color: #fff;
}

.player-view-main .share-tip img {
	width: 32px;
	height: 32px;
}

.player-view-main .share-tip .share-tip-btn {
	background: #DF1C41;
	border-radius: 32px;
	max-width: 145px;
	width: 100%;
	height: 38px;
	font-weight: 400;
	font-size: 14px;
	line-height: 21.7px;
	color: #ECEFF3;
	border: none;
	/*font-family: "Gilroy-Medium";*/
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0px auto;
	transition: all 0.5s;
	cursor: pointer;
	padding: 0 10px;
}

.player-box-iframe .userBatchKey {
	position: absolute;
	top: 11px;
	left: 11px;
}

.player-box-iframe .userBatchKey img {
	height: auto;
	width: auto;

}

.player-game-left-view .player-box-grid .userBatchKey {
	left: auto;
	top: 11px;
	right: 11px;
}

.request-popup {
	left: 50%;
}

.closeIcon {
	position: absolute;
	right: 15px;
	top: 15px;
	cursor: pointer;
}
.btn-down-icon-multi-table {
	transition: all 0.2s;
	margin-right: 10px;
	display: inline-block;
}
.btn-down-icon-multi-table.rotate {
	transform: rotate(-180deg);
}

.talking-wave{
	background-color: #00acee;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    margin: 0 auto;
}

.talking-wave img{
	width: 25px;
    height: auto;
}

.already-in-call{
	background-color: #ff0000;
    border-radius: 50%;
    padding: 5px;
}


/*  Start Mic Animation */

.mic-container {
	height: 1.625em;
	width: 1.625em;
	position: relative;
  }
  
  .mic-grid {
	background-color: rgb(26, 115, 232);
	transition-property: background-color;
	transition-duration: 0.1915s;
	transition-timing-function: linear;
	transition-delay: 0.1915s;
	border-radius: 50%;
	align-items: center;
	color: #fff;
	display: flex;
	justify-content: center;
	position: absolute;
	height: 1.625em;
	width: 1.625em;
  }
  
  .mic-grid > .one,
  .mic-grid > .two,
  .mic-grid > .three {
	width: 0.25em;
	height: 1em;
	background: url("../../../assets/images/playerview/mic.png");
	background-repeat: no-repeat;
	background-size: 4em 1em;
	animation: stripeJiggleAnimation 0.6s steps(3, jump-none) 0s infinite;
  }
  
  @keyframes stripeJiggleAnimation {
	from {
	  background-size: 4em 0.8em;
	  background-position-y: 0.1em;
	}
	50% {
	  background-size: 4em 1em;
	  background-position-y: 0;
	}
	to {
	  background-size: 4em 0.8em;
	  background-position-y: 0.1em;
	}
  }
  
  .mic-grid > .two {
	margin-left: 0.125em;
	margin-right: 0.125em;
	animation-delay: 0.2s;
  }
  
  .mic-grid.default > .one,
  .mic-grid.default > .two,
  .mic-grid.default > .three {
	animation-name: none;
	background-position-x: 0;
  }
  
  .mic-grid.low > .one,
  .mic-grid.low > .three {
	background-position-x: -0.3125em;
  }
  
  .mic-grid.medium > .one,
  .mic-grid.medium > .three {
	background-position-x: -0.625em;
  }
  
  .mic-grid.high > .one,
  .mic-grid.high > .three {
	background-position-x: -0.9375em;
  }
  
  .mic-grid.extream > .one,
  .mic-grid.extream > .three {
	background-position-x: -1.25em;
  }
  
  .mic-grid.default > .two {
	background-position-x: 0;
  }
  
  .mic-grid.low > .two {
	background-position-x: -1.5625em;
  }
  
  .mic-grid.medium > .two {
	background-position-x: -1.875em;
  }
  
  .mic-grid.high > .two {
	background-position-x: -2.1875em;
  }
  
  .mic-grid.extream > .two {
	background-position-x: -2.5em;
  }

  /*  End Mic Animation */