.bar-room-setting-popup .modal-dialog {
  max-width: unset !important;
  width: 1328px !important;
}

.fade.bar-room-setting-popup.modal.show {
  max-width: unset !important;
  width: 1328px !important;
}

.fade.bar-room-setting-popup.main-game-setting.modal.show {
  height: unset !important;
}

.bar-room-setting-popup .modal-content {
  background: transparent;
  border: none;
}

.bar-room-setting-page .modal-backdrop.show {
  opacity: 1 !important;
  background: rgba(35, 38, 46, 0.9);
}

.bar-room-settings {
  position: relative;
  max-width: 1328px;
  width: 100%;
  background: #31343e;
  border-radius: 20px;
  padding: 30px 40px;
}

.bar-room-settings h2 {
  font-weight: 410;
  font-size: 36px;
  line-height: 46px;
  color: #ffffff;
  padding-bottom: 20px;
  margin: 0;
}

.bar-room-settings-grid {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
}

.bar-room-settings-grid:before {
  content: "";
  background: #454851;
  height: 100%;
  width: 2px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.bar-room-settings-audio {
  width: calc(50% - 50px);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.bar-room-audio-grid-full {
  width: 100%;
}

.bar-room-settings-video {
  width: calc(50% - 50px);
}

.bar-room-settings-audio h4 {
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0;
  padding-bottom: 16px;
}

.bar-room-settings-audio .form-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #a0aabb;
  margin: 0;
  padding-bottom: 10px;
}

.bar-room-settings-grid .css-1s2u09g-control {
  background: #454851;
  border: 1px solid #a0aabb;
  border-radius: 6px;
}

.bar-room-settings-grid .css-14el2xx-placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.bar-room-settings-grid .progress-bar {
  background-color: #83bc9a;
}

.bar-room-settings-grid .progress {
  background: #4c505e;
}

.bar-room-settings-audio p {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #909090;
  margin: 10px 0px;
}

.bar-room-settings-audio .input-range__label-container {
  display: none;
}

.bar-room-settings-audio .input-range {
  margin-top: 0;
}

.bar-room-settings-audio .form-group {
  margin-bottom: 20px;
}

.bar-room-settings-video h4 {
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0;
  padding-bottom: 16px;
}

.bar-room-settings-video .form-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #a0aabb;
  margin: 0;
  padding-bottom: 10px;
}

.bar-room-video-placeholder {
  border: 1px solid #4c505e;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  max-width: 448px;
  width: 100%;
}

.bar-room-video-placeholder.full-w {
  max-width: 100%;
  overflow: hidden;
}

.bar-room-video-placeholder.full-w video {
  -o-object-fit: cover;
  object-fit: cover;
}

.bar-room-video-wall {
  max-width: 100px;
  width: 100%;
}

.bar-room-video-placeholder h5 {
  font-weight: 400;
  font-size: 30px;
  line-height: 38px;
  color: #4c505e;
  margin: 0;
}

.bar-room-settings-video .bar-room-audio-grid-full {
  margin-bottom: 16px;
}

.bar-room-video-wall .slick-list {
  margin-top: -12px;
  border-radius: 6px;
}

.bar-room-video-wall .slick-prev {
  display: none !important;
}

.bar-room-video-wall .slick-next {
  top: auto;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background: url(../../assets//images//barroom/next-arrow.svg);
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: contain;
}

.bar-room-video-wall .slick-list:before {
  background: linear-gradient(
    180deg,
    rgba(55, 57, 64, 0) 0%,
    rgba(44, 47, 54, 0.5) 52.08%,
    #23262e 100%
  );
  content: "";
  width: 98%;
  height: 24px;
  position: absolute;
  bottom: 0px;
  z-index: 9;
  border-radius: 0px 0px 6px 6px;
  left: 1%;
}
.bar-room-video-wall .slick-next:before {
  display: none;
}

.bar-room-settings-grid .btn,
.bar-room-settings-grid .btn:focus,
.bar-room-settings-grid .btn:focus-visible,
.bar-room-settings-grid .btn-check:checked + .bar-room-settings-grid .btn,
.bar-room-settings-grid .btn.active,
.bar-room-settings-grid .btn.show,
.bar-room-settings-grid .btn:first-child:active,
:not(.bar-room-settings-grid .btn-check) + .bar-room-settings-grid .btn:active {
  background: #c7ad7a;
  border-radius: 6px;
  border-color: #c7ad7a;
  max-width: 170px;
  height: 50px;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  box-shadow: none;
  color: #ffffff;
  font-family: "DDCHardware" !important;
}

.bar-room-settings-grid .btn:hover {
  background: #af935d;
  border-color: #af935d;
}
.bar-room-settings-grid .btn:focus,
.bar-room-settings-grid .btn:focus-visible {
  box-shadow: 0 0 0 4px rgb(255 255 255 / 30%);
}
.bar-room-settings-grid .btn.stop-teting-btn,
.bar-room-settings-grid .btn.stop-teting-btn:focus {
  color: #c7ad7a;
  background: transparent;
}

.bar-room-settings-grid .btn.stop-teting-btn:hover {
  color: #af935d;
  border-color: #af935d;
}
.sound-grid {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.sound-grid .form-group {
  width: 48%;
}

.switch {
  position: relative;
  height: 1.5rem;
  width: 3rem;
  cursor: pointer;
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  border-radius: 9999px;
  background-color: rgb(69 72 81);
  transition: all 0.2s ease;
}

.switch:checked {
  background-color: #c7ad7a;
}

.switch::before {
  position: absolute;
  content: "";
  left: calc(1.5rem - 1.6rem);
  top: calc(1.55rem - 1.6rem);
  display: block;
  height: 1.6rem;
  width: 1.6rem;
  cursor: pointer;
  border: 1px solid rgba(100, 116, 139, 0.527);
  border-radius: 9999px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 3px 10px rgba(100, 116, 139, 0.327);
  transition: all 0.2s ease;
}

.switch:checked:before {
  transform: translateX(100%);
  border-color: #c7ad7a;
}

.bar-room-audio-grid-half {
  width: 100%;
}

.form-group canvas {
  width: 450px;
}

.version-status {
  position: absolute;
  top: 30px;
  right: 40px;
  font-size: 14px;
  color: #a5a5a5;
}

.bar-room-settings.spec-bar-room-settings .bar-room-settings-grid:before {
  display: none;
}

.bar-room-settings.spec-bar-room-settings .bar-room-settings-audio {
  width: 100%;
}

ul.setting-tab {
    background: transparent;
    margin-bottom: 30px;
    gap: 12px;
    border: 0;
    border-bottom: 1px solid #454851;
}

ul.setting-tab.nav-tabs .nav-link {
    background: transparent;
    color: #A0AABB;
    border: none;
    padding: 8px 12px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    position: relative;
    border-bottom: 2px solid transparent;
}

ul.setting-tab.nav-tabs .nav-link.active {
    color: #C7AD7A;
    border-bottom: 2px solid #C7AD7A;
}

.setting-tab-box  .create-profile-content{
	padding: 0;
}

.setting-tab-box .create-profile-content form:after{
	transform: translate(-50%, -50%);
    top: 50%;
	height: 498px;
}

.setting-tab-box  .profile-btns {
    padding-bottom: 20px;
    padding-top: 190px;
	flex-wrap: wrap;
	gap: 16px;
	
}

.setting-tab-box .profile-btns .btn{
	margin: 0;
	max-width: 170px;
	width: 100%;
}

.setting-tab-box .profile-btns span {
    display: flex;
    padding: 0px;
    justify-content: flex-end;
    width: 100%;
}