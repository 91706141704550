.host-broadcast-video{
	background: #000;
	opacity: 1;
}
.host-broadcast-video .modal-dialog {
    max-width: 100%;
    margin: 0 auto;
    /* width: 1378px; */
}
.host-broadcast-video .modal-dialog .modal-body {
    background: #0e0e10;
    padding: 0px 10px 18px 10px;
}
.host-broadcast-wrapper {
    left: 50%;
    position: absolute;
    top: 0;
    width: 1600px;
    height: 938px;
    transition: all 0.5s;
}
.host-broadcast-video .modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: auto;
}
.host-broadcast{
	width: 1500px;
	margin: 0 auto;
}
.host-broadcast-video h2, .intro-video-page h2 {
	height: 80px;
    background: #1C1D20;
    font-family: "Gilroy" !important;
    width: 318px;
    text-align: center;
    margin: 0 auto;
    border-radius: 0 0 48px 48px;
    color: #ECEFF3;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
}

.videobroadcast {
    background: #1C1D20;
    margin: 30px auto 0 auto;
    height: calc(100% - 240px);
    border: 1px solid #666d80;
    border-radius: 16px;
    position: relative;
    width: 100%;
    text-align: center;
    overflow: hidden;
}

.videobroadcast.oneplayer {
    height: calc(100% - 20px);
    margin: 20px auto 0 auto;
}
.videobroadcast.hostPlayer {
    height: calc(100% - 320px);
    margin: 20px auto 0 auto;
}
.videobroadcast.oneplayerHost {
    height: calc(100% - 100px);
    margin: 20px auto 0 auto;
}

.videobroadcast.oneplayer > div {
    height: 100% !important;
}


.videobroadcast video {
	width: 100%;
    border-radius: 16px;
    height: 100%; 
	object-fit: cover;
}
img.introperson {
    display: block;
    height: auto;
    margin: 0 auto;
    max-width: 469px;
	width: 100%;
}
.videobroadcast h3 {
    background: #DF1C41;
    width: 100px;
    height: 41px;
    padding: 8px 12px 8px 12px;
    border-radius: 8px;
    position: absolute;
    left: 16px;
    top: 16px;
    font-family: "Gilroy" !important;
    font-size: 20px;
    font-weight: 600;
    line-height: 24.5px;
    text-align: left;
    color: #fff;
    z-index: 9999;
    margin: 0;
}
.videobroadcast h3 img {
    float: left;
	margin-right: 9px;
}
.videobroadcast h4 {
    background: #292B31;
    position: absolute;
    color: #fff;
    height: 41px;
    bottom: 15px;
    left: 15px;
    padding: 8px 16px 8px 16px;
    font-family: "Gilroy" !important;
    font-size: 20px;
    font-weight: 600;
    line-height: 24.5px;
    text-align: left;
    margin: 0;
    border-radius: 8px;
    backdrop-filter: blur(15px);
}
.liveuser {
    margin-top: 25px;
	display: flex;
	flex-wrap: nowrap;
    justify-content: center;
}
.liveuser-list {
	list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
	margin: 0;
    justify-content: flex-start; /* Use flex-start to align items to the left */
	min-width: 192px;
}

.liveuser-list li {
    box-sizing: border-box;
    text-align: center;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc(12.5% - 8px);
    background: #1c1d20;
    border: 1px solid #666d80;
    border-radius: 8px;
    color: #fff;
    position: relative;
    height: 185px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.liveuser-list .main-player-camoff {
    background: transparent;
}
.liveuser-list li img {
    width: 112px;
    object-fit: cover;
    border-radius: 50%;
    height: 112px;
}
.liveuser-list li h5 {
    background: #292B31;
    position: absolute;
    color: #fff;
    bottom: 3px;
    left: 15px;
    padding: 8px 16px 8px 16px;
    font-family: "Gilroy" !important;
    border-radius: 8px;
    /*font-size: 15px;*/
	font-size: 0.94rem;
    font-weight:500;
    line-height: 24.5px;
    text-align: left;
}
.hiddenbox{
	display: none !important;
}
/*--------------------*/
.theater-block {
    max-width: 1680px;
    overflow-x: hidden;  
    overflow-x: auto;
	display: flex;
	flex-wrap: nowrap;
    justify-content: center;
}
  .theater-block::-webkit-scrollbar {
	height: 8px; 
  }
  
  .theater-block::-webkit-scrollbar-thumb {
	background: #888; 
	border-radius: 4px; 
  }
  
  .theater-block::-webkit-scrollbar-thumb:hover {
	background: #555; 
  }

  .liveuser-theater {
    /*max-width: 241px;*/
    margin-top: 16px;
    display: inline-block;
    margin-right: 20px;
	width: 386px;
    max-width: 386px;
}

.liveuser-theater-pic{
	background: #1c1d20;
    padding: 12px;
    border-radius: 16px;
	height: 166px;
}
.last-theater{
	margin-right: 0px !important;
}
/*.liveuser-list-theater {
	list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: flex-start; 
}*/
.liveuser-list-theater {
    list-style: none;
    padding: 0;
    flex-wrap: wrap;
    justify-content: flex-start;
    column-count: 4;
	margin: 0;
    padding: 0;
	display: block;
	text-align: center;
}

.theater-block .liveuser-theater:nth-child(5n){
	margin-right: 0;
  }
.liveuser-theater h3 {
    font-family: "Gilroy" !important;
    border-radius: 8px;
    font-size: 20px;
    font-weight: 600;
    line-height: 24.5px;
    text-align: left;
    color: #fff;
}
.liveuser-list-theater li {
    padding: 0px;
    text-align: center;
    background: #1c1d20;
    border-radius: 8px;
    color: #fff;
    position: relative;
    padding: 6px 0;
	width: 58px;
	display: inline-block;
	text-align: center;
}
.liveuser-list-theater li::before{
	content: "";
	background: url("../../assets/images/userliveactive.png");
	max-width: 15px;
    height: 14px;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
}
.liveuser-list-theater li img {
    width: 58px;
    border-radius: 50px;
    height: 58px;
	object-fit: cover;
}

.video-placeholder-wrapper {
    position: relative;
   
}

.placeholder-loading {
    position:absolute;
    top: 40%;
    left:50%;
    transform: translate(-50%, -50%);
}


.placeholder-loading span{
    font-size:20px;
	color: #fff;
	font-weight: 700;
}