.signup {
  background: #0E0E10;
}

.signup-content {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signup-form {
  max-width: 422px;
  width: 100%;
  display: flex;
  padding: 32px 50px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  border-radius: 20px;
  background: #1C1D20;
  box-shadow: 10px 10px 60px 0px #1b1d23;
}

.signup-form h3 {
  font-weight: 400;
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  color: #83bc9a;
  margin: 0;
  padding-bottom: 24px;
}

.signup-form h4 {
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  text-transform: uppercase;
  color: #a0aabb;
  margin: 0;
}

.lets-play-btn,
.lets-play-btn:hover {
  background: #c7ad7a;
  border-radius: 6px;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  text-decoration: none;
  height: 50px;
  font-family: "DDCHardware" !important;
  cursor: pointer;
}

.signup-form-icon img {
  width: 58px;
  height: 64px;
  -o-object-fit: contain;
  object-fit: contain;
}

.signup-form .form-label {
    font-weight: 400;
    font-size: 14px;
    color: #ECEFF3;
    margin-bottom: 6px;
    line-height: 155%;
}

.auth-icon {
    position: absolute;
    top: 42px;
    left: 12px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px #1c1d20 inset !important;
	-webkit-background-clip: text;
    -webkit-text-fill-color: #ECEFF3;
}

.signup-form .form-control,
.signup-form .form-control:focus {
    background: #1c1d20;
    color: #ECEFF3;
    font-weight: 400;
    font-size: 16px;
    line-height: 17px;
    height: 48px;
    padding: 8px 12px;
	padding-left: 44px;
    border-radius: 10px;
    border: 1px solid #666D80;
	box-shadow: none;
}

.signup-form .form-control:focus {
    background: #0E0E10;
}

.signup-form input.form-control[name="password"]{
  padding-right: 36px;
}
.signup-form input.form-control[name="verifyPassword"]{
  padding-right: 36px;
}

.signup-form .form-control::-webkit-input-placeholder {
  color: #818898;
}

.signup-form .form-control::-moz-placeholder {
  color: #818898;
}

.signup-form .form-control:-ms-input-placeholder {
  color: #818898;
}

.signup-form .form-control::-ms-input-placeholder {
  color: #818898;
}

.signup-form .form-control::placeholder {
  color: #818898;
}

.form-control-signup-padding .form-control,
.form-control-signup-padding .form-control:focus{
	padding-left: 12px;
}



.signup-form .form-group {
  margin-bottom: 16px;
  position: relative;
}
.signup-btn {
    padding-top: 8px;
}
.half-width {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.profile-form .half-width {
  gap: 20px;
}

.half-width .form-group {
  width: 47%;
}

.signup-btn .btn {
    max-width: 100%;
    background: #c7ad7a;
    border-radius: 30px;
    border-color: #c7ad7a;
    width: 100%;
    height: 50px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    transition: all 0.5s;
  }
  .signup-btn .btn:hover,
  .signup-btn .btn:focus,
  .signup-btn .btn-check:checked + .signup-btn .btn,
  .signup-btn .btn.active,
  .signup-btn .btn.show,
  .signup-btn .btn:first-child:active,
  :not(.signup-btn .btn-check) + .signup-btn .btn:active,
  .signup-btn .btn:focus-visible {
	background: #af935d;
	border-color: #af935d;
	outline: 0;
  }
  .signup-btn .btn:focus,
  .signup-btn .btn:focus-visible {
	box-shadow: none;
  }


  .signup-btn .btn:disabled {
	background: #373A41 !important;
	color: #666D80 !important;
	border-color: #373A41 !important;
  }




.mt-8{
	margin-top: 8px!important;
}

.signup-form p {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin: 0;
}

.signup-form p a {
  color: #c7ad7a;
  text-decoration: none;
}

.signup-form .form-group span {
  position: absolute;
  top: 42px;
  right: 12px;
  cursor: pointer;

}

.error-field {
  border: 1px solid #d93355 !important;
  box-shadow: none !important;
}

.error-text {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #d93355 !important;
  padding-top: 8px;
}

.signup .react-tel-input .form-control,
.signup .react-tel-input .form-control:focus {
  width: 100%;
  background: #454851;
  border: 1px solid #a0aabb;
  border-radius: 6px;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  height: 40px;
  padding: 18.5px 14px 18.5px 60px;
}

.signup .react-tel-input .selected-flag .arrow {
  border-top: 4px solid #fff;
}

.signup .react-tel-input .selected-flag .arrow.up {
  border-bottom: 4px solid #fff;
}

.signup .react-tel-input span {
  color: #6b6b6b;
  position: relative;
  top: auto;
  right: auto;
}

.signup .react-tel-input .selected-flag:focus:before,
.signup .react-tel-input .selected-flag.open:before {
  display: none;
}
.game-cp-wrapper h4 {
  margin: 0;
  font-weight: 400;
  font-size: 30px;
  line-height: 30px;
  color: #fff;
  padding-bottom: 2px;
}

.password-hint-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 8px;
}

.alrdy-mrg{
	padding-top: 8px;
}

.password-hint-wrapper p {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding: 0;
}

.password-hint-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
}

.password-hint-list span {
  display: flex;
  align-items: flex-start;
  gap: 4px;
  color: #a0aabb;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.password-hint-list span svg {
  width: 16px;
  height: 16px;
}

.signup-form-content-wrapper form {
  width: 100%;
}

.signup-form-content-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.linked-in-btn.btn {
    display: flex;
    height: 57px;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0;
    border: 0;
	gap: 12px;
    overflow: hidden;
    border-radius: 16px;
    transition: all 0.3s;
    background: #2867B2 !important;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.linked-in-btn.btn:hover {
  opacity: 0.9;
}

.linked-in-logo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.linked-in-btn.btn span {
    display: flex;
    height: 50px;
    padding: 10px 0px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 0px 4px 4px 0px;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
	font-family: "Inter", sans-serif;
}

.form-divider {
  position: relative;
  width: 100%;
}

.form-divider span,
.form-divider.login-divider {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  user-select: none;
  cursor: pointer;
}

.form-divider svg {
  width: 16px;
  height: 16px;
  transition: all 300ms;
}

.form-divider.show svg {
  rotate: 180deg;
}

.form-divider svg path {
  fill: #c7ad7a;
}

.form-divider::before,
.form-divider::after {
  position: absolute;
  content: "";
  width: 19.5%;
  height: 1px;
  background: #454851;
  top: 50%;
  transform: translateY(-50%);
}

.form-divider.login-divider::before,
.form-divider.login-divider::after {
  width: 45%;
}

.form-divider::before {
  left: 0;
}
.form-divider::after {
  right: 0;
}

.bg-btn.full-width-btn.btn {
  max-width: 100%;
  width: 100%;
  height: 50px;
  font-family: "DDCHardware" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.otp-form-wrapper {
  display: flex;
  width: 322px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.otp-form-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}

.otp-form-content h5 {
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  text-transform: uppercase;
  margin: 0;
}

.otp-form-content p {
  color: #a0aabb;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.otp-form-content p span {
  color: #c7ad7a;
}

.otp-form-wrapper form {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.otp-input-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.signup-form .otp-input-wrapper .form-group {
  margin: 0;
}

.signup-form .otp-input-wrapper .form-control {
  display: flex;
  width: 47px !important;
  height: 50px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
}

.signup-form form {
  width: 100%;
}

.signup-form form.signup-form-wrapper {
  width: 100%;
  height: 0;
  overflow: hidden;
  /*display: none;*/
  transition: all 300ms;
}


/*********** OTP **********/

.otp-confirmation h4 {
    color: #FFF;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    font-family: "Gilroy" !important;
    text-transform: none;
	padding-bottom: 10px;
}

.otp-confirmation p {
    color: #C1C7D0;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%;
}

.otp-base {
    display: grid;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 8px;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    margin-top: 24px;
	margin-bottom: 12px;
}

.otp-base input {
    display: flex;
    background: #292b31;
    color: #ECEFF3;
    font-weight: 400;
    font-size: 16px;
    line-height: 17px;
    height: 48px;
    border-radius: 10px;
    border: 1px solid #36394A;
    box-shadow: none;
	text-align: center;
	outline: none;
}

.otp-base + .error-text {
    padding-top: 3px;
    padding-bottom: 10px;
}

.signup-form  .resend-text {
	color:  #666D80;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 160%;
text-align: center;
margin-top: 24px !important ;
}

.signup-form  .resend-link {
	color:  #C7AD7A;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 160%;
text-align: center;
margin-top: 24px !important ;
text-decoration: underline;
display: block;
}

.otp-base input.focus {
	background: #1C1D20;
	border: 1px solid #262834;
}