.signup.linked-login {
  background-color: #0e0e10;
}

.signup-form.login-form {
    max-width: 524px;
    padding: 48px 48px;
    background: #1C1D20;
    box-shadow: 10px 10px 60px 0px #1b1d23;
}

.login-form-icon {
  position: relative;
  width: 160px;
  height: 160px;
  -o-object-fit: contain;
  object-fit: contain;
  border-radius: 50%;
}

.login-form-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover
}

.login-form-icon span {
  display: inline-flex;
  cursor: pointer;
  padding: 5px;
  background-color: #454851;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  right: 25px;
}

.header-container {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.heading-linkedIn {
    font-weight: 600;
    font-size: 32px;
    text-align: center;
    color: #ECEFF3;
    line-height: 120%;
	padding-bottom: 8px;
}

.sub-heading-linkedin {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #C1C7D0;
}

/************ Upload poupup ************/

.profile-upload-area p {
  margin: 0;
}

.profile-upload-popup .modal-body {
  padding: 0 !important;
}

.poker-popup.profile-upload-popup .modal-content {
  border-color: transparent;
  background: #31343e;
  border-radius: 6px;
}

.profile-upload-popup .intro-drag-video {
  border: 1px dashed #4c505e;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 909px;
  width: 100%;
  height: 367px;
  background: #31343E;
}

.profile-upload-popup .intro-drag-video:hover{
	border: 1px dashed #4c505e;
	background: #31343E;
}

.profile-upload-popup .intro-drag-video p {
    font-family: "Inter", sans-serif;
}

.profile-content-wrapper {
    padding: 0px 64px;
}

.profile-content-wrapper .signup-btn {
    padding-top: 16px;
}

.profile-upload-popup .modal-dialog {
  max-width: 909px;
}

.roundImage img{
  border-radius: 50%;
}
