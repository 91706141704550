/************************** Chat UI ****************************/

.tab-card-bottom {
  padding: 12px 16px;
  background: #31343e;
  border-radius: 0 0 20px 20px;
  width: 100%;
}

.Chat-input-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.Chat-input-wrapper .btn {
  width: 40px;
  height: 40px;
  padding: 8px;
}

.Chat-input-wrapper .btn:disabled,
.Chat-input-wrapper .btn.disabled {
  color: #fff;
  background: #c7ad7a;
  border-color: #c7ad7a;
}

.Chat-input-wrapper input.form-control {
  background: #454851;
  border-radius: 6px;
  border-color: #454851;
  padding: 12px 16px;
  height: 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 16px;
  color: #fff;
  caret-color: #c7ad7a;
  padding-right: 120px;
}

.Chat-input-wrapper input.form-control:focus {
  outline: 0;
  box-shadow: none;
}

.Chat-input-wrapper input.form-control::-webkit-input-placeholder {
  /* Edge */
  color: #a5a5a5;
}

.Chat-input-wrapper input.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #a5a5a5;
}

.Chat-input-wrapper input.form-control::-moz-placeholder {
  color: #a5a5a5;
}

.Chat-input-wrapper input.form-control::-ms-input-placeholder {
  color: #a5a5a5;
}

.Chat-input-wrapper input.form-control::placeholder {
  color: #a5a5a5;
}

.cam-emoji-wrapper {
  position: absolute;
  right: 54px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: flex-start;
  gap: 8px;
}

.emoji-input-btn {
  opacity: 0.5;
  cursor: pointer;
  transition: all 0.2s;
}

.emoji-input-btn:hover {
  opacity: 1;
}

.chat-content-wrapper {
  position: relative;
  width: 100%;
  height: calc(100% - 114px);
}

.chat-content-wrapper.waiting-content-chat-wrapper {
  height: calc(100% - 133px);
}

.chat-content-wrapper.participants-content-chat-wrapper {
  height: calc(100% - 85px);
}

.tab-chat-element {
  position: relative;
  flex-direction: row;
  /*align-items: baseline;*/
  justify-content: flex-start;
}

.chat-text-wrapper {
  width: calc(100% - 29px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
}

.chat-text-wrapper p.chat-message {
  color: #eceff3;
  margin: 0;
  word-break: break-word;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
}
.chat-text-wrapper p.chat-message a {
  color: #c7ad7a;
  font-weight: 500;
  text-decoration: none;
}

.chat-text-wrapper p.chat-message a:hover {
  color: #c7ad7a;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  text-underline-offset: 3px;
}

.chat-detail-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 12px;
}

.chat-avatar {
  width: 32px;
  height: 32px;
  -o-object-fit: cover;
  object-fit: cover;
  border: 1px solid #373940;
  border-radius: 100px;
  margin-right: -4px;
  cursor: pointer;
}

.chat-detail-wrapper p {
  max-width: 130px;
  display: block;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #c7ad7a;
  margin: 0;
  word-break: break-all;
}

.chat-detail-wrapper p.chat-user-name {
  transition: all 0.2s;
  cursor: pointer;
}

.chat-detail-wrapper p.chat-user-name:hover {
  text-decoration: underline;
}

.chat-detail-wrapper span {
  position: relative;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #c1c7d0;
}

.chat-detail-wrapper span::before {
  position: absolute;
  content: "";
  background: #909090;
  top: 50%;
  left: -6.5px;
  width: 2px;
  height: 2px;
  border-radius: 100%;
  margin-top: 0px;
}

.tab-chat-element .chat-time {
  width: 29px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #c1c7d0;
  text-align: right;
}

.chat-time-shadow {
  width: 29px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #c1c7d0;
  text-align: right;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.my-name {
  font-weight: 500;
  font-size: 14px;
  color: #c1c7d0 !important;
  word-break: break-all;
}

.chat-load-more-section {
  width: 100%;
  margin-top: 10px;
}
.chat-load-more {
  color: #fff;
  display: flex;
  justify-content: center;
}
.chat-load-more p {
  background: #1c1d20;
  color: #fff;
  display: flex;
  justify-content: center;
  padding: 5px 10px 5px 10px;
  cursor: pointer;
}
/*.chat-content-wrapper::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 8px;
  background: linear-gradient(180deg,
      rgba(49, 52, 62, 1) 0%,
      rgba(49, 52, 62, 0.2) 100%);
  z-index: 1;
}*/

.chat-react-btn.btn,
.chat-react-btn.btn:hover,
.chat-react-btn.btn:focus,
.chat-react-btn.btn:focus-visible,
:not(.chat-react-btn.btn-check) + .chat-react-btn.btn:active,
.chat-react-btn.btn:active {
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  outline: 0;
  box-shadow: none;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-react-btn.btn svg path {
  transition: all 0.2s;
}

.chat-react-btn.btn:hover svg path {
  fill: #fff;
}

.chat-react-btn.btn.active svg path {
  fill: #fff;
}

.chat-right-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0px;
  gap: 8px;
  height: 100%;
}

.main-player-game-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
}

.hand-reacted-emoji-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.hand-chat-elem-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
}

.cam-input-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 6px;
  box-shadow: 0px 10px 60px 0px #1b1d23;
  overflow: hidden;
}

.cam-input-wrapper span {
  display: flex;
  height: 40px;
  padding: 12px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  font-size: 14px;
  font-family: Gilroy;
  line-height: 16px;
  color: #fff;
  background: #454851;
  transition: all 0.2s;
  cursor: pointer;
}

.cam-input-wrapper span:hover {
  background: #4c505e;
}

.cam-input-modal.modal.show {
  top: 50%;
  height: 100vh;
  overflow: hidden;
}

.cam-input-modal .modal-body {
  display: flex;
  padding: 16px 20px 20px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.cam-shutter-btn-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.cam-shutter-btn-wrapper .shutter-btn,
.cam-shutter-btn-wrapper .cam-select-btn {
  cursor: pointer;
}

.selfie-cam img,
.selfie-cam video {
  min-width: 528px;
  width: 528px;
  height: 303px;
  -o-object-fit: contain;
  object-fit: contain;
  border-radius: 8px;
  border: 1px solid #4c505e;
}

.selfie-cam video {
  -o-object-fit: cover;
  object-fit: cover;
}

.cam-setting-btn-wrapper .drop-wrapper {
  max-width: 245px;
  width: 100%;
  right: 0;
  left: auto;
  bottom: 50px;
  top: auto;
}

.preview-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 20px;
  line-height: 26px;
  text-transform: uppercase;
  cursor: pointer;
}

.selfie-cam {
  width: 100%;
  height: 303px;
}

.cam-shutter-btn-wrapper .tab-card-bottom {
  padding: unset;
  background: unset;
  border-radius: unset;
}

.intro-drag-video span img {
  min-width: unset;
  width: unset;
  height: unset;
  -o-object-fit: unset;
  object-fit: unset;
  border-radius: unset;
  border: unset;
}

.selfie-cam .intro-drag-video {
  width: 100%;
}

.upload-chat-image-modal .selfie-cam {
  height: unset;
  width: 100%;
}

.upload-chat-image-modal .add-company-logo {
  margin-bottom: unset;
}

.upload-chat-image-modal .add-company-logo h6 {
  margin-bottom: 20px;
}

.chat-media-file {
  max-width: 150px;
  max-height: 100px;
  min-height: 60px;
  height: 100%;
  border-radius: 6px;
  -o-object-fit: contain;
  object-fit: contain;
  cursor: pointer;
}

.chat-input-emoji {
  position: absolute;
  right: 40px;
  top: -60px;
  z-index: 9;
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: all 0.2s;
}

.chat-input-cam {
  width: 116px;
  top: unset;
  bottom: 35px;
  right: 45%;
}

.chat-input-emoji.show {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.chat-reaction-emoji {
  position: absolute;
  right: 16px;
  bottom: 20px;
  z-index: 9;
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: all 0.2s;
}

.chat-reaction-emoji.show {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.reacted-emoji-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  cursor: pointer;
}

.reacted-emoji {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px;
  gap: 4px;
  background: #454851;
  border-radius: 6px;
}

.reacted-emoji p {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #fff;
  margin: 0;
}

.reacted-emoji span {
  font-size: 18px !important;
}

.reaction-info-wrapper {
  position: absolute;
  width: 120px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  bottom: 30px;
  left: -6px;
  background: #23262e;
  border-radius: 6px;
  padding: 8px;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
  z-index: 9;
}

.reacted-emoji:hover .reaction-info-wrapper {
  display: flex;
}

.reacted-emoji .reacting-username {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: center;
}

.reacted-emoji .reacting-username p {
  position: relative;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  word-break: break-word;
}

.reacted-emoji .reacting-username p::after {
  position: absolute;
  content: ",";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  right: -4px;
  bottom: 0;
}

.reacted-emoji .reacting-username p:last-child::after {
  display: none;
}

.reacted-emoji p.reaction-info-text {
  color: #909090;
  display: flex;
  align-items: center;
}

.cam-shutter-btn-wrapper .Chat-input-wrapper input.form-control {
  padding-right: 36px;
}

.modal-backdrop.chat-img-view-modal-backdrop.show {
  opacity: 0.8 !important;
}

.chat-img-view-modal .modal-dialog {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.chat-img-view img {
  max-width: 1800px;
  width: 100%;
  max-height: 80vh;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.chat-img-view-modal .modal-content {
  background: transparent;
  border-color: transparent;
  border-radius: 0;
  padding: 0;
  box-shadow: unset;
}

.chat-img-view-modal .modal-header,
.chat-img-view-modal .modal-body {
  padding: 0;
}

.chat-img-view-modal .modal-header {
  position: absolute;
  right: 10px;
  top: 20px;
  z-index: 2;
}

.chat-img-view-modal.modal {
  overflow: hidden;
}

.spec-eye-icon svg {
  width: 20px;
  height: 20px;
}

.chat-wrapper.waiting-chat-wrapper {
  border-radius: 8px;
  background: #292b31;
  width: 100%;
}

.tab-card-head.waiting-tab-card-head {
  background: #292b31;
}

.tab-card-element.waiting-tab-chat-element {
  gap: 12px;
  align-items: flex-end;
  flex-direction: row;
}

.chat-line-wrapper {
  background: #1c1d20;
  display: flex;
  border-radius: 16px 16px 16px 0;
  padding: 12px;
  width: 100%;
  height: 100%;
}

.chat-line-wrapper-owner {
  background: #373a41;
  display: flex;
  border-radius: 16px 16px 0px 16px;
  padding: 12px;
  width: 100%;
  height: 100%;
}

.Chat-input-wrapper.waiting-chat-input-wrapper input.form-control {
  height: 60px;
  border-radius: 24px;
  background: #1c1d20;
  border: none;
}

.tab-card-bottom.waiting-tab-card-bottom {
  background: #292b31;
}

.send-icon-wrapper {
  display: inline-flex;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px !important;
  padding: 5px;
  background-color: transparent !important;
  border: none !important;
  opacity: 0.5;
}

.send-icon-wrapper:hover {
  opacity: 1;
}
