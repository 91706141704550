.joinErrorModal h4 {
  color: #fff;
  width: 100%;
  text-align: center;
  font-weight: 410;
  font-size: 20px;
  line-height: 26px;
}
.joinErrorModal p {
  color: #fff;
  width: 100%;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.joinErrorModal .joinErrorModal-btn {
  width: 100%;
  max-width: 100%;
  height: 50px;
  max-width: 150px;
  border-radius: 32px;
  background-color: #DF1C41;
  border: none;
}

.joinErrorModal .joinErrorModal-btn:hover, .joinErrorModal .joinErrorModal-btn:focus {
	background-color: #8c132a !important;
}

.joinErrorModal .joinErrorModal-borderBtn {
  /*margin-top: 12px;*/
  max-width: 100%;
  width: 100%;
  height: 50px;
  color: #c7ad7a;
  max-width: 150px;
  border-radius: 32px;
}

.leaveRoomButtons {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 32px;
	gap: 1rem;
}

.leaveRoomButtons .outline-btn, .leaveRoomButtons .outline-btn:focus {
    border-color: #909090!important;
	color: #c7ad7a!important;
}

.leaveRoomButtons .btn, .leaveRoomButtons .btn:hover, .leaveRoomButtons .btn:focus{
	font-size: 16px!important;
	font-weight: 500 !important;
	box-shadow: none!important;
}
.bar-room-subtitle {
	display: flex;
	color: #FFFFFF;
	font-size: 18px;
	font-weight: 400;
	text-align: center;
	line-height: 27.9px;
	max-width: 387px;
	margin: 0 auto;
}
