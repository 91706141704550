.final-winner-main {
  display: flex;
  width: 1230px;
  height: 684px;
  padding: 20px;
  align-items: flex-start;
  gap: 20px;
}

.final-players-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  max-width: 315px;
  width: 100%;
  height: 100%;
}

.final-winner-player-wrapper {
  position: relative;
  max-width: 520px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 10px;
  background: linear-gradient(102deg, #eab20b 0%, #f9ce1f 100%, #f9ce1f 100%),
    #ddab34;
  box-shadow: 0px 0px 30px 0px rgba(186, 143, 42, 0.5);
  overflow: hidden;
}

.final-winner-player-wrapper::before {
  position: absolute;
  content: "";
  background-image: url(../../assets/images/winnerPlayerBg.png);
  background-position: center;
  background-repeat: repeat;
  background-size: contain;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.final-winner-player-box {
  position: relative;
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
  z-index: 1;
}

.player-on-winner-box {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
  align-self: stretch;
}

.player-on-winner-box::before {
  position: absolute;
  content: attr(data-value);
  font-family: "DDCHardware";
  color: #31343e;
  font-size: 194px;
  font-weight: 500;
  line-height: 151px;
  top: 50%;
  left: 4px;
  transform: translateY(-50%);
}

.final-players-wrapper.right .player-on-winner-box::before {
  left: auto;
  right: 4px;
}

.players-on-winner-wrapper {
  display: flex;
  max-width: 315px;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  border: 2px solid #373940;
  background: #23262e;
  border-radius: 4px;
}

.players-on-winner-wrapper.position-1 {
  border: 2px solid #ddab34;
  box-shadow: 0px 0px 30px 0px rgba(186, 143, 42, 0.5);
}

.player-on-winner-box img {
  display: flex;
  width: 80px;
  height: 80px;
  justify-content: center;
  align-items: center;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 100px;
  border: 2px solid #373940;
}

.player-on-winner-box p {
  max-width: 118px;
  display: block;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #fff;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  margin: 0;
  z-index: 9;
  word-break: break-all;
}

.final-winner-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}

.final-winner-profile img {
  width: 300px;
  height: 300px;
  border-radius: 500px;
  -o-object-fit: cover;
  object-fit: cover;
}

.final-winner-profile-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

.final-winner-profile-details > p {
  color: #fff;
  text-align: center;
  font-family: Gilroy;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  margin: 0;
}

.final-winner-profile-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.final-winner-profile-name > p {
  color: #fff;
  text-align: center;
  font-family: Gilroy;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  margin: 0;
}

.final-winner-profile-name > span {
  color: #fff;
  text-align: center;
  font-family: Gilroy;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}

.final-winner-cards-wrapper {
  display: flex;
  align-items: center;
  gap: 4.695px;
}

.final-winner-cards-wrapper img {
  width: 44.601px;
  height: 66.901px;
}

.final-winner-cards-wrapper
  .hand-history-card-wrapper.highlighted-winner-cards {
  display: flex;
  padding: 4.695px;
  align-items: flex-start;
  gap: 4.695px;
  background: #31343e;
}

.more-then-4 .player-on-winner-box::before {
  font-size: 110px;
}

.final-winner-profile .player-box-iframe {
  width: 300px;
  height: 300px;
  overflow: hidden;
  border-radius: 100%;
}

.final-winner-profile .player-box-iframe img,
.final-winner-profile .player-box-iframe video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.final-winner-profile .player-box-iframe span {
  display: none;
}

.go-to-rail.btn,
.go-to-rail.btn:hover,
.go-to-rail.btn-check:checked + .go-to-rail.btn,
.go-to-rail.btn.active,
.go-to-rail.btn.show,
.go-to-rail.btn:focus,
.go-to-rail.btn:focus-visible,
.go-to-rail.btn:focus:not(:focus-visible),
.go-to-rail.btn:first-child:active,
:not(.go-to-rail.btn-check) + .go-to-rail.btn:active {
  position: absolute;
  width: fit-content;
  padding: 8px 12px;
  top: 10px;
  right: 10px;
  font-family: "DDCHardware";
  background-color: #23262e !important;
  border-color: #23262e !important;
  color: #f9ce1f !important;
  z-index: 9 !important;
}
