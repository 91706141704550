.history-sidebar {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  max-width: 300px;
  width: 100%;
  background: #373940;
  box-shadow: 0px 10px 60px #1b1d23;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  z-index: 99;
  transition: all 0.2s;
}

.history-sidebar.hide {
  transform: translateX(-140%);
}

.sidebar-head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  gap: 10px;
  background: #373940;
  border-radius: 0px 0px 20px 20px;
  width: 100%;
}

.sidebar-head h4 {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0;
}

.sidebar-head span {
  cursor: pointer;
}

.sidebar-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 10px;
  gap: 10px;
  isolation: isolate;
  width: 100%;
}

.player-history-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 10px;
  gap: 8px;
  width: 100%;
  cursor: pointer;
  transition: all 0.2s;
}

.player-history-details:hover {
  background: #23262e;
  border-radius: 4px;
}
.history-profile-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  width: 100%;
}

.history-stats-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 100%;
}

.history-profile-section img {
  width: 32px;
  height: 32px;
  border: 1px solid #373940;
  border-radius: 100px;
}

.history-profile-section p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
  margin: 0;
  width: 122px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.history-stats-section .game-stats {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #fff;
}

.history-stats-section .game-stats.win {
  color: #57b661;
}

.history-stats-section .game-stats.lose {
  color: #d93355;
}

.history-table.table {
  color: #fff;
}

.history-table.table thead th {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  border: unset;
  padding-top: 15px;
  padding-bottom: 15px;
  letter-spacing: 0.03rem;
  text-align: center;
}

.history-table.table tbody td {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  border: unset;
  padding-top: 10px;
  padding-bottom: 10px;
  text-transform: capitalize;
  letter-spacing: 0.03rem;
  vertical-align: middle;
  text-align: center;
}

.history-table.table tbody td.win {
  color: #57b661;
}

.history-table.table tbody td.lose {
  color: #d93355;
}

.cards-image img {
  width: 27.74px;
  height: 44px;
}

.cards-image {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.waiting-list-btn .setting-btn-group {
  width: unset;
}

.waiting-list-btn .setting-btn-group .setting-btn {
  border-radius: 6px;
  height: 42px;
  background: #c7ad7a;
  transition: all 0.2s;
}

.waiting-list-btn .setting-btn-group .setting-btn:hover {
  background: #af935d;
}

.waiting-list-btn .setting-btn-group .drop-wrapper.show {
  transform: translateY(-10px);
}

.waiting-list-btn
  .setting-btn-group
  .drop-item
  .invite-user-action
  .form-check
  .form-check-label {
  max-width: 192px;
}

.waiting-btn {
  position: relative;
}

.waiting-notification {
  position: absolute;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #ff0000;
  border-radius: 50px;
  bottom: -10px;
  right: -10px;
  text-shadow: 1px 1px 2px #333;
}
