.emoji-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  gap: 8px;
  background: #454851;
  box-shadow: 0px 10px 60px #1b1d23;
  border-radius: 20px;
}

.emoji-wrapper span {
  background: transparent;
  border-radius: 8px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px !important;
  transition: all 0.2s;
  cursor: pointer;
}

.emoji-wrapper span:hover {
  background: #34373f;
}

.emoji-wrapper span.emoji-disabled {
  pointer-events: none;
}
