.multi-table-game-view {
  position: relative;
  height: 210px;
  width: 330px;
  overflow: hidden;
  background: #31343e;
  border: 1px solid #454851;
  border-radius: 16px;
}

.table-hover-switch {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  background: rgba(35, 38, 46, 0.7);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  border-radius: 16px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s;
  z-index: 9;
  cursor: pointer;
}

.table-hover-switch p {
  max-width: 129px;
  width: 100%;
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}

.multi-table-game-view:hover .table-hover-switch {
  opacity: 1;
  visibility: visible;
}

.multi-table-slider-main {
  width: 1600px;
}

.multi-table-slider-main .slick-arrow::before {
  display: none;
}

.multi-table-slider-main .slick-arrow::after {
  position: absolute;
  background: unset;
  content: "";
  font-family: unset;
  font-size: unset;
  line-height: unset;
  background-image: url("../../assets/images/Left.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 32px;
  height: 32px;
  top: 0;
  left: 0;
}

.multi-table-slider-main .slick-prev,
.multi-table-slider-main .slick-next {
  width: 32px;
  height: 32px;
}

.multi-table-slider-main .slick-prev {
  left: -32px;
}
.multi-table-slider-main .slick-next {
  right: -24px;
  transform: rotate(180deg) translateY(50%);
}

.multi-table-slider-main .slick-disabled::after {
  opacity: 0.5;
}

/*********************************** Multi table grid view ************************************/

.multi-table-game-view .logo {
  display: none;
}

.multi-table-game-view .player-game-sidebar {
  display: none;
}

.multi-table-game-view .player-game-grid {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  gap: 4px;
  padding: 12px;
  background: #31343e;
  /*border: 1px solid #373940;*/
  border-radius: 16px;
  flex-wrap: nowrap;
}

.multi-table-game-view .player-game-view-grid {
  max-width: 96px;
  gap: 2px;
}

.multi-table-game-view .player-length-1 .player-box {
  height: 185px;
  border-width: 2px;
}

.multi-table-game-view .player-length-1 .player-box-view {
  height: 145px;
}

.multi-table-game-view .player-dropdown {
  display: none;
}

.multi-table-game-view .company-logo-grid {
  display: none;
}

.multi-table-game-view .player-box-user span {
  display: none;
}

.multi-table-game-view .player-box-user {
  justify-content: center;
  padding: 2px;
}

.multi-table-game-view .player-length-1 .player-box-user h4 {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}

.multi-table-game-view .player-length-1 .player-box-action-grid,
.multi-table-game-view .player-length-2 .player-box-action-grid {
  padding: 4px 0 0 0;
  width: 100%;
  height: 22px;
  border: none;
}

.multi-table-game-view .player-game-right-view .player-box-action-grid .player-box-action {
  margin-right: -84px;
}

.multi-table-game-view .player-length-1 .player-action-view-check,
.multi-table-game-view .player-length-1 .player-action-view-fold,
.multi-table-game-view .player-length-1 .player-action-view-show {
  height: 30px;
  width: 50px;
}

.multi-table-game-view .player-action-view-fold span {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

.multi-table-game-view .player-game-dealing {
  max-width: 104px;
}

.multi-table-game-view .player-camera.view-1 .player-camera-box img {
  height: 88px !important;
}

.multi-table-game-view .dealer-name-wrapper {
  display: none;
}

.multi-table-game-view .player-camera-box {
  border-width: 2px;
}

.multi-table-game-view .tournament-game-view .automated-community-card-wrapper {
  border: none;
  min-height: 25.75px;
  padding: 0;
  gap: 2px;
  border-radius: 1.58585px;
}

.multi-table-game-view .player-bid-amount {
  flex-direction: column;
  padding-bottom: 0;
}

.multi-table-game-view .tournament-game-view .player-bid-box {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
}

.multi-table-game-view .player-bid-icon {
  display: none;
}

.multi-table-game-view .player-bid {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 30px;
  min-height: unset;
}

.multi-table-game-view .player-bid .player-bid-info span {
	font-size: 10px;
	line-height: 12px;
}

.multi-table-game-view .tournament-game-view .player-bid-info h4 {
  text-align: center;
  font-size: 12px;
  line-height: 14px;
}

.multi-table-game-view .player-game-action {
  display: none;
}

.multi-table-game-view .player-pot-count ul li {
  width: 100%;
}

.multi-table-game-view
  .tournament-game-view
  .automated-community-card-wrapper
  span
  img {
  width: 100%;
  height: 28px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 1.58585px;
}

.multi-table-game-view
  .player-game-right-view.player-length-1
  .player-box-card {
  transform: scale(31%) translate(82%, 0%);
}

.multi-table-game-view .layer-game-not-started-content.pause-animation,
.multi-table-game-view .layer-game-not-started-content {
  transform: scale(33%) translate(-100px, 116px);
  /*bottom: -10px;*/
}

.multi-table-game-view .player-length-1 .player-action-empty-field {
  min-height: 25px;
  width: 40px;
}

.multi-table-game-view .player-length-2 .player-box-card {
  transform: scale(31%) translate(-105%, 0%);
}

.multi-table-game-view .player-length-1 .player-box-card,
.multi-table-game-view .player-game-left-view.player-length-1 .player-box-card.strength-meter-show {
	transform: scale(31%) translate(-105%, 2%);
}

.multi-table-game-view .player-length-1 .player-box-card .show img {
  width: 57px;
  height: 90px
}

.multi-table-game-view .player-game-left-view.player-length-1
  .player-box-action {
  margin-left: -106px;
  width: unset;
}

.multi-table-game-view .player-length-1 .player-action-view,
.multi-table-game-view .player-length-2 .player-action-view {
  padding: 4px;
  height: 30px;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  width: 50px;
}

.multi-table-game-view .player-length-1 .player-action-view-text,
.multi-table-game-view .player-length-2 .player-action-view-text {
  font-weight: 400;
  font-size: 7px;
  line-height: 10px;
}

.multi-table-game-view .player-length-1 .player-action-view h4,
.multi-table-game-view .player-length-1 .player-action-view-bet span,
.multi-table-game-view .player-length-2 .player-action-view h4,
.multi-table-game-view .player-length-2 .player-action-view-bet span {
  font-size: 9px;
  line-height: 9px;
}

.multi-table-game-view .player-box-iframe.multi-table-avatar {
  display: block;
}

.multi-table-game-view .player-box-iframe {
  display: none;
}

.multi-table-game-view .main-player-camoff {
  flex-direction: column;
  gap: 10px;
}

.multi-table-game-view .main-player-camoff img {
  border: 3px solid #373940;
  border-radius: 100px;
  width: 60px;
  height: 60px;
}

.multi-table-game-view .main-player-camoff span {
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 70%;
  text-align: center;
  word-break: break-all;
}

.multi-table-game-view .player-action-winner span {
  font-size: 5px;
  line-height: 5px;
}

.multi-table-game-view .player-length-3 .player-action-winner span,
.multi-table-game-view .player-length-4 .player-action-winner span {
  font-size: 5px;
  line-height: 5px;
}

.multi-table-game-view .player-action-winner p {
  font-size: 5px;
  line-height: 11px;
}

.multi-table-game-view .player-length-3 .player-action-winner p {
  font-size: 5px;
  line-height: 8px;
}

.multi-table-game-view .player-length-4 .player-action-winner p {
  font-size: 5px;
  line-height: 6px;
}

.multi-table-game-view .player-action-winner {
  padding: 4px;
  width: 50px;
  height: 30px;
}

.multi-table-game-view .winner-player .player-box-action-grid {
  border: none;
}

.multi-table-game-view .player-length-2 .player-box-view {
  height: 55px;
}

.multi-table-game-view .player-length-2 .player-box {
  height: 91px;
}

.multi-table-game-view .player-box {
  border-width: 2px;
  border-radius: 2px;
}

.multi-table-game-view .player-length-2 .player-box-user h4 {
  font-size: 10px;
  line-height: 12px;
}

.multi-table-game-view .player-length-2 .main-player-camoff img {
  width: 20px;
  height: 20px;
  border-width: 2px;
}

.multi-table-game-view .player-length-2 .main-player-camoff {
  justify-content: flex-start;
  padding-top: 3px;
  gap: 2px;
}

.multi-table-game-view
  .player-game-right-view.player-length-2
  .player-box-card {
  transform: scale(31%) translate(78%, 0%);
}

.multi-table-game-view
  .player-game-right-view.player-length-2
  .player-box-action {
  margin-right: -76px;
}
.multi-table-game-view .player-length-2 .player-box-action {
  width: calc(100% - 42px);
}

.multi-table-game-view .player-length-2 .player-action-view-check,
.multi-table-game-view .player-length-2 .player-action-view-fold,
.multi-table-game-view .player-length-2 .player-action-view-show,
.multi-table-game-view .player-length-2 .player-action-empty-field,
.multi-table-game-view .player-length-2 .player-action-view,
.multi-table-game-view .player-length-2 .player-action-winner {
  height: 27px;
  padding: 4px;
  gap: 0px;
  min-height: unset;
}

.multi-table-game-view
  .player-game-left-view.player-length-2
  .player-box-action {
  margin-left: -76px;
}

.multi-table-game-view .dealer-name-wrapper.multi-table-count {
  display: flex;
  padding: 4px;
}

.multi-table-game-view .dealer-name-wrapper.multi-table-count span {
  font-size: 10px;
  line-height: 12px;
}

.multi-table-game-view .layout-header-grid {
  display: none;
}

.multi-table-game-view .player-length-3 .main-player-camoff img,
.multi-table-game-view .player-length-4 .main-player-camoff img,
.multi-table-game-view .player-action-view-bet span img {
  display: none;
}

.multi-table-game-view .player-length-3 .player-box {
  height: 60px;
  width: 95px;
}

.multi-table-game-view .player-length-3 .player-box-view,
.multi-table-game-view .player-length-4 .player-box-view {
  width: 46px;
}

.multi-table-game-view
  .player-length-3
  .player-box-action-grid.multi-table-game-view,
.multi-table-game-view
  .player-length-4
  .player-box-action-grid.multi-table-game-view {
  border: none;
}

.multi-table-game-view .player-length-3 .main-player-camoff {
  justify-content: flex-start;
  padding-top: 8px;
}

.multi-table-game-view .player-length-3 .player-box-user {
  padding: 2px;
  gap: 4.05px;
  height: 30px;
}

.multi-table-game-view .player-length-4 .player-box-user {
  padding: 2px;
  gap: 4.05px;
  height: 26px;
}

.multi-table-game-view .player-length-3 .player-box-user h4,
.multi-table-game-view .player-length-4 .player-box-user h4 {
  font-size: 10px;
  line-height: 10px;
}

.multi-table-game-view .player-length-3 .player-box-action {
  max-width: unset;
}

.multi-table-game-view .player-length-3 .player-box-action-grid,
.multi-table-game-view .player-length-4 .player-box-action-grid {
  width: 45px;
  border: 0;
  flex-wrap: nowrap;
  padding: 0;
}

.multi-table-game-view .player-length-3 .player-box-action-grid .player-box-action,
.multi-table-game-view .player-length-4 .player-box-action-grid .player-box-action {
  margin-right: 0;
}

.multi-table-game-view .player-length-3 .player-action-view,
.multi-table-game-view .player-length-3 .player-action-view-fold,
.multi-table-game-view .player-length-3 .player-action-view-show {
  min-height: unset;
  height: 28px;
  font-size: 14px;
  line-height: 18px;
}

.multi-table-game-view .player-length-4 .player-action-view,
.multi-table-game-view .player-length-4 .player-action-view-fold,
.multi-table-game-view .player-length-4 .player-action-view-show {
  min-height: unset;
  height: 26px;
  font-size: 14px;
  line-height: 18px;
}

.multi-table-game-view .player-length-4 .player-action-view {
  height: 24px;
}

.multi-table-game-view .player-length-3 .player-action-view-text,
.multi-table-game-view .player-length-4 .player-action-view-text {
  font-size: 5px;
  line-height: 8px;
  font-weight: 400;
}

.multi-table-game-view .player-length-3 .player-action-view h4,
.multi-table-game-view .player-length-4 .player-action-view h4 {
  font-size: 10px;
  line-height: 10px;
  margin: 0;
}

.multi-table-game-view .player-length-3 .player-action-view span,
.multi-table-game-view .player-length-4 .player-action-view span {
  display: none;
}

.multi-table-game-view .player-length-3 .player-action-empty-field,
.multi-table-game-view .player-length-3 .player-action-view-check,
.multi-table-game-view .player-length-4 .player-action-view-check,
.multi-table-game-view .player-length-4 .player-action-empty-field {
  min-height: 27px;
  height: 27px;
}

.multi-table-game-view .player-length-3 .player-box-card {
  transform: scale(29%) translate(-105%, 129%);
  margin: 0;
}

.multi-table-game-view .player-length-3 .player-box-grid,
.multi-table-game-view .player-length-4 .player-box-grid {
  flex-wrap: nowrap;
}

.multi-table-game-view .player-length-3 .player-box-user:before,
.multi-table-game-view .player-length-4 .player-box-user:before {
  background: #373940;
  -webkit-backdrop-filter: blur(1.2143px);
  backdrop-filter: blur(1.2143px);
}

.multi-table-game-view .player-length-3 .player-action-winner,
.multi-table-game-view .player-length-4 .player-action-winner {
  width: unset;
  height: 27px;
}

.multi-table-game-view .add-time {
  display: none;
}

.multi-table-game-view .player-length-4 .player-box {
  width: 95px;
  height: 45px;
}

.multi-table-game-view .player-length-4 .main-player-camoff {
  justify-content: flex-start;
  padding-top: 3px;
}

.multi-table-game-view .player-length-4 .player-box-action {
  max-width: unset;
  position: absolute;
  width: 42px;
  height: 25px;
  background: #373940;
}

.multi-table-game-view .player-length-4 .player-action-winner {
  width: 42px;
  height: 25px;
  min-height: 25px;
}

.multi-table-game-view .player-length-4 .player-box-card {
  transform: scale(23%) translate(-145%, 15%);
  margin: 0;
}

.multi-table-game-view
  .player-camera.view-1.dealer-camera-view
  .player-camera-box
  video,
.multi-table-game-view .player-camera.view-1 .player-camera-box video {
  height: 200px !important;
}

.multi-table-game-view .multi-table-dealer-view {
  display: block;
}

.multi-table-game-view .dealer-cam--view {
  display: none;
}

.multi-table-game-view .player-pot-count ul {
  flex-wrap: nowrap;
}

.multi-table-game-view .player-pot-count ul li span {
  font-size: 8px;
  line-height: normal;
}

.multi-table-game-view .player-pot-count ul li h4 {
  font-size: 14px;
  line-height: normal;
}

.multi-table-game-view .player-pot-count ul li:nth-child(3) {
  border: none;
}
.multi-table-game-view .player-pot-count ul li:nth-child(4),
.multi-table-game-view .player-pot-count ul li:nth-child(5),
.multi-table-game-view .player-pot-count ul li:nth-child(6),
.multi-table-game-view .player-pot-count ul li:nth-child(7),
.multi-table-game-view .player-pot-count ul li:nth-child(8),
.multi-table-game-view .player-pot-count ul li:nth-child(9) {
  display: none;
}

.multi-table-game-view
  .player-length-4
  .player-box-card
  img.cards-for-multiTable-view {
  display: block;
}

.multi-table-game-view .player-length-4 .player-box-card span img,
.multi-table-game-view .hand-strength-meter {
  display: none;
  width: 90.94px;
  height: 71.96px;
  object-position: top;
}

/*********************************** Multi table grid view ************************************/


.multi-table-game-view .userBatchKey {
    top: 6px;
    left: 6px;
}

.multi-table-game-view .userBatchKey img {
    width: 12px;
    height: 12px;
}

.multi-table-game-view .player-game-left-view .player-box-grid .userBatchKey {
	left: auto;
	top: 5px;
	right: 5px;
  }


  .multi-table-game-view .player-game-left-view .player-box-grid .userBatchKey img{
	width: 12px;
    height: 12px;
  }