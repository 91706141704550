.custom-select-popper {
	position: relative;
}

.popper {
	position: absolute;
	box-shadow: 10px 10px 60px 0px #1B1D23;
	bottom: calc(100% + 15px);
	left: 0;
	background-color: #0e0e10;
	border-radius: 1rem;
	border: 1px solid #666d80;
}

.popper-options {
	padding: 1rem;
}

.option {
	display: flex;
	justify-content: center;
	position: relative;
	flex-direction: column;
	padding: 0px 16px 0 50px;
	cursor: pointer;
	color: #eceff3;
	margin-top: 10px;
	margin-bottom: 10px;
	/* text-wrap: nowrap;
   height: 46px;   */
}

.option-empty {
	color: #eceff3;
}

.active-tick {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 0;
}

.sublabel {
	font-size: 12px;
	font-weight: 400;
}

.option.active {
	color: #c7ad7a;
}

.divider {
	height: 1px;
	background-color: #666d80;
}

.select-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 5px;
	border: 1px solid #909090;
	border-radius: 30px;
	background-color: transparent;
	padding: 10px 20px;
	color: #C7AD7A;
}

.select-btn-text {
	width: 113px;
	text-overflow: ellipsis;
	/* Needed to make it work */
	overflow: hidden;
	white-space: nowrap;
}

.select-btn-text.waiting-room {
	width: 95px;
}

.select-btn-icon {
	display: inline-flex;
}

.custom-option {
	padding-left: 1rem;
}

.custom-label-icon {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 0;
}