.viewers-waiting-main {
  position: relative;
}

.viewers-waiting-main .waiting-logo {
  min-width: unset;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.viewers-waiting-main .waiting-room-head {
  position: relative;
  display: flex;
  justify-content: center;
}

.viewers-waiting-main .waiting-text {
  background-image: url(../../assets/images/headShape.svg);
  background-repeat: no-repeat;
  width: 978px;
  height: 80px;
  background-size: 100%;
}

.waiting-room-content-body {
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding-top: 52px;
  align-items: center;
  justify-content: center;
}

.viewer-waiting-body {
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 20px;
  background: #31343e;
}

.waiting-audio-video-setting {
  position: relative;
  display: flex;
  height: 58px;
  padding: 12px 16px;
  align-items: center;
  gap: 8px;
}

.viewer-setting-wrapper {
  display: flex;
  max-width: 378px;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;
  border-radius: 20px;
  background: #31343e;
  padding: 20px;
}

.viewer-profile-wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.viewer-btns {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.change-password-wrapper {
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 8px;
}

.viewer-btns .btn,
.spectator-save-btn.bg-btn.btn {
  max-width: 100%;
  width: 100%;
  height: 50px;
  font-family: "DDCHardware";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.spectator-profile-form .preview-cover {
  position: relative;
}

.spectator-profile-edit-drop {
  position: absolute;
  right: 3px;
  top: 4px;
}

.spectator-profile-form .preview-cover .btn {
  display: flex;
  padding: 4px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 59px;
  background: rgba(35, 38, 46, 0.9);
  backdrop-filter: blur(15px);
}

.spectator-profile-form .preview-cover .dropdown-toggle::after {
  display: none;
}

.spectator-profile-form .preview-cover .no-bg-btn.btn svg path {
  fill: #fff;
}

/************************** Form **************************/

.spectator-profile-form {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.spectator-profile-form .profile-preview-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  flex: 1 0 0;
}

.change-password-btn.btn {
  font-family: "DDCHardware";
  max-width: 135px;
  display: flex;
  padding: 15px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.profile-edit-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
}

.profile-edit-input {
  position: relative;
  width: unset;
}

.profile-edit-input > .input-edit-icon {
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: all 300ms;
}

.profile-edit-input:hover > .input-edit-icon {
  opacity: 1;
  visibility: visible;
}

.profile-edit-full {
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.profile-edit-half {
  position: relative;
}

.input-edit-icon {
  position: absolute;
  top: 0px;
  right: -16px;
  cursor: pointer;
}

.spectator-profile-form .form-control,
.spectator-profile-form .form-control:focus {
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 4px;
  border-radius: 6px;
  border: 1px solid #a0aabb;
  background: #454851;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.spectator-profile-form textarea.form-control,
.spectator-profile-form textarea.form-control:focus {
  height: 88px;
}

.spectator-profile-form .form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.spectator-profile-form label.form-label {
  color: #fff;
  text-align: center;
  font-family: "DDCHardware";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
}

.preview-social ul li .profile-edit-full {
  width: calc(100% - 32px);
}

.spectator-save-btn.bg-btn.btn:disabled,
.spectator-save-btn.bg-btn.btn.disabled {
  background: #454851 !important;
  border-color: #454851 !important;
  color: #31343e !important;
  opacity: 1 !important;
}

.spectator-profile-form .dropdown-menu {
  --bs-dropdown-min-width: 123px;
  border-radius: 6px;
  background: #454851;
  box-shadow: 0px 10px 60px 0px #1b1d23;
  padding: 0;
  overflow: hidden;
}

.spectator-profile-form .dropdown-item,
.spectator-profile-upload {
  position: relative;
  display: flex;
  height: 40px;
  padding: 12px 11px;
  align-items: flex-start;
  gap: 10px;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.spectator-profile-form .spectator-profile-upload input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  opacity: 0;
}

.spectator-profile-form .dropdown-item:hover,
.spectator-profile-upload:hover {
  background: #4c505e;
}

.spectator-profile-form .dropdown-item:nth-child(2) {
  color: #d93355;
}

.spectator-profile-form .error-text {
  padding: 0;
  margin: 0;
}

.preview-social .profile-edit-full {
  flex-direction: column;
}

/************** Email verification *************/

.verified-icon {
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);
}

.email-verified.form-control,
.email-verified.form-control:focus {
  border-color: #83bc9a;
  box-shadow: unset;
  padding-right: 30px;
}

.verification-btn {
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 4px;
  color: #c7ad7a;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  cursor: pointer;
}

/***************************************************/

.waiting-room-content-body .drop-wrapper {
  width: 350px;
  top: auto;
  bottom: 66px;
  left: 50%;
  transform: translate(-50%, 0);
}

.waiting-room-content-body .drop-wrapper.show {
  transform: translate(-50%, 8px);
}

.change-password-modal.preview-modal .modal-content {
  border-radius: 20px;
  background: var(--secondary-dark, #31343e);
  box-shadow: 10px 10px 60px 0px #1b1d23;
}

.change-password-modal.preview-modal .modal-header {
  display: none;
}

.change-password-modal.preview-modal .modal-body {
  padding: unset;
}

.change-password-main.signup-form {
  box-shadow: none;
  background: transparent;
  gap: 24px;
}

.change-password-main.signup-form h4 {
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  text-transform: uppercase;
  align-self: stretch;
  text-align: left;
}

.waiting-tv-wrapper video {
  width: 894px;
  height: 556px;
  object-fit: cover;
}

.profile-btn-second-row {
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.profile-btn-second-row .btn {
  padding: unset;
}

.form-info-text {
  color: #a0aabb;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 16px;
}
