.intro-video-page {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.intro-video-form {
  background: #31343e;
  box-shadow: 10px 10px 60px #1b1d23;
  border-radius: 20px;
  max-width: 422px;
  width: 100%;
  margin: 0px auto;
  padding: 32px 50px;
  position: relative;
}

.intro-back-arrow {
  background: #c7ad7a;
  border-radius: 6px;
  width: 40px;
  height: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: -20px;
  cursor: pointer;
  transition: all 0.5s;
  padding: 0 !important;
}

.intro-back-arrow:hover {
  background: #af935d;
}
.intro-back-arrow:focus {
  background: #af935d;
  box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}

.intro-video-form h2 {
  font-weight: 400;
  font-size: 36px;
  line-height: 46px;
  padding-bottom: 32px;
  text-align: center;
  margin: 0;
  color: #ffffff;
}

.intro-video-form p {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  color: #909090;
  padding-bottom: 32px;
  margin: 0;
}

.intro-video-form .intro-outline-btn,
.intro-video-form .intro-outline-btn:hover,
.intro-video-form .intro-outline-btn:focus,
.intro-outline-btn.btn-check:checked + .intro-outline-btn.btn,
.intro-outline-btn.btn.active,
.intro-outline-btn.btn:focus-visible,
.intro-outline-btn.btn.show,
.intro-outline-btn.btn:first-child:active,
:not(.intro-outline-btn.btn-check) + .intro-outline-btn.btn:active {
  background: transparent;
  color: #c7ad7a;
  border-radius: 6px;
  border-color: #c7ad7a;
  width: 100%;
  height: 50px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  font-family: "DDCHardware" !important;
  box-shadow: none;
}

.intro-video-form .intro-outline-btn:hover {
  color: #af935d;
  border-color: #af935d;
}

.intro-video-form .intro-outline-btn:focus {
  color: #c7ad7a;
  border-color: #57626680;
  box-shadow: 0px 0px 0px 2px #57626680;
}
.intro-video-form .intro-btn,
.intro-video-form .intro-btn:hover,
.intro-video-form .intro-btn:focus {
  background: #c7ad7a;
  border-radius: 6px;
  border-color: #c7ad7a;
  width: 100%;
  height: 50px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  font-family: "DDCHardware" !important;
  box-shadow: none;
}

.intro-video-form .intro-btn:hover {
  background: #af935d;
  border-color: #af935d;
}

.intro-video-form .intro-btn:focus {
  background: #c7ad7a;
  border-color: #c7ad7a;
  box-shadow: 0px 0px 0px 2px #57626680;
}

/*********** Dropzone ***********/

.intro-drag-video {
  border: 1px dashed #4c505e;
  border-radius: 6px;
  padding: 6px 20px;
  margin-bottom: 0px;
  cursor: pointer;
  transition: all 0.5s;
  position: relative;
}

.intro-drag-video:hover {
  background: #454851;
  border: 1px dashed #a0aabb;
}

.intro-drag-video p {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  padding: 0;
  text-align: center;
}

.intro-drag-video p span {
  color: #c7ad7a;
  text-decoration: underline;
}

.intro-drag-video p span img {
  margin-right: 6px;
}

/*********** Uploading ***********/

.intro-uploading-video {
  background: #454851;
  border: 1px dashed #a0aabb;
  border-radius: 6px;
  padding: 42px 20px;
  margin-bottom: 32px;
  cursor: pointer;
  transition: all 0.5s;
  position: relative;
}

.intro-uploading-video p {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  padding: 0;
  text-align: center;
}

.intro-uploading-video p span {
  display: block;
  margin-bottom: 10px;
}

.intro-uploading-video .spinner-border {
  border: 2px solid #c7ad7a;
  border-right-color: transparent;
}

.intro-uploading-close {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 9;
  cursor: pointer;
}

/*********** Error ***********/

.intro-uploading-video-error {
  background: transparent;
  border: 1px dashed #d93355;
  border-radius: 6px;
  padding: 34px 20px;
  margin-bottom: 32px;
  cursor: pointer;
  transition: all 0.5s;
  position: relative;
}

.intro-uploading-error-action {
  position: absolute;
  right: 10px;
  top: 10px;
}

.intro-uploading-error-action span {
  display: inline-block;
  margin: 0px 5px;
}

.intro-uploading-error-content img {
  margin: 0px auto;
  display: block;
}

.intro-uploading-error-content p {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  padding-top: 10px;
  padding-bottom: 4px;
  text-align: center;
}

.intro-uploading-error-content span {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #a5a5a5;
  display: block;
}

/*********** Video List  ***********/

.intro-uploading-video-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
  flex-wrap: wrap;
}

.intro-uploading-list-content {
  display: flex;
  align-items: center;
  width: calc(100% - 60px);
}

.intro-uploading-list-info {
  padding-left: 12px;
}

.intro-uploading-list-info p {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  padding-bottom: 8px;
  max-width: 200px;
  overflow: hidden;
}

.intro-uploading-list-info span {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #909090;
  display: block;
}

.intro-uploading-list-delete span {
  color: #c7ad7a;
  text-decoration: underline;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: block;
  cursor: pointer;
}

.intro-uploading-list-content img {
  width: 48px;
  height: 48px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 12px;
}

/************************ Intro Popup ************************/

.intro-video-popup {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #23262ef0;
  transition: all 0.2s;
  transform: translateY(-200%);
}

.intro-video-popup.show{
	transform: translateY(0);
}

.intro-video-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.intro-video-popup video {
  width: 100%;
  height: 100%;
  background: #23262e;
}

.close-intro-video.btn {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  padding: 0px;
  justify-content: center;
  align-items: center;
  margin: 0;
  border-radius: 5px 5px 0 0;
  background: transparent;
  border-color: transparent;
  z-index: 9;
}

.close-intro-video.btn:hover,
.close-intro-video.btn-check:checked + .close-intro-video.btn,
.close-intro-video.btn.active,
.close-intro-video.btn.show,
.close-intro-video.btn:first-child:active,
:not(.close-intro-video.btn-check) + .close-intro-video.btn:active,
.close-intro-video.btn:focus,
.close-intro-video.btn:focus-visible,
.close-intro-video.btn:first-child:active:focus-visible {
  background: transparent;
  border-color: transparent;
  box-shadow: none;
  outline: none;
}
