.user-management-page .request-access-table table tr td:first-child,
.user-management-page .request-access-table table tr th:first-child,
.user-management-page .request-access-table table tr td:nth-child(2),
.user-management-page .request-access-table table tr th:nth-child(2) {
  width: 7%;
}

.user-management-page .request-access-table table tr td:nth-child(3),
.user-management-page .request-access-table table tr th:nth-child(3) {
  text-transform: capitalize;
  width: 10%;
}

.user-management-page .request-access-table table tr td:nth-child(4),
.user-management-page .request-access-table table tr th:nth-child(4) {
  width: 15%;
}

.user-management-page .request-access-table table tr td:nth-child(5),
.user-management-page .request-access-table table tr th:nth-child(5),
.user-management-page .request-access-table table tr td:nth-child(6),
.user-management-page .request-access-table table tr th:nth-child(6),
.user-management-page .request-access-table table tr td:nth-child(7),
.user-management-page .request-access-table table tr th:nth-child(7) {
  text-align: center;
  width: 5%;
}

.user-management-action-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.user-management-action-wrapper button.btn {
  display: flex;
  width: 36px;
  height: 36px;
  padding: 4px;
  justify-content: center;
  align-items: center;
}

.user-management-action-wrapper button.btn svg {
  width: 20px;
  height: 20px;
}

.user-management-action-wrapper button.btn svg path {
  fill: #c7ad7a !important;
}

.user-management-action-wrapper button.btn:nth-child(2) svg path {
  fill: #d93355 !important;
}

.user-management-page .searching-section form {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.admin-view-profile-page form:after {
  bottom: -25%;
  height: 345px;
}

.admin-view-profile-page .form-select {
  background: #454851;
  border: 1px solid #a0aabb;
  border-radius: 6px;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  height: 40px;
  padding: 5px 12px;
}

.admin-view-profile-page input.form-control[readonly],
.admin-view-profile-page input.form-control[readonly]:focus,
.admin-view-profile-page input.form-control[readonly]:focus-visible,
.admin-view-profile-page textarea.form-control[readonly],
.admin-view-profile-page textarea.form-control[readonly]:focus,
.admin-view-profile-page textarea.form-control[readonly]:focus-visible {
  background: transparent;
  border-color: transparent;
  box-shadow: none;
  outline: 0;
  pointer-events: none;
  padding: 0;
  resize: none;
}

.admin-view-profile-page textarea.form-control::-webkit-input-placeholder {
  color: #a0aabb;
}

.admin-view-profile-page textarea.form-control::-moz-placeholder {
  color: #a0aabb;
}

.admin-view-profile-page textarea.form-control:-ms-input-placeholder {
  color: #a0aabb;
}

.admin-view-profile-page textarea.form-control::-ms-input-placeholder {
  color: #a0aabb;
}

.admin-view-profile-page textarea.form-control::placeholder {
  color: #a0aabb;
}

.delete-popup-text.block-popup-text {
    text-align: center;
}

.block-popup-text .bar-room-leave-btn {
  flex-direction: row;
}
