@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

@font-face {
  font-family: "Gilroy";
  src: url(./assets/fonts/Gilroy/Gilroy-Regular.otf);
  font-weight: 400;
}

@font-face {
  font-family: "Gilroy";
  src: url(./assets/fonts/Gilroy/Gilroy-Medium.otf);
  font-weight: 500;
}

@font-face {
  font-family: "Gilroy";
  src: url(./assets/fonts/Gilroy/Gilroy-SemiBold.otf);
  font-weight: 600;
}

@font-face {
  font-family: "Gilroy";
  src: url(./assets/fonts/Gilroy/Gilroy-Bold.otf);
  font-weight: 700;
}

@font-face {
  font-family: "Gilroy";
  src: url(./assets/fonts/Gilroy/Gilroy-ExtraBold.otf);
  font-weight: 900;
}

@font-face {
  font-family: "DDCHardware";
  src: url(./assets/fonts/DDCHardware/DDCHardware-Condensed.ttf);
}

body {
  font-family: "Gilroy" !important;
  margin: 0;
  padding: 0;

  font-weight: 500;
  overflow-x: hidden;
  background: #23262e !important;
  /*background-color: #0E0E10 !important;*/
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "DDCHardware" !important;
}

::selection {
  background-color: #dac190;
  color: #23262e;
}

.game-view {
  background: #0e0e10 !important;
}

.container {
  /*max-width: 1230px !important;*/
  max-width: 1590px !important;
}

.layout-header .container {
  max-width: 1600px !important;
  position: relative;
}
.layout-header {
  position: relative;
}
.logo {
  left: 15px;
  position: absolute;
  top: 25px;
  cursor: pointer;
  z-index: 99;
}

.layout-header.dealer-home-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  left: 0px;
  cursor: pointer;
  z-index: 99;
  padding: 40px 0;
  width: 100%;
  background-color: #23262e;
}
.dealer-home-header .logo {
  left: unset;
  position: unset;
  top: unset;
  z-index: 99999;
}

.layout-header-grid {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.waiting-list-btn {
  position: fixed;
  top: 0;
  right: -132px;
  z-index: 99;
  display: flex;
  flex-direction: row;
  color: #fff;
  align-items: flex-start;
  gap: 12px;
}

.player-history-btn {
  right: unset;
  left: -62px;
}

.loader {
  position: fixed;
  background: #0e0e10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999;
}

.loader div {
  width: 80px !important;
  height: 80px !important;
}

/******************** Waiting List Modal ***********************/

.waiting-popup .modal-body {
  padding: 0 20px 16px 20px;
}

.waiting-user {
  min-height: 397px;
  display: flex;
  height: 397px;
  padding: 16px 0px 20px 0px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.waiting-list-btns {
  display: flex;
  padding: 16px 20px 20px 20px;
  align-items: flex-start;
  gap: 8px;
}
.waiting-list-Host-Tag {
  display: flex;
  padding: 5px;
  align-items: flex-start;
  gap: 8px;
  justify-content: center;
  background: #d93355;
}

.waiting-list-btns .add-all-btn {
  border: 1px solid #c7ad7a;
  border-radius: 6px;
  background: transparent;
  height: 50px;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #c7ad7a;
  font-family: "DDCHardware" !important;
}

.waiting-list-btns .add-all-btn.btn:hover,
.waiting-list-btns .add-all-btn.btn:active,
.waiting-list-btns .add-all-btn.btn:focus-visible,
.waiting-list-btns .add-all-btn.btn-check:checked + .add-all-btn.btn,
.waiting-list-btns .add-all-btn.btn.active,
.waiting-list-btns .add-all-btn.btn.show,
.waiting-list-btns .add-all-btn.btn:first-child:active,
:not(.add-all-btn.btn-check) + .add-all-btn.btn:active {
  background-color: transparent;
  border-color: #af935d;
  color: #af935d;
  box-shadow: none;
}

.waiting-list-btns .add-all-btn.btn:focus,
.waiting-list-btns .add-all-btn.btn:focus:not(:focus-visible),
.waiting-list-btns .add-all-btn.btn:focus-visible {
  border-color: transparent;
  box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}

.waiting-list-btns .add-btn {
  border: 1px solid #c7ad7a;
  border-radius: 6px;
  background: #c7ad7a;
  height: 50px;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  font-family: "DDCHardware" !important;
}

.waiting-list-btns .add-btn.btn:hover,
.waiting-list-btns .add-btn.btn:active,
.waiting-list-btns .add-btn.btn:focus-visible,
.waiting-list-btns .add-btn.btn-check:checked + .add-btn.btn,
.waiting-list-btns .add-btn.btn.active,
.waiting-list-btns .add-btn.btn.show,
.waiting-list-btns .add-btn.btn:first-child:active,
:not(.add-btn.btn-check) + .add-btn.btn:active {
  background-color: #af935d;
  border-color: #af935d;
  color: #fff;
  box-shadow: none;
}

.waiting-list-btns .add-btn.btn:focus,
.waiting-list-btns .add-btn.btn:focus:not(:focus-visible),
.waiting-list-btns .add-btn.btn:focus-visible {
  box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}

.waiting-list-btns .add-btn:disabled {
  color: #31343e;
  border-color: #454851;
  background: #454851;
  opacity: 1;
}

.waiting-list-btns .add-all-btn:disabled {
  color: #454851;
  border-color: #454851;
  background: transparent;
  opacity: 1;
}

.poker-popup span.waiting-list-count {
  font-weight: 410;
  font-size: 20px;
  line-height: 26px;
  text-transform: uppercase;
  color: #83bc9a;
  padding-left: 8px;
}

.bg-btn.btn {
  background: #c7ad7a;
  border-color: #c7ad7a;
  border-radius: 6px;
  padding: unset;
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s;
}

.bg-btn.btn:hover,
.bg-btn.btn-check:checked + .bg-btn.btn,
.bg-btn.btn.active,
.bg-btn.btn.show,
.bg-btn.btn:focus,
.bg-btn.btn:focus-visible,
.bg-btn.btn:focus:not(:focus-visible) {
  background: #af935d;
  border-color: #af935d;
}

.bg-btn.btn:first-child:active,
:not(.bg-btn.btn-check) + .bg-btn.btn:active {
  background: #c7ad7a;
  border-color: #c7ad7a;
  box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}

.bg-btn.btn:disabled,
.bg-btn.btn.disabled {
  background: #af935d !important;
  border-color: #af935d !important;
  color: #fff !important;
  opacity: 0.5 !important;
}

.bg-btn.table-view {
  width: 110px;
  font-weight: 400;
  font-family: "DDCHardware" !important;
}

.bg-btn.silent-disco-btn {
  width: 148px;
  font-weight: 400;
  font-family: "DDCHardware" !important;
}

.no-bg-btn.btn {
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  background: transparent;
  border: transparent;
  color: #c7ad7a;
}

.no-bg-btn.btn:hover,
.no-bg-btn.btn:active,
.no-bg-btn.btn:focus,
.no-bg-btn.btn:focus-visible,
.no-bg-btn.btn:first-child:active,
:not(.no-bg-btn.btn-check) + .no-bg-btn.btn:active {
  background: #373940;
  border: #373940;
  color: #c7ad7a;
}

.no-bg-btn.btn:disabled,
.no-bg-btn.btn.disabled {
  background: transparent !important;
  border: transparent !important;
  color: #c7ad7a !important;
  opacity: 0.5 !important;
}

.no-bg-btn.btn svg path {
  fill: #c7ad7a;
}

.no-bg-btn.btn:first-child svg path:last-child {
  fill: #d6002b;
}

.no-bg-btn.resend-otp.btn {
  color: #c7ad7a;
  text-decoration: underline;
  text-underline-offset: 4px;
}

.no-bg-btn.resend-otp.btn:disabled,
.no-bg-btn.resend-otp.btn.disabled {
  color: #4c505e !important;
  opacity: 1 !important;
}

.modal {
  --bs-modal-width: 570px !important;
}

.modal *,
.player-game-view-container *,
.dealer *,
.waiting-room-content *,
.game-view-banner-main *,
.game-stats-wrapper *,
.side-setting-wrapper *,
.table-drop-wrapper *,
.hand-info-wrapper *,
.player-game-view-wrapper-bottom * {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.community-card-btn.btn {
  font-family: "DDCHardware" !important;
  display: inline-flex;
  height: 40px;
  padding: 13px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  width: 200px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.delete-btn.btn {
  display: flex;
  padding: 15px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: #d93355;
  border-color: #d93355;
  color: #fff;
  font-family: "DDCHardware" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.delete-btn.btn:hover,
.delete-btn.btn-check:checked + .delete-btn.btn,
.delete-btn.btn.show,
.delete-btn.btn:first-child:active,
:not(.delete-btn.btn-check) + .delete-btn.btn:active,
.delete-btn.btn:focus,
.delete-btn.btn:focus-visible,
.delete-btn.btn.disabled,
.delete-btn.btn:disabled,
fieldset:disabled .delete-btn.btn {
  background: #d6002b !important;
  border-color: #d6002b !important;
  color: #fff;
  box-shadow: none;
}

.delete-btn.btn.active {
  box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}

.outline-btn.btn,
.outline-btn.btn:hover,
.outline-btn.btn:active,
.outline-btn.btn:focus,
.outline-btn.btn:focus-visible,
.outline-btn.btn:first-child:active,
:not(.outline-btn.btn-check) + .outline-btn.btn:active {
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 4px;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  background: transparent;
  border-color: #c7ad7a;
}

.outline-btn.btn:disabled,
.outline-btn.btn.disabled {
  background: transparent !important;
  border-color: #c7ad7a !important;
  color: #fff !important;
  opacity: 0.5 !important;
}

/*************** Not Supported ***************/

.page-supported {
  position: relative;
  padding: 60px 0;
  min-height: calc(100vh - 0px);
  width: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  background: #0e0e10;
}

.page-supported-content {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px;
  gap: 32px;
}

.page-supported-content svg {
  width: 246px;
  height: 246px;
}

.page-supported-wrapper {
  max-width: 540px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  text-align: center;
}

.page-supported-content h6 {
  font-weight: 410;
  font-size: 44px;
  line-height: 46px;
  color: #ffffff;
  text-transform: uppercase;
  margin: 0;
}

.page-supported-content p {
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #ffffff;
  margin: 0;
}

.page-supported-wrapper img {
  width: 80px;
  margin: 0px auto;
}

/************************************************************************************************************/

/*************** Media Query ******************/

/************************************************************************************************************/

@media screen and (min-width: 1770px) {
  .layout-header .container,
  .generate-game-page .container {
    max-width: 95% !important;
  }
}

@media screen and (max-width: 1180px) {
  
}

@media screen and (max-width: 767px) {
  .container {
    padding: 0px 15px;
  }
  .App {
    display: none;
  }

  .page-supported {
    display: flex;
  }

  .page-supported-content h6 {
    font-size: 32px;
    line-height: 34px;
  }
  .page-supported-content p {
    font-size: 20px;
    line-height: 24px;
  }
}
