.create-profile {
  max-width: 1328px;
  width: 100%;
  /*min-height: 100vh;*/
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px auto;
}

.create-profile-content {
  background: #31343e;
  border-radius: 20px;
  padding: 50px;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #a0aabb;
}

.create-profile-content form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
}

.create-profile-content form:after {
  content: "";
  width: 2px;
  height: 336px;
  background: #454851;
  display: block;
  position: absolute;
  left: 50%;
  transform: translate(50%, -50%);
  bottom: -43%;
}

.create-profile-left {
  width: calc(50% - 50px);
}

.create-profile-right {
  width: calc(50% - 50px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.create-profile-content h3 {
  font-weight: 400;
  font-size: 36px;
  line-height: 46px;
  color: #ffffff;
  margin: 0;
  padding-bottom: 40px;
}

.create-profile-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.profile-pic-upload {
  max-width: 134px;
  width: 100%;
}

.profile-form {
  width: calc(100% - 174px);
}

.profile-pic {
  width: 100%;
  height: 140px;
  margin-bottom: 20px;
}

.profile-pic img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 8px;
}

.upload-btn {
  position: relative;
  margin-bottom: 20px;
}

.upload-btn:before {
  content: "";
  background: #c7ad7a;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 6px;
}

.upload-btn:after {
  content: "new photo";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  font-family: "DDCHardware" !important;
}

.upload-btn .form-control,
.upload-btn .form-control:focus {
  height: 50px !important;
  position: relative;
  opacity: 0;
  z-index: 9;
}

.create-profile-content .form-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #a0aabb;
  margin-bottom: 9px;
}

.create-profile-content .form-control,
.create-profile-content .form-control:focus {
  background: #454851;
  border: 1px solid #a0aabb;
  border-radius: 6px;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  height: 40px;
  padding: 5px 12px;
}

.create-profile-content input.form-control::-webkit-input-placeholder {
  color: #a5a5a5;
}

.create-profile-content input.form-control::-moz-placeholder {
  color: #a5a5a5;
}

.create-profile-content input.form-control:-ms-input-placeholder {
  color: #a5a5a5;
}

.create-profile-content input.form-control::-ms-input-placeholder {
  color: #a5a5a5;
}

.create-profile-content input.form-control::placeholder {
  color: #a5a5a5;
}

.create-profile-content textarea.form-control,
.create-profile-content textarea.form-control:focus {
  height: 126px;
}

.create-profile-content .form-control::-webkit-input-placeholder {
  color: #ffffff;
}

.create-profile-content .form-control::-moz-placeholder {
  color: #ffffff;
}

.create-profile-content .form-control:-ms-input-placeholder {
  color: #ffffff;
}

.create-profile-content .form-control::-ms-input-placeholder {
  color: #ffffff;
}

.create-profile-content .form-control::placeholder {
  color: #ffffff;
}

.create-profile-content .form-group {
  position: relative;
}

.create-profile-right-wrapper {
  width: 100%;
}

.profile-btns {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: "DDCHardware" !important;
  padding-bottom: 35px;
}

.profile-btns span {
  display: inline-flex;
  padding: 15px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #c7ad7a;
  cursor: pointer;
}

.profile-bg-btn.btn,
.profile-bg-btn.btn:disabled {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  min-width: 170px;
  height: 50px;
  border-color: #c7ad7a;
  background: #c7ad7a;
  border-radius: 6px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  box-shadow: none;
  transition: all 0.2s;
}

.profile-outline-btn.btn {
  border: 1px solid #c7ad7a;
  background: transparent;
  color: #c7ad7a;
}

.profile-bg-btn.btn:hover,
.profile-bg-btn.btn-check:checked + .profile-bg-btn.btn,
.profile-bg-btn.btn.active,
.profile-bg-btn.btn.show,
.profile-bg-btn.btn:first-child:active,
:not(.profile-bg-btn.btn-check) + .profile-bg-btn.btn:active {
  background: #af935d;
  border-color: #af935d;
}

.profile-outline-btn.btn:hover,
.profile-outline-btn.btn-check:checked + .profile-outline-btn.btn,
.profile-outline-btn.btn.active,
.profile-outline-btn.btn.show,
.profile-outline-btn.btn:first-child:active,
:not(.profile-outline-btn.btn-check) + .profile-outline-btn.btn:active {
  background: transparent;
  border-color: #af935d;
  color: #af935d;
}

.profile-bg-btn:focus:not(:focus-visible),
.profile-bg-btn.btn:focus-visible {
  box-shadow: 0 0 0 4px rgb(87 98 102 / 50%);
  background: #c7ad7a;
  border-color: #c7ad7a;
}

.profile-outline-btn:focus:not(:focus-visible),
.profile-outline-btn.btn:focus-visible {
  box-shadow: 0 0 0 4px rgb(87 98 102 / 50%);
  background: transparent;
  border-color: #c7ad7a;
  color: #c7ad7a;
}

.profile-btns a,
.profile-btns a:hover {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  color: #c7ad7a;
  text-decoration: none;
}

.form-check .form-check-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #ffffff;
  margin: 0;
  cursor: pointer;
}

.create-profile-right .form-check {
  margin: 0;
  display: flex;
  align-items: center;
  padding: 0;
  gap: 10px;
}
.create-profile-right input.form-check-input {
  background: #454851;
  border: 1px solid #a0aabb;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  margin: 0;
  cursor: pointer;
}
.create-profile-right input.form-check-input:focus,
.create-profile-right input.form-check-input:focus-visible {
  box-shadow: 0 0 0 3px rgb(160 170 187 / 30%);
}

.profile-change-password a,
.profile-change-password a:hover {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #c7ad7a;
  font-family: "DDCHardware" !important;
  text-decoration: none;
  padding-top: 20px;
}

.delete-btn .btn,
.delete-btn .btn:first-child:active,
.delete-btn .btn:first-child:active:focus-visible,
.delete-btn .btn:focus,
.delete-btn .btn:disabled {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  border-color: #c7ad7a;
  background: transparent;
  color: #c7ad7b;
  border-radius: 6px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  width: 100%;
  font-family: "DDCHardware" !important;
  box-shadow: none;
}

.delete-btn .btn:hover {
  border-color: #bd9c5b;
  background: transparent;
  color: #bd9c5b;
}

.preview-modal .modal-content {
  border-color: #31343e;
  border-radius: 6px;
  background: #31343e;
}

.preview-modal .modal-header {
  border: none;
  padding: 0;
  position: absolute;
  right: -10px;
  width: 25px;
  height: 25px;
  background: #fff;
  border-radius: 100%;
  top: -10px;
  justify-content: center;
}

.preview-modal .modal-dialog {
  max-width: 378px;
  margin: 1.75rem auto;
}

.preview-modal .modal-header .btn-close {
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 9;
  width: 12px;
}

.preview-modal .modal-header .btn-close:focus {
  box-shadow: none;
}

.preview-modal .modal-body {
  width: 100%;
  padding: 20px;
  color: #fff;
  display: inline-flex;
  flex-direction: column;
  gap: 32px;
}

.preview-user {
  display: flex;
  align-items: center;
  gap: 20px;
}

.preview-cover {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.preview-info {
  width: calc(100% - 120px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.preview-cover img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  -o-object-fit: cover;
  object-fit: cover;
}

.profile-preview-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.preview-about {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.preview-social {
  width: 100%;
}

.preview-social ul {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-self: stretch;
}

.preview-social ul li {
  display: flex;
  align-items: center;
  gap: 8px;
}

.preview-social ul li a {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  color: #fff;
  text-decoration: none;
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.preview-social ul li a img {
  margin-right: 10px;
}

.preview-about h4 {
  display: flex;
  align-items: center;
  margin: 0;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.preview-about p {
  margin: 0;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding-right: 34px;
  word-break: break-word;
}

.preview-about p.expand {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  min-height: 40px;
  word-break: break-word;
}

.preview-about p.about-me-full {
  height: 80px;
  overflow: auto;
  scroll-behavior: smooth;
}

.preview-about span {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #c7ad7a;
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: pointer;
}

.preview-info h4 {
  width: 100%;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  text-transform: uppercase;
  word-break: break-all;
}

.preview-info span {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #c7ad7a;
  display: block;
  padding-bottom: 4px;
}

.preview-info p {
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  color: #a0aabb;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.preview-join ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.preview-join ul li {
  padding-bottom: 14px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  display: flex;
  align-items: center;

  /* Grayscale / White */
  color: #ffffff;
}

.preview-join ul li img {
  width: 20px;
  height: 20px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 100%;
  margin-left: 10px;
  margin-right: 10px;
}

.preview-join ul li span {
  font-family: "DDCHardware" !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}

.preview-social ul li:last-child,
.preview-join ul li:last-child {
  padding: 0;
}

.preview-about p a {
  color: #c7ad7a;
  text-decoration: none;
}

.create-profile.create-profile-not-in-game {
  min-height: 100vh;
}

.modal-backdrop.profile-preview-modal-back.show {
  opacity: 0.9 !important;
  background: #23262e;
  z-index: 9999;
}

.preview-modal.modal.show {
  z-index: 9999;
}

.create-profile-right-section {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

/****************** Profile view ********************/

.my-profile-view-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 510px;
  align-self: stretch;
}

.profile-head-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profile-head-wrapper h2 {
  width: 100%;
  color: #fff;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 46px;
  margin: 0;
}

.profile-head-wrapper .profile-btns {
  padding: 0;
}

.profile-body-content-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
}

.profile-body-content-wrapper::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  top: 0;
  left: 50%;
  right: 0;
  bottom: 0;
  transform: translateX(-50%);
  background: #454851;
}

.my-profile-left-content {
  max-width: 564px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.my-profile-right-content {
  max-width: 564px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.my-profile-about-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.my-profile-about-content h5 {
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  text-transform: uppercase;
  margin: 0;
}

.my-profile-about-content p {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  max-height: 234px;
  overflow: auto;
}

.my-profile-status-wrapper {
  display: inline-flex;
  align-items: center;
  gap: 8px;
}

.my-profile-status-wrapper span {
  color: #a0aabb;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.account-delete-modal .modal-body {
  padding: 36px;
}
