.page-404 {
  position: relative;
  padding: 60px 0;
  min-height: calc(100vh - 0px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-404-content {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px;
  gap: 32px;
}

.page-404-content svg {
  width: 246px;
  height: 246px;
}

.page-404-wrapper {
  max-width: 540px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.page-404-content h6 {
  font-weight: 410;
  font-size: 44px;
  line-height: 46px;
  color: #ffffff;
  text-transform: uppercase;
  margin: 0;
}

.page-404-content p {
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #ffffff;
  margin: 0;
  padding-bottom: 8px;
}

.bg-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 36px;
  gap: 10px;
  max-width: 242px;
  width: 100%;
  height: 50px;
  background: #c7ad7a;
  border-radius: 6px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  text-decoration: none;
  font-family: "DDCHardware";
  transition: all 0.2s;
  cursor: pointer;
}

.bg-btn:hover {
  background: #af935d;
  color: #fff;
}


.support-link {
  color: #c7ad7a;
  text-decoration: none;
}

.support-link:hover {
  color: #af935d;
}

.outline-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 36px;
  gap: 10px;
  max-width: 242px;
  width: 100%;
  height: 50px;
  border: 1px solid #c7ad7a;
  background: transparent;
  border-radius: 6px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #c7ad7a;
  text-decoration: none;
  font-family: "DDCHardware";
  transition: all 0.2s;
  cursor: pointer;
}

.outline-btn:hover {
  background: transparent;
  border-color: #af935d;
  color: #af935d;
}

.outline-btn:focus {
  box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}

.page-404-btn {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;
}
