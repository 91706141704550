.main-game-music {
  max-width: 448px;
  width: 448px;
  display: flex;
  height: 58px;
  padding: 12px 16px;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 20px;
  background: #31343e;
}

.music-track-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
  color: #fff;
}

.music-track-content {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  justify-content: space-between;
}

.music-track-content p {
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  align-self: stretch;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  margin: 0;
}

.music-track-content > span {
  cursor: pointer;
  position: relative;
}

.music-tooltip-wrapper {
  width: 308px;
  position: absolute;
  right: -27px;
  top: 20px;
  padding: 12px;
  border-radius: 10px;
  background: #4c505e;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  transition: all 300ms;
}

.music-track-content > span:hover .music-tooltip-wrapper {
  opacity: 1;
  visibility: visible;
}

.music-tooltip-wrapper::before {
  content: "";
  position: absolute;
  top: -5px;
  right: 24px;
  background: url(../../assets/images/toolTipArrowIcon.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 20px;
  height: 8px;
}

.music-track-wrapper span {
  color: #a0aabb;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  align-self: stretch;
}

.music-track-wrapper span.music-track-album {
  font-size: 10px;
}

.music-player-btn.btn {
  height: 40px;
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 16px;
  background: #454851;
  border-color: #454851;
}

.music-player-btn.btn:hover {
  background: #4c505e;
  border-color: #4c505e;
}

.music-player-btn.btn-check:checked + .music-player-btn.btn,
.music-player-btn.btn.active,
.music-player-btn.btn.show,
.music-player-btn.btn:first-child:active,
:not(.music-player-btn.btn-check) + .music-player-btn.btn:active,
.music-player-btn.btn:focus-visible {
  background: #454851;
  border-color: #454851;
  box-shadow: none;
  outline: 0;
}

.music-player-btn.btn.disabled,
.music-player-btn.btn:disabled {
  color: #454851 !important;
  border-color: #454851 !important;
  background: transparent !important;
  opacity: 1 !important;
}

.music-volume-wrapper {
  position: relative;
  display: flex;
}

.music-volume-wrapper::after {
  position: absolute;
  content: "";
  width: 1px;
  height: 16px;
  background: #373940;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.music-volume-wrapper .btn:first-child {
  border-radius: 16px 0px 0px 16px;
}

.music-volume-wrapper .btn:last-child {
  border-radius: 0px 16px 16px 0px;
}

.music-track-album {
  display: flex;
  gap: 2px;
}

.music-track-artist {
  display: flex;
  gap: 2px;
  width: 100%;
}

.music-track-album a,
.music-track-album a:hover,
.music-tooltip-wrapper a,
.music-tooltip-wrapper a:hover {
  color: #c7ad7a;
}

.music-track-artist > span:first-child {
  width: calc(100% - 95px);
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  overflow: hidden;
  word-break: break-all;
}

.game-view-music .skip-btn.btn {
  display: none;
}

.game-view-music .music-volume-wrapper .btn:first-child {
  border-radius: 16px;
}

.game-view-music .music-play-btn.btn {
  display: none;
}

/************************* Volume slider *****************************/
.music-volume-control-wrapper {
  position: relative;
  width: 50px;
}

.music-volume-range-main .volume-range-main {
  background: #23262e;
  margin: 0;
}

.music-volume-range-main {
  position: absolute;
  top: -44px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  padding: 5px 12px;
  justify-content: space-between;
  width: 130px;
  align-items: center;
  gap: 4px;
  border-radius: 10px;
  background: #4c505e;
  opacity: 0;
  visibility: hidden;
  transition: all 300ms;
}

.music-volume-control-wrapper:hover .music-volume-range-main {
  opacity: 1;
  visibility: visible;
}

.music-volume-range-main::before {
  position: absolute;
  content: "";
  width: 20px;
  height: 8px;
  background: url(../../assets/images/handArrow.svg);
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
  bottom: -7px;
  left: 50%;
  transform: translateX(-50%);
}

.music-volume-range-main .volume-range-main #slider {
  padding-bottom: 8px;
  transform: translateY(-9px);
}

.music-volume-range-main .volume-range-main #selector,
.music-volume-range-main .volume-range-main .volume-range-selectBtn {
  width: 10px;
  height: 10px;
}

.game-view-music > .bg-btn.silent-disco-btn.btn {
  width: 100%;
  max-width: 100%;
  border-radius: 16px;
  height: 40px;
  letter-spacing: 1px;
}

.music-volume-range-main .btn:first-of-type svg path:nth-child(3) {
  fill: transparent;
}

.music-volume-range-main .btn {
  width: 24px;
  height: 24px;
  padding: 0;
  background: transparent;
  border-color: transparent;
}

/****************************************************************/

.skeleton-placeholder {
  background: #454851;
  border-radius: 2px;
}


