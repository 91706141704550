.waiting-room-content-container .container {
  max-width: 1630px !important;
  width: 100%;
  position: relative;
}

.waiting-room-content {
  position: absolute;
  top: 0;
  left: 50%;
  transition: all 0.5s;
  width: 1600px;
  height: 922px;
  transform: translate(-50%, 4%);
}

.waiting-room-head {
  display: flex;
  /*align-items: center;*/
  justify-content: flex-end;
}

.waiting-logo {
  min-width: 378px;
  height: 80px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.waiting-text {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  background-color: #1C1D20;
  border-radius: 0 0 48px 48px;
  height: 80px;
  width: 605px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.viewer-host-wrapper {
  display: flex;
  max-width: 404px;
  width: 100%;
  flex-direction: column;
  align-self: stretch;
  border-radius: 8px;
  gap: 20px;
}
.wr-camera-content .viewer-setting-wrapper {
  border-radius: 8px;
}

.waiting-text h4 {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ECEFF3;
    margin: 0;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    font-family: "Gilroy" !important;
}

.waiting-btn {
  display: flex;
  align-items: center;
  gap: 16px;
  padding-top: 20px;
}

.waiting-con-btn {
  display: flex;
  align-items: center;
  gap: 16px;
}

.waiting-mic {
  background: #c7ad7a;
  border-radius: 6px;
  padding: 8px;
  cursor: pointer;
}

.waiting-mic:hover {
  background: #af935d;
}

.waiting-camera {
  background: #c7ad7a;
  border-radius: 6px;
  padding: 8px;
  cursor: pointer;
}

.waiting-camera:hover {
  background: #af935d;
}

.waiting-leave-btn.btn {
  background: #d93355;
  border-radius: 6px;
  width: 170px;
  height: 50px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  text-transform: uppercase;
  border: none;
  font-family: "DDCHardware" !important;
}

.waiting-leave-btn.btn:hover,
.waiting-leave-btn.btn-check:checked + .waiting-leave-btn.btn,
.waiting-leave-btn.btn.active,
.waiting-leave-btn.btn.show,
.waiting-leave-btn.btn:first-child:active,
:not(.waiting-leave-btn.btn-check) + .waiting-leave-btn.btn:active {
  background: #d6002b;
  border: none;
  outline: 0;
}

.waiting-leave-btn.btn:focus,
.waiting-leave-btn.btn:focus:not(:focus-visible),
.waiting-leave-btn.btn:focus-visible {
  background: #d93355;
  box-shadow: 0 0 0 0.25rem rgb(87 98 102 / 50%);
}

.wr-camera-grid {
  position: relative;
  padding-top: 24px;
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.wr-camera-box {
  display: flex;
  width: 100%;
  height: 253px;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 8px;
  background: #1c1d20;
}

.wr-camera-name {
  max-width: 364px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: absolute;
  bottom: 16px;
  left: 12px;
  display: flex;
  padding: 6px 24px;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  border-radius: 4px;
  background: rgba(35, 38, 46, 0.7);
  backdrop-filter: blur(15px);
}

.wr-host-tag {
  max-width: 364px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: absolute;
  bottom: 16px;
  right: 12px;
  display: flex;
  padding: 6px 24px;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  border-radius: 4px;
  background: rgba(35, 38, 46, 0.7);
}
.wr-host-tag p {
  display: block;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #ffffff;
  margin: 0;
  word-break: break-all;
}

.wr-camera-name p {
  display: block;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  color: #ffffff;
  margin: 0;
  word-break: break-all;
  font-family: "Inter", sans-serif;
}

.wr-main-camera {
  width: 100%;
  height: 100%;
  border: 5px solid #31343e;
  border-radius: 6px;
}

.wr-camera-gridbox {
  display: grid;
  width: 100%;
  height: 783px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 12px;
  grid-template-areas:
    "content-1 content-8 content-7"
    "content-2 content-9 content-6"
    "content-3 content-4 content-5";
}

.content-1 {
  grid-area: content-1;
}

.content-2 {
  grid-area: content-2;
}

.content-3 {
  grid-area: content-3;
}

.content-4 {
  grid-area: content-4;
}

.content-5 {
  grid-area: content-5;
}

.content-6 {
  grid-area: content-6;
}

.content-7 {
  grid-area: content-7;
}

.content-8 {
  grid-area: content-8;
}

.content-9 {
  grid-area: content-9;
}

.waiting-center-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.waiting-center-box p {
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
  font-family: "Inter", sans-serif;
}

.wr-camera-box:nth-child(3) .profile-viewer,
.wr-camera-box:nth-child(4) .profile-viewer,
.wr-camera-box:nth-child(5) .profile-viewer,
.wr-camera-box:nth-child(8) .profile-viewer {
  transform: translateY(-44%);
}

.camera-active {
  border: 2px solid #C7AD7A;
}

.wr-camera-box .noPlayerImg {
  position: absolute;
  border-radius: 8px;
  display: grid;
  place-content: center;
  z-index: 10;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.wr-camera-box video {
  width: 100%;
  height: 100%;
  -o-object-fit: cover !important;
  object-fit: cover !important;
  background-size: cover;
  border-radius: 8px !important;
}

.wr-camera-box .cameraOff {
  border: unset;
  width: 80px;
  height: 80px;
  border-radius: 50% !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -o-object-fit: cover;
  object-fit: cover;
}

.wr-camera-box video::-webkit-media-controls {
  display: none;
}

.waiting-camera-close {
  background-color: #fff;
  border-radius: 6px;
  padding: 8px;
  cursor: pointer;
}

.waiting-mic-close {
  background-color: #fff;
  border-radius: 6px;
  padding: 8px;
  cursor: pointer;
}

/* Could Use these as well for Individual Controls */

video::-webkit-media-text-track-container {
  display: none;
}

video::-webkit-media-text-track-background {
  display: none;
}

video::-webkit-media-text-track-display {
  display: none;
}

.guestImgContainer img,
.guestImgContainer video {
  width: 100%;
  height: 509px;
  -o-object-fit: cover;
  object-fit: cover;
}

.waiting-btn .side-setting-wrapper .btn-primary {
    margin-left: 13px;
}

.waiting-btn .side-setting-wrapper {
  display: flex;
  width: 404px;
  height: 68px;
  padding: 12px 16px;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: space-evenly;
  background-color: #1C1D20;
}
.draggable-host-bottom {
  margin-top: 20px;
}

.draggable-host-bottom .success-btn {
  background-color: #57b661;
  border-color: #57b661;
}

.draggable-host-bottom .mt20 {
  margin-top: 10px;
}
.draggable-waiting-player {
  display: flex;
  gap: 8px;
}
.flex-row {
  flex-direction: row;
}
.flex-column {
  flex-direction: column;
}

.draggable-participant-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 100%; */
  gap: 10px;
}
.margin-bottom {
  margin-bottom: 10px;
}
.margin-right {
  margin-right: 10px;
}
.draggable-see-all-button{
	display: flex;
	justify-content: space-between;
}
.draggable-see-all-button span {
	color: #FFFFFF;
	font-weight: 600;
}
.draggable-full-host-bottom {
    position: absolute;
    bottom: 130px;
    margin-top: 20px;
    display: flex;
    width: 30%;
    gap: 10px;
}

.draggable-container.draggable-full .drop-wrapper {
    top: inherit;
    bottom: 80px;
}

.waiting-page {
	background-color: #0e0e10!important;
}

.chat-wrapper.waiting-chat-wrapper {
	border-radius: 8px;
	background: #292B31;
}

.side-setting-wrapper .volume-range-main{
	margin: 1% auto;
}
.tab-card-head.waiting-tab-card-head {
	background: #292B31;
	box-shadow: 0px 5px 10px 0px rgba(19, 20, 21, 0.15);
}
.tab-card-element.waiting-tab-chat-element {
	gap: 12px;
	align-items: flex-end;
}

.chat-line-wrapper{
	background: #1C1D20;
	display: flex;
    border-radius: 16px 16px 16px 0;
	padding: 12px;
	width: 100%;
	height: 100%;
}

.Chat-input-wrapper.waiting-chat-input-wrapper input.form-control{
	height: 60px;
	border-radius: 24px;
    background: #1C1D20;
	border: none;
}

.tab-card-bottom.waiting-tab-card-bottom {
	background: #292B31;
}

.send-icon-wrapper {
	display: inline-flex;
	cursor: pointer;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 20px;
	padding: 5px;
}

.table-wrapper {
	background-color: #292B31;
	width: 100%;
	border-radius: 8px;
	padding: 0;
	max-height: 50%;
	overflow: auto;
}

.table-header {
    font-size: 20px;
    font-weight: 400;
    color: #ECEFF3;
    padding: 10px 16px;
    box-shadow: 0px 5px 10px 0px rgba(19, 20, 21, 0.15);
}

.table-header h4 {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    font-family: "Gilroy" !important;
    margin: 0;
}

.table-content {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 16px;
	gap: 0.8rem;
}

.table-line {
	display: flex;
	align-items: center;
	gap: 18px;
	width: 100%;
}
.table-line-dealer {
	padding: 10px;
}

.table-number {
	display: flex;
	flex-direction: column;
	font-size: 16px;
	line-height: 19.2px;
	color: #FFFFFF;
	align-items: center;
}

.table-profile-wrapper {
	display: flex;
	gap: 0.5rem;
}

.profile {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	height: 32px;
	width: 32px;
	border-radius: 50%;
	color: #FFFFFF;
}

.profile-icon {
	font-size: 12.8px;
	border-radius: 50%;
}

.status-badge {
	position: absolute;
	top: 0;
	left: 0;
	height: 6px;
	width: 6px;
	background-color: #83BC9A;
	border-radius: 50%;
}

.active-tick.mic {
	left: 10px;
}

.drop-wrapper.action-dropper {
	background-color: #0E0E10;
	box-shadow: 10px 10px 60px 0px #1B1D23;
	border: 1px solid #666D80;
}

.mic-component {
	display: flex;
	position: relative;
	padding: 1rem 0 1rem 50px;
	gap: 1.4rem;
	border-top: 1px solid #666D80;
	width: 100%;
}

.mic-component svg {
	position: absolute;
	top: 15px;
	left: 15px;
}

.leave-waiting-btn {
	border-radius: 32px !important;
	height: 38px;
	align-items: center;
	background-color: #DF1C41 !important;
}



/************************* Host Controller ?*****************/


.host-controller-view{
	overflow: hidden;
	background-color: #0E0E10 !important;
}

.host-controller-view body{
	overflow: hidden;
}



.host-controller-view .drop-wrapper{
	display: none;
}

.host-controller-view .btn-down-icon.rotate{
    transform: rotate(0deg);
}

.host-controller-view .oneToOneShow .drop-wrapper{
	display: block;
}

.host-controller-view .normalActionShow .drop-wrapper{
	display: block;
}

.host-controller-view .oneToOneShow .btn-down-icon.rotate{
    transform: rotate(-180deg);
}

.host-controller-view .normalActionShow .btn-down-icon.rotate{
	transform: rotate(-180deg);
}


.host-controller-view .draggable-full .oneToOneShow .drop-wrapper {
    top: unset;
    bottom: 50px;
}




.host-controller-view .setting-btn.active, .host-controller-view .setting-btn.active:hover {
    background: #0E0E10;
}

.host-controller-view .setting-btn.active .cam-icon, .host-controller-view .setting-btn.active:hover .cam-icon {
    background-color: #292B31;
}


.host-controller-view .draggable-content .setting-btn.active, .host-controller-view .draggable-content .setting-btn.active:hover {
    background: #41444c;
}

.host-controller-view .draggable-content .setting-btn.active .cam-icon, .host-controller-view .draggable-content .setting-btn.active:hover .cam-icon {
	background-color: #0E0E10;
}

.host-controller-view .draggable-content .setting-btn, .host-controller-view .draggable-content .setting-btn:hover {
    
	background: #0E0E10;
}

.host-controller-view .draggable-content .setting-btn .cam-icon, .host-controller-view .draggable-content .setting-btn:hover .cam-icon {
 
	background-color: #292B31;
}


.host-controller-view .draggable-content .setting-btn .cam-icon.off, .host-controller-view .draggable-content .setting-btn:hover .cam-icon.off {
	background-color: #DF1C41;
}


.host-controller-view .draggable-content .setting-btn.btn-primary, .host-controller-view .draggable-content .setting-btn.btn-primary:hover{
	background: #292B31;
	border-color: #292B31;
}

.draggable-content .setting-btn.active:hover .cam-icon.off {
    background-color: #DF1C41;
}

.userBatchKey-right{
	position: absolute;
	top: 11px;
	right: 11px;

  }